export const productsSkeyTixWhyNft = [
  {
    imgPath: "/images/profit-card9.svg",
    title: "Uniqueness and value",
    description:
      "Each NFT token is unique and one-of-a-kind, making it ideal for creating digital mementos of events, certificates of attendance, or proof of skills learned."
  },
  {
    imgPath: "/images/profit-card10.svg",
    title: "A modern souvenir",
    description:
      "In the age of digitization, NFTs are becoming a modern form of souvenirs that can be easily stored, collected, and exchanged."
  },
  {
    imgPath: "/images/profit-card11.svg",
    title: "Value for participants",
    description:
      "NFTs can serve as proof of event attendance, attracting attendees interested in building their digital portfolio."
  }
];
