import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { Container } from "react-bootstrap";
import Group from "../../../../../components/arrangement/Group/Group";
import Stack from "../../../../../components/arrangement/Stack/Stack";

import "./Hero.scss";

const Hero = () => {
  const isMobile = useIsMobile("lg");

  return (
    <div className="rwa-hero">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Group
            rowGap={20}
            alignItems="center"
            justifyContent={isMobile ? "center" : "flex-start"}
            className={isMobile ? "wrap-reverse" : ""}
          >
            <Stack rowGap={isMobile ? 24 : 32} style={{ width: "590px" }}>
              <h1 className="t-xs-c t-lg-l m-xs-a m-lg-0">Skey Real Assets</h1>
              <div className="w-xs-100 w-lg-mc">
                <p className="s1 t-xs-c t-lg-l m-xs-a" style={{ maxWidth: "430px" }}>
                  A solution that tokenizes goods, dividing them into small portions accessible to any investor. Our
                  technology opens the door to a new era of investing in tangible assets with high growth potential.
                </p>
              </div>
            </Stack>
            <img src="/images/rwa/hero.png" alt="hero" className="hero-img" />
          </Group>
        </Container>
      </Container>
    </div>
  );
};

export default Hero;
