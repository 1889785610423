export const productsSkeyTixOperation = [
  {
    img: "/images/product-operation1.png",
    header: "Create",
    subheader: "an NFT in the panel and assign it to a ticket for your event."
  },
  {
    img: "/images/product-operation2.png",
    header: "Distribute",
    subheader: "tickets with NFT in your ticketing system."
  },
  {
    img: "/images/product-operation3.png",
    header: "Verify",
    subheader: "the authenticity of the participant's NFT ticket in the app."
  },
  {
    img: "/images/product-operation4.png",
    header: "Validate",
    subheader: "the NFT ticket in the app and confirm access to the event."
  }
];
