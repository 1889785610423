import NewsHero from "../../sections/NewsHero/NewsHero";
import NewsArticles from "../../sections/NewsArticles/NewsArticles";
import NewsStory from "../../sections/NewsStory/NewsStory";
import "./News.scss";

const News = () => {
  return (
    <section id="news">
      <NewsHero />
      <NewsArticles />
      <NewsStory />
    </section>
  );
};

export default News;
