import { Container } from "react-bootstrap";
import Group from "../../../../../components/arrangement/Group/Group";
import { LinkItem } from "../../../../../shared/interfaces/LinkItem";
import Stack from "../../../../../components/arrangement/Stack/Stack";

import "./MoreInfo.scss";

const links: LinkItem[] = [
  {
    label: "Watch video",
    icon: "/images/yt.svg",
    link: "https://www.youtube.com/watch?v=agGUOVbvJ5g",
    self: false
  },
  {
    label: "Read how it works",
    icon: "/images/go-to-30.svg",
    link: "https://skeynetwork.medium.com/the-digital-product-passport-paving-the-way-for-a-circular-economy-in-the-eu-991135ee78a0",
    self: false
  }
];

const MoreInfo = () => {
  return (
    <div className="more-info">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Stack rowGap={30} fullWidth alignItems="center">
            <h2 className="t-xs-c">More information</h2>
            <Group justifyContent="center" colGap={24} rowGap={8} className="wrap links" fullWidth>
              {links.map((item, id) => {
                return (
                  <a className="generic-link" href={item.link} target="_blank" rel="noreferrer" key={id}>
                    <Group colGap={8} justifyContent="center" alignItems="center" fullSize>
                      <p className="s1">{item.label}</p>
                      <img src={item.icon} alt="icon" />
                    </Group>
                  </a>
                );
              })}
            </Group>
          </Stack>
        </Container>
      </Container>
    </div>
  );
};

export default MoreInfo;
