import { Container } from "react-bootstrap";
import CooperationCard from "../../components/molecules/CooperationCard/CooperationCard";
import { ICooperationProps } from "../../../shared/interfaces/ICooperationProps";
import "./ProductUseCases.scss";
import Space from "../../components/atoms/Spacing/Space";

interface IUseCase {
  useCaseCard: ICooperationProps;
}

const ProductUseCases = ({ useCaseCard }: IUseCase) => {
  return (
    <div id="use-cases">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="center-column">
            <h2 className="header">Use cases</h2>
            <Space space="space-md" />
            <p className="s1 subheader">
              Check out how our ecosystem is growing and who uses our blockchain solutions.
            </p>
            <div className="space" />
            <div className="card-size">
              <CooperationCard {...useCaseCard} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default ProductUseCases;
