import { PRODUCT_BLOCKCHAIN_GITBOOK, PRODUCT_BLOCKCHAIN_PANEL_MANUAL, PRODUCT_BLOCKCHAIN_VIDEO } from "../constants";
import { LinkItem } from "../shared/interfaces/LinkItem";

export const productBlockchainDocumentationLinks: LinkItem[] = [
  {
    label: "Show Gitbook",
    link: PRODUCT_BLOCKCHAIN_GITBOOK,
    icon: "/images/gitbook.svg"
  },
  {
    label: "Watch Video",
    link: PRODUCT_BLOCKCHAIN_VIDEO,
    icon: "/images/video.svg"
  },
  {
    label: "Show Tools",
    link: PRODUCT_BLOCKCHAIN_PANEL_MANUAL,
    icon: "/images/whitepaper.svg",
    self: true
  }
];
