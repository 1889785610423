import { Col, Container, Row } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import { overviewBlockchain } from "../../../data/overviewBlockchain";
import Image from "../../components/atoms/Image/Image";
import Link from "../../components/atoms/Link/Link";

import "./OverviewBlockchain.scss";

const OverviewBlockchain = () => {
  return (
    <div id="overview-blockchain">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row>
            <Col xs={12} lg={6}>
              <h2 className={"header"}>Use our Skey Network blockchain ecosystem</h2>
              <Space space={"space-lg"} />
              <p className="s1 subheader">
                Skey Network is an independent blockchain that allows you to create flexible products, services, and
                user-friendly applications that guarantee cryptographic security.
              </p>
              <Space space={"space-xxl"} />
              <div className={"buttons"}>
                <Link target="_self" variant="primary" className="button" url="/cooperation">
                  Show Case Study
                </Link>
              </div>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} lg={{ span: 5, offset: 1 }} className={"col-blockchain"}>
              {overviewBlockchain.map((card) => (
                <div key={Math.random()} className={"card-network"}>
                  <Image imgWidth={80} imgHeight={80} imgPath={card.img} alt={"card img"} className={"img"} />
                  <Space space={"space-sm"} className={" desktop-sm"} />
                  <div className={"text-part"}>
                    <p className="s1 subheader">{card.header}</p>
                    <Space space={"space-sm"} className="desktop-lg" />
                    <Space space={"space-xs"} className="mobile-lg" />
                    <p className="s2 subheader"> {card.subheader}</p>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default OverviewBlockchain;
