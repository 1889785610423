import { Container } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import "./NewsHero.scss";

const NewsHero = () => {
  return (
    <div id="news-hero">
      <img
        width="770"
        height="556"
        src={"/images/news-hero-bg-blocks-desktop.png"}
        alt={"logo"}
        className={"news-bg-blocks desktop-xl"}
      />
      <img
        width="501"
        height="421"
        src={"/images/news-hero-bg-blocks-mobile.png"}
        alt={"logo"}
        className={"news-bg-blocks mobile-xl"}
      />
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className={"col-center"}>
            <h1 className={"header"}>Growth building strategy</h1>
            <Space space="space-lg" />
            <p className={" s1 subheader"}>
              Check out the most crucial articles about the development of our Skey Network blockchain ecosystem! The
              following channels wrote about Skey Network.
            </p>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default NewsHero;
