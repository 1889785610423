import { useRef, useState, useEffect } from "react";
import { useFormsControll } from "../contexts/FormsControllContext";

const useFormHeight = () => {
  const formRef = useRef<any>(null);
  const { formStage } = useFormsControll();
  const [formHeight, setElementHeight] = useState<number>();

  useEffect(() => {
    const ref = formRef.current;
    if (!ref) return;

    setTimeout(() => {
      setElementHeight(ref.clientHeight);
    }, 100);
  }, [formStage]);

  return { formHeight, formRef };
};

export default useFormHeight;
