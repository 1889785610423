import Container from "react-bootstrap/esm/Container";

import "./Hero.scss";

const Hero = () => {
  return (
    <div className="hero  relative">
      <Container fluid="xl" className="relative">
        <Container fluid="xl" className="pt-xs-12">
          <h1 className="title h1-info t-xs-c t-md-l">Skey Network Ecosystem</h1>
        </Container>
        <img src="/images/info/bg.png" alt="info-bg" className="bg" />
      </Container>
    </div>
  );
};

export default Hero;
