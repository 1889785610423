import { Container } from "react-bootstrap";
import Stack from "../../../../../components/arrangement/Stack/Stack";

import ButtonV2 from "../../../../components/atoms/Button/ButtonV2";
import { useFormsControll } from "../../../../../contexts/FormsControllContext";
import Group from "../../../../../components/arrangement/Group/Group";

import "./ContactUs.scss";

const ContactUs = () => {
  const { openPartnershipForm } = useFormsControll();

  return (
    <div className="contact-us">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Stack fullWidth alignItems="center">
            <h2 className="mb-xs-3">Contact us now</h2>
            <p className="s2 mb-xs-4 t-xs-c" style={{ maxWidth: "530px" }}>
              Want to know how Skey Network can help your company meet new EU requirements and optimize processes?
              Contact us now to find out how to build a market advantage together!
            </p>
            <button onClick={openPartnershipForm}>
              <ButtonV2 size={"md"} hierarchy={"primary"} isFlat={false}>
                <Group fullSize justifyContent="center">
                  <p className="s1 white-0">Contact us</p>
                </Group>
              </ButtonV2>
            </button>
          </Stack>
        </Container>
      </Container>
    </div>
  );
};

export default ContactUs;
