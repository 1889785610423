import SolutionsHero from "../../sections/SolutionsHero/SolutionsHero";
import SolutionsSkeyCards from "../../sections/SolutionsSkeyCards/SolutionsSkeyCards";
import SolutionsDeflationSystem from "../../sections/SolutionsDeflationSystem/SolutionsDeflationSystem";
import OverviewDocumentation from "../../sections/OverviewDocumentation/OverviewDocumentation";
import ContactProductDefault from "../../sections/ContactSection/ContactProductDefault/ContactProductDefault";

import "./Solutions.scss";

const Solutions = () => {
  return (
    <section id="solutions">
      <SolutionsHero />
      <SolutionsSkeyCards />
      <SolutionsDeflationSystem />
      <OverviewDocumentation />
      <ContactProductDefault />
    </section>
  );
};

export default Solutions;
