import { useFormsControll } from "../../../../../contexts/FormsControllContext";
import ButtonLegacy from "../../../atoms/Button/ButtonLegacy";
import Space from "../../../atoms/Spacing/Space";
import "./SuccessFormContent.scss";

const SuccessFormContent = () => {
  const { closeAll } = useFormsControll();

  return (
    <div className="success-form-content appear">
      <h2>Thank you.</h2>
      <Space space="space-sm" />
      <p className="s1">We will contact you as soon as possible.</p>
      <Space space="space-md" />
      <ButtonLegacy onClick={closeAll}>Close</ButtonLegacy>
    </div>
  );
};

export default SuccessFormContent;
