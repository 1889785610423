import { Container } from "react-bootstrap";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import Group from "../../../../../components/arrangement/Group/Group";

import Card, { ICard } from "../../components/Card";

import "./MainBenefits.scss";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const benefits: ICard[] = [
  {
    img: "/images/passport/benefits/1.svg",
    text: "Compliance with EU regulations"
  },
  {
    img: "/images/passport/benefits/2.svg",
    text: "Building customer trust"
  },
  {
    img: "/images/passport/benefits/3.svg",
    text: "Export facilitation"
  },
  {
    img: "/images/passport/benefits/4.svg",
    text: "Increase in market advantage"
  },
  {
    img: "/images/passport/benefits/5.svg",
    text: "Time and cost savings"
  }
];

const MainBenefits = () => {
  const isMobile = useIsMobile("lg");
  return (
    <div className="main-benefits">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Stack rowGap={isMobile ? 32 : 56} fullWidth>
            <Stack rowGap={20} fullWidth>
              <h2 className="t-xs-c m-xs-a">Main benefits</h2>
              <p className="s2 w-xs-100 t-xs-c m-xs-a" style={{ maxWidth: "720px" }}>
                The Digital Product Passport is the future of trade in the European Union, allowing companies to meet
                the latest regulatory and environmental requirements.
              </p>
            </Stack>
            <Group justifyContent="center" colGap={24} rowGap={40} alignItems="flex-start" className="wrap">
              {benefits.map((b, id) => (
                <Card {...b} key={id} className="benefit" />
              ))}
            </Group>
          </Stack>
        </Container>
      </Container>
    </div>
  );
};

export default MainBenefits;
