import { Container } from "react-bootstrap";

import Space from "../../components/atoms/Spacing/Space";

import "./SkeyTixIntegration.scss";

const SkeyTixIntegration = () => {
  return (
    <div id="skey-tix-integration" className="skey-tix-integration">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="skey-tix-integration-box">
            <div className="integration-content">
              <h2>Integration with existing systems</h2>
              <Space space="space-lg" />

              <p className="s2">
                For event organizers who already have their own ticketing systems, we offer easy integration of our NFT
                module via API. This solution allows clients to enhance their existing system with exciting new
                functionality.
              </p>
            </div>

            <div className="integration-image-box">
              <img
                width={340}
                height={254}
                src="/images/skey-tix-integration.svg"
                className="desktop-lg"
                alt="SkeyTIX integration"
              />
              <img
                width={286}
                height={227}
                src="/images/skey-tix-integration.svg"
                className="mobile-lg"
                alt="SkeyTIX integration"
              />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default SkeyTixIntegration;
