import { SKEY_BOX_TERMS_AND_CONDITIONS, SKEY_BOX_PRIVACY_POLICY } from "../constants";

export type AgreementsName = "Terms and Conditions" | "Privacy Policy" | "Information concerning services";

export interface Agreement {
  name: AgreementsName;
  label: string;
  required: boolean;
  link?: { label: string; url: string };
}

export const formAgreements: Agreement[] = [
  {
    name: "Terms and Conditions",
    label: "I have read the",
    required: true,
    link: {
      label: "Terms and Conditions",
      url: SKEY_BOX_TERMS_AND_CONDITIONS
    }
  },
  {
    name: "Privacy Policy",
    label:
      "I consent to the processing of my personal data by the Administrator for the purpose of providing services and online sales, in accordance with the",
    required: true,
    link: {
      label: "Privacy Policy",
      url: SKEY_BOX_PRIVACY_POLICY
    }
  },
  {
    name: "Information concerning services",
    label:
      "I agree to electronically receive the information concerning the services provided by the Administrator to the indicated e-mail address.",
    required: false
  }
];
