export const fiatCodes = ["PLN", "USD", "EUR"] as const;
export type FiatCodes = typeof fiatCodes[number];

export interface FiatProps {
  currencySymbol: string;
  shortName: FiatCodes;
  longName: string;
  minAmount: number;
}

export const CRYPTO_CODE_CALCULATIONS = "SKEY";
export const CRYPTO_CODE_RENDER = "$SKEY";
export const DEFAULT_INPUT_AMOUNT = "0";

export const fiatsProps: Record<FiatCodes, FiatProps> = {
  EUR: {
    currencySymbol: "/images/fiat-currencies/eur.svg",
    shortName: "EUR",
    longName: "Euro",
    minAmount: 25
  },
  PLN: {
    currencySymbol: "/images/fiat-currencies/pln.svg",
    shortName: "PLN",
    longName: "Polish Zloty",
    minAmount: 100
  },
  USD: {
    currencySymbol: "/images/fiat-currencies/usd.svg",
    shortName: "USD",
    longName: "American Dollar",
    minAmount: 25
  }
};
