import Stack from "../../../../components/arrangement/Stack/Stack";
import { classNames } from "../../../../utils/classNames";

import "./Card.scss";

export interface ICard {
  img: string;
  text: string;
  className?: string;
  description?: string;
}

const Card = (props: ICard) => {
  return (
    <Stack rowGap={20} alignItems="center" className={classNames("passport-card", props.className)}>
      <img src={props.img} alt={"card-item"} className="mb-xs-1" />
      <h4 className="t-xs-c" style={{ maxWidth: "260px" }}>
        {props.text}
      </h4>
      {!!props.description && <p className="s2 t-xs-c">{props.description}</p>}
    </Stack>
  );
};

export default Card;
