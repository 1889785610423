import { IInteractiveDateProps } from "../../../../shared/interfaces/IInteractiveDateProps";
import InteractiveArrow from "../../atoms/InteractiveArrow/InteractiveArrow";
import InteractiveText from "../../atoms/InteractiveText/InteractiveText";
import "./TabInteractiveDate.scss";

const TabInteractiveDate = ({ variant, className, date }: IInteractiveDateProps) => {
  return (
    <div className={`tab-interactive-date ${className}`}>
      <InteractiveText textRole={"h2"} variant={"active"} weight="bold" className="mazzard">
        {date}
      </InteractiveText>
      <InteractiveArrow variant={variant} />
    </div>
  );
};

export default TabInteractiveDate;
