import { Container, Row } from "react-bootstrap";
import Image from "../../atoms/Image/Image";
import RouterLink from "../../atoms/RouterLink/RouterLink";
import { menuLinks } from "../../../../data/menuLinks";
import Group from "../../../../components/arrangement/Group/Group";

import "./Navbar.scss";

const NavbarInfo = () => {
  return (
    <>
      <div className="navbar-background"></div>
      <section id="nav-bar">
        <Container fluid="xl">
          <Row className="row-center">
            <Group justifyContent="space-between" alignItems="center" fullWidth>
              <Image
                imgWidth={104}
                imgHeight={36}
                imgPath={"/images/skey-logo-basic.svg"}
                alt={"skey logo"}
                asLink={true}
                href="/#"
              />
              <RouterLink label={"Go to the main page"} link={menuLinks.overview.paths[0]} isActive={false} />
            </Group>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NavbarInfo;
