import { CSSProperties } from "react";

import { BaseProps } from "../../../shared/interfaces/BaseProps";
import { IChildren } from "../../../shared/interfaces/IChildren";
import { classNames } from "../../../utils/classNames";

import "./Group.scss";

export interface IGroup extends BaseProps {
  colGap?: number;
  rowGap?: number;
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  fullSize?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  className?: string;
}

const Group = ({
  colGap = 0,
  rowGap = 0,
  children,
  justifyContent = "flex-start",
  alignItems = "center",
  fullSize = false,
  fullWidth = false,
  fullHeight = false,
  className = "",
  style
}: IGroup & IChildren) => {
  const classNamee = classNames(
    "group",
    className,
    fullSize ? "full-size" : "",
    fullWidth ? "full-width" : "",
    fullHeight ? "full-height" : ""
  );
  return (
    <div
      className={classNamee}
      style={{
        columnGap: `${colGap}px`,
        rowGap,
        justifyContent,
        alignItems,
        ...style
      }}
    >
      {children}
    </div>
  );
};

export default Group;
