import { Container } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import "./SkeyTixTokenomy.scss";

const SkeyTixTokenomy = () => {
  return (
    <div id="skey-tix-tokenomy">
      <Container fluid="xxl">
        <Container fluid="xl">
          <h2 className="header">Tokenomy</h2>
          <Space space="space-xl" />
          <img
            width={1134}
            height={335}
            src="/images/skey-tix-tokenomy-desktop.png"
            alt="images"
            className="tokenomy-img desktop-xl"
          />
          <img
            width={341}
            height={958}
            src="/images/skey-tix-tokenomy-mobile.png"
            alt="images"
            className="tokenomy-img mobile-xl"
          />
        </Container>
      </Container>
    </div>
  );
};

export default SkeyTixTokenomy;
