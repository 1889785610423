import Caruma from "./sections/Caruma/Caruma";
import Go2NFT from "./sections/Go2NFT/Go2NFT";
import Hero from "./sections/Hero/Hero";
import Partners from "./sections/Partners/Partners";
import SkeyNetwork from "./sections/SkeyNetwork/SkeyNetwork";

import "./Info.scss";
// import OverviewBuyBox from "../../sections/OverviewBuyBox/OverviewBuyBox";

const Info = () => {
  return (
    <section id="info" className="pb-xs-10 pb-md-20">
      <Hero />
      <SkeyNetwork />
      <Partners />
      <Go2NFT />
      <Caruma />
      {/* <OverviewBuyBox /> */}
    </section>
  );
};

export default Info;
