import { Container } from "react-bootstrap";
import Stack from "../../../../../components/arrangement/Stack/Stack";

import Group from "../../../../../components/arrangement/Group/Group";
import CaseStudyButton from "../../components/CaseStudyButton";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { id } from "../../constants/id";

import "./UseCase.scss";

const UseCase = () => {
  const isMobile = useIsMobile("lg");

  return (
    <div className="use-case relative" id={id.caseStudy}>
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Stack fullWidth rowGap={10}>
            <Stack fullWidth rowGap={20} style={{ zIndex: 1 }}>
              <h2 className="m-xs-a t-xs-c">Use case for clothing protection</h2>
              <p className="s2 t-xs-c m-xs-a" style={{ maxWidth: "720px" }}>
                The Digital Product Passport is already up and running in the fashion industry. The first products have
                digital passports - just scan the QR code to see all the information. Go2NFT is already implementing our
                solutions among its customers, offering them an additional option to create NFTs.
              </p>
            </Stack>
            <div className="relative w-xs-100" style={{ zIndex: 0, mixBlendMode: "darken" }}>
              <img
                src="/images/passport/use-case/use-case-desktop.jpg"
                alt="use-case"
                className="d-xs-n d-lg-b"
                style={{ maxWidth: "100%", zIndex: 0, position: "relative", marginTop: "-80px" }}
              />
              <img
                src="/images/passport/use-case/use-case-mobile.jpg"
                alt="use-case"
                className="d-xs-b d-lg-n m-xs-a"
                style={{
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "-130px",
                  marginBottom: "-130px",
                  zIndex: 0
                }}
              />
              <div
                style={{
                  position: "absolute",
                  left: "52%",
                  top: isMobile ? "52%" : "61%",
                  transform: "translateX(-50%)",
                  width: "max-content"
                }}
              >
                <CaseStudyButton>
                  <Group colGap={8} alignItems="center" className="p-xs-1">
                    <p className="primary" style={{ fontSize: "15px", letterSpacing: "-0.02em" }}>
                      show digital passport
                    </p>
                    <img src="/images/go-to.svg" alt="go to" />
                  </Group>
                </CaseStudyButton>
              </div>
            </div>
          </Stack>
        </Container>
      </Container>
    </div>
  );
};

export default UseCase;
