import { Container } from "react-bootstrap";
import Card, { ICard } from "../../../Passport/components/Card";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import Group from "../../../../../components/arrangement/Group/Group";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

import "./Benefits.scss";

const benefits: (ICard & { description: string })[] = [
  {
    img: "/images/rwa/benefits/1.svg",
    text: "Broader access to investment",
    description:
      "Tokenization allows small investors to access assets previously reserved for large players, increasing participation in capital markets."
  },
  {
    img: "/images/rwa/benefits/2.svg",
    text: "Investments available to everyone",
    description:
      "Tokenizing assets, such as real estate and shares, increases liquidity and enables 24/7 trading in global markets."
  },
  {
    img: "/images/rwa/benefits/3.svg",
    text: "Transparency and security",
    description:
      "Blockchain provides an immutable transaction record, increasing transparency and building customer trust."
  },
  {
    img: "/images/rwa/benefits/4.svg",
    text: "Lower costs and higher efficiency",
    description:
      "Automating asset management through smart contracts reduces operating costs, eliminates intermediaries, and increases efficiency."
  },
  {
    img: "/images/rwa/benefits/5.svg",
    text: "Global investment and diversification",
    description:
      "Tokenization enables investment in global assets, offering new markets and more significant opportunities for wallet diversification."
  },
  {
    img: "/images/rwa/benefits/6.svg",
    text: "Passive income",
    description: "Investors can earn regular income from sales, making tokenization an attractive long-term investment."
  }
];

const Benefits = () => {
  const isMobile = useIsMobile("lg");

  return (
    <div className="benefits">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Stack rowGap={isMobile ? 50 : 80} fullWidth>
            <Stack rowGap={20} fullWidth>
              <h2 className="t-xs-c m-xs-a">Main benefits</h2>
              <p className="s2 w-xs-100 t-xs-c m-xs-a" style={{ maxWidth: "720px" }}>
                Real World Assets (RWAs) bring revolutionary benefits by combining traditional assets with modern
                blockchain technology, and Skey Network is actively working in this area with innovative solutions.
              </p>
            </Stack>
            <Group justifyContent="center" colGap={40} rowGap={50} alignItems="flex-start" className="wrap">
              {benefits.map((b, id) => (
                <Card {...b} key={id} className="benefit" />
              ))}
            </Group>
          </Stack>
        </Container>
      </Container>
    </div>
  );
};

export default Benefits;
