export const accessControlCards = [
  {
    img: "/images/profit-card1.svg",
    header: "Security",
    subheader: "based on blockchain cryptography"
  },
  {
    img: "/images/profit-card2.svg",
    header: "Higher level",
    subheader: "of GDPR security (maximizing user privacy protection)"
  },
  {
    img: "/images/profit-card3.svg",
    header: "Integration",
    subheader:
      " of devices regardless of the technology used and the manufacturer"
  },
  {
    img: "/images/profit-card4.svg",
    header: "Development",
    subheader: "of sharing economy"
  },
  {
    img: "/images/profit-card5.svg",
    header: "Transparency",
    subheader: "decentralization and anonymity of data"
  }
];
