import { useRef, useState, useEffect } from "react";
import { useWindowDimensions } from "./useWindowDimensions";

const useTagHeight = () => {
  const tagRef = useRef<any>(null);
  const { viewportWidth, viewportHeight } = useWindowDimensions();
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    const ref = tagRef.current;
    if (!ref) return;

    setTimeout(() => {
      setElementHeight(ref.clientHeight);
    }, 1000);
  }, [viewportWidth, viewportHeight]);

  return { elementHeight, tagRef };
};

export default useTagHeight;
