import { Container } from "react-bootstrap";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import Group from "../../../../../components/arrangement/Group/Group";
import Card, { ICard } from "../../components/Card";

import "./Gains.scss";

const gains: ICard[] = [
  {
    img: "/images/passport/gains/1.svg",
    text: "Quick and easy implementation"
  },
  {
    img: "/images/passport/gains/2.svg",
    text: "Automated processes"
  },
  {
    img: "/images/passport/gains/3.svg",
    text: "Security guaranteed by blockchain"
  },
  {
    img: "/images/passport/gains/4.svg",
    text: "Up-to-date data you can trust"
  }
];

const Gains = () => {
  return (
    <div className="gains">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Stack rowGap={64} fullWidth>
            <Stack rowGap={20} fullWidth>
              <h2 className="t-xs-c m-xs-a">What will you gain from it?</h2>
              <p className="s2 w-xs-100 t-xs-c m-xs-a" style={{ maxWidth: "540px" }}>
                Skey Network provides a full suite of tools and infrastructure to simplify implementing a digital
                product passport. What do we offer?
              </p>
            </Stack>
            <Group fullWidth justifyContent="center" colGap={40} rowGap={40} alignItems="flex-start" className="wrap">
              {gains.map((g, id) => (
                <Card {...g} key={id} className="gain" />
              ))}
            </Group>
          </Stack>
        </Container>
      </Container>
    </div>
  );
};

export default Gains;
