import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiClientOptions, AxiosClient } from "./AxiosClient";
import { GetCryptoCurrenciesResponse, GetFiatCurrenciesResponse, PostCalculateResponse } from "./dtos";
import { ARI_WIDGET_ID } from "../config";

export class AriApiClient extends AxiosClient {
  static CASINO_API_HEADER_NAME = "Ari10-Widget-Id";
  static widgetId = ARI_WIDGET_ID;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(options: ApiClientOptions) {
    super(options);
  }

  public async currencies() {
    const path = "/currencies";
    const method = "GET";
    return await this.request<GetCryptoCurrenciesResponse>({ url: path, method });
  }
  public async fiatCurrencies() {
    const path = "/fiat-currencies";
    const method = "GET";
    return await this.request<GetFiatCurrenciesResponse>({ url: path, method });
  }
  public async calculate(cryptoCode: string, fiatAmount: string, fiatCode: string) {
    const path = `/currencies/${cryptoCode}/calculate`;
    const method = "POST";
    return await this.request<PostCalculateResponse>({
      url: path,
      method,
      data: { offeredAmount: Number(fiatAmount), offeredCurrencyCode: fiatCode }
    });
  }

  private async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const headers = {
      ...config.headers,
      [`${AriApiClient.CASINO_API_HEADER_NAME}`]: AriApiClient.widgetId
    };

    return await super.sendRequest<T>({
      ...config,
      headers
    });
  }
}
