export const profuctsSkeyTixFaq = [
  {
    question: "What is the NFT?",
    answer:
      'Buckle up! NFT stands for Non-Fungible Token, but what does it mean? Imagine you have a fantastic digital T-shirt in the virtual world that no one else has. Only you own it, and everyone knows it\'s yours. "Non-fungible" means it is unique and not exchangeable 1:1 with other things. And "Token" is a ticket confirming your right to this thing. This is all thanks to the magic of blockchain technology, which, like a tough security guard at the club\'s gate, makes sure no one can cheat you. In short, NFT is your VIP pass to unique digital things on the web. You are now the king of the Internet!'
  },
  {
    question: "Why do I need NFT?",
    answer:
      'Are you wondering, "Why do I need an NFT?" while at an event or training? Well, you walk into an event and want something special - NFT is your digital VIP ticket, confirmation that "I was there!". But it\'s not the end! Are you finishing the training? Instead of a mere certificate, the NFT is your shiny badge in the virtual world, proof of the skills you\'ve acquired. In either case, you not only have a souvenir but also an indisputable digital sign that you are part of something great and that you are growing!'
  },
  {
    question: "How to search for NFTs from an event?",
    answer:
      "Want to find an NFT from a mega event? No problem! Hit up SkeyTixplorer - it's the place where the magic happens. Once you're there, you use the search engine. You type in your NFT address or wallet address, and boom! All the fabulous NFTs from the event are at your fingertips. Just like in a computer game when you find hidden treasure. You watch, choose, and enjoy the digital souvenir. Simple as taking a selfie!"
  },
  {
    question: "How to claim the NFT?",
    answer: {
      steps: {
        one: "Click on the link that came in the email - it's like getting into a VIP party.",
        two: "Once you're there, look for the \"Show Seed\" button. Click it, and you'll see 15 words - they're like game codes, so write them down well!",
        three:
          "Now go to Chrome and install the wallet from here: Skey Keeper It's like adding a new app to your smartphone.",
        four: "Then, set up a password for your wallet - like securing your diary from curious siblings.",
        five: "Import the wallet using those 15 words you saved earlier (Add account> Import account > Paste seed > Import)."
      }
    }
  },
  {
    question: "How to transfer my NFT to another wallet?",
    answer: {
      info: "To transfer the received NFTs from the event to another wallet follow these steps:",
      steps: {
        one: "Connect your wallet on the website - SkeyTixplorer",
        two: "Select the NFTs you want to send",
        three: "Enter the address of the wallet to receive the NFT.",
        four: "Send"
      }
    }
  }
];
