import CooperationHero from "../../sections/CooperationHero/CooperationHero";
import CooperationCards from "../../sections/CooperationCards/CooperationCards";
import "./Cooperation.scss";
import ContactCooperation from "../../sections/ContactSection/ContactCooperation/ContactCooperation";

const Cooperation = () => {
  return (
    <section id="cooperation">
      <CooperationHero />
      <CooperationCards />
      <ContactCooperation />
    </section>
  );
};

export default Cooperation;
