import Group from "../../../../../components/arrangement/Group/Group";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import { SectionTitleProps } from "../../data/data";

import { useIsMobile } from "../../../../../hooks/useIsMobile";

const SectionTitle = ({ imgSrc, title, description }: SectionTitleProps) => {
  const isMobile = useIsMobile("md");

  return (
    <Group className="title" colGap={isMobile ? 20 : 40} alignItems="flex-start">
      <img src={imgSrc} alt="company-logo" height={isMobile ? 42 : 55} />
      <Stack rowGap={isMobile ? 12 : 32}>
        <h2 className="capitalize">{title}</h2>
        <p className="s1 capitalize" style={{ maxWidth: "435px" }}>
          {description}
        </p>
      </Stack>
    </Group>
  );
};

export default SectionTitle;
