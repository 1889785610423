import { useFormsControll } from "../../../contexts/FormsControllContext";
import { FormAgreementsProvider } from "../../../contexts/FormAgreementsContext";

import Backdrop from "../../components/atoms/Backdrop/Backdrop";
import PartnershipForm from "../../components/molecules/Form/PartnershipForm/PartnershipForm";
import ProductForm from "../../components/molecules/Form/ProductForm/ProductForm";
import ModulesForm from "../../components/molecules/Form/ModulesForm/ModulesForm";

const Forms = () => {
  const { productFormOpen, partnershipFormOpen, modulesFormOpen } = useFormsControll();

  return (
    <>
      {productFormOpen && (
        <Backdrop>
          <ProductForm />
        </Backdrop>
      )}
      {partnershipFormOpen && (
        <Backdrop>
          <PartnershipForm />
        </Backdrop>
      )}
      {modulesFormOpen && (
        <Backdrop>
          <FormAgreementsProvider>
            <ModulesForm />
          </FormAgreementsProvider>
        </Backdrop>
      )}
    </>
  );
};

export default Forms;
