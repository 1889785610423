import { createContext, useState, useContext, ReactNode } from "react";

const DefaultProductContext = createContext(null as any);

interface DefaultProductsProps {
  children: ReactNode;
}

export type SkeyProducts = "SkeyBOX" | "SkeyTIX" | "Blockchain" | "Skey Access Control" | "";

export interface DefaultProductValue {
  defaultProduct: SkeyProducts;
  handleDefaultProduct: (product: SkeyProducts) => void;
  resetDefaultProduct: () => void;
}

export const DefaultProductProvider = ({ children }: DefaultProductsProps) => {
  const [defaultProduct, setDefaultProduct] = useState<SkeyProducts>("");

  const handleDefaultProduct = (product: SkeyProducts) => setDefaultProduct(product);

  const resetDefaultProduct = () => setDefaultProduct("");

  return (
    <DefaultProductContext.Provider value={{ defaultProduct, handleDefaultProduct, resetDefaultProduct }}>
      {children}
    </DefaultProductContext.Provider>
  );
};

export const useDefaultProduct = (): DefaultProductValue => useContext(DefaultProductContext);
