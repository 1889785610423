import { useState } from "react";

import { ILink } from "../../../../shared/interfaces/ILink";
import HoverIcon from "../HoverIcon/HoverIcon";

import "./Link.scss";

const Link = ({
  url,
  target = "_blank",
  variant = "primary",
  size = "size-normal",
  children,
  icon,
  imgWidth,
  imgHeight,
  iconHovers = true,
  iconOnLeft = false,
  className = "",
  download = false,
  mode = "normal-mode",
  disabled = false,
  notClickable = false
}: ILink) => {
  const [hoverState, setHoverState] = useState<"default" | "hover">("default");

  const isHovered = hoverState === "hover";

  const hasIcon = !!icon && !!imgHeight && !!imgWidth;

  const setDefault = () => setHoverState("default");
  const setHover = () => setHoverState("hover");

  // eslint-disable-next-line no-useless-concat
  const buildClass = `skey-link ${variant} ${size} ${className} ${mode ? mode : ""} ${disabled ? "disabled" : ""} ${
    notClickable ? "not-clickable" : ""
  }`;

  const normalLink = (
    <a
      href={url}
      className={buildClass}
      rel="noreferrer noopener"
      target={target}
      download={download}
      onMouseOver={setHover}
      onMouseLeave={setDefault}
    >
      {iconOnLeft && hasIcon && (
        <HoverIcon
          imgHeight={imgHeight}
          imgWidth={imgWidth}
          className={`mr-xs`}
          iconPath={icon}
          isHover={isHovered}
          doHover={iconHovers}
        />
      )}
      {children}
      {!iconOnLeft && hasIcon && (
        <HoverIcon
          imgHeight={imgHeight}
          imgWidth={imgWidth}
          className={`ml-xs`}
          iconPath={icon}
          isHover={isHovered}
          doHover={iconHovers}
        />
      )}
    </a>
  );

  const dummyLink = (
    <span className={buildClass}>
      {iconOnLeft && hasIcon && (
        <HoverIcon
          imgHeight={imgHeight}
          imgWidth={imgWidth}
          className={`mr-xs`}
          iconPath={icon}
          isHover={isHovered}
          doHover={iconHovers}
        />
      )}
      {children}
      {!iconOnLeft && hasIcon && (
        <HoverIcon
          imgHeight={imgHeight}
          imgWidth={imgWidth}
          className={`ml-xs`}
          iconPath={icon}
          isHover={isHovered}
          doHover={iconHovers}
        />
      )}
    </span>
  );

  return (
    <>
      {notClickable && <>{dummyLink}</>}
      {!notClickable && <>{normalLink}</>}
    </>
  );
};

export default Link;
