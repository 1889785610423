import "./ProductsSkeyBoxModules.scss";
import { Col, Container, Row } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import GridCards from "../../components/organisms/GridCards/GridCards";
import { productsSkeyBoxModules } from "../../../data/productsSkeyBoxModules";
import Image from "../../components/atoms/Image/Image";

const ProductsSkeyBoxModules = () => {
  return (
    <div id="products-skey-box-modules">
      <Container fluid="xxl">
        <Container fluid="xl" className="as-center-col">
          <Row>
            <Col xs={12} lg={8}>
              <h2 className="modules">Modules</h2>
              <Space space={"space-sm"} />
              <p className="s1 title">
                A set of specific tools that allow your company to integrate its own products or services with the Skey
                Network ecosystem or create completely new products or services based on blockchain technology
              </p>
            </Col>
            <Space space={"space-xxxl"} />
            <GridCards maxColumns="3" gridGap="20">
              {productsSkeyBoxModules.map((card, index) => (
                <div className="cards-modules" key={index}>
                  <Image imgWidth={80} imgHeight={80} imgPath={card.img} alt="products" />
                  <Space space="space-sm" />
                  <p className="s1 subheader modules-header">{card.header}</p>
                  <p className="s2 modules-title">{card.title}</p>
                </div>
              ))}
            </GridCards>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyBoxModules;
