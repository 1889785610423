import { IStoriesOfProject } from "../ui/components/organisms/StoryAccordion/StoryAccordion";

type SideIndicator = "left" | "right";

let sideIndicator: SideIndicator = "right";

const toggleSide = (side: SideIndicator) => {
  if (side === "left") {
    return "right";
  }
  if (side === "right") {
    return "left";
  }
  throw new Error("Unhandled side");
};

export const accordionItemsWithSides = ({
  storyAccordionItems
}: IStoriesOfProject) => {
  return storyAccordionItems.map((item) => {
    return {
      ...item,
      cards: item.cards.map((card) => {
        sideIndicator = toggleSide(sideIndicator);
        return {
          ...card,
          anchorSide: sideIndicator
        };
      })
    };
  });
};
