import { Col, Container, Row } from "react-bootstrap";

import { productsSkeyTixCards } from "../../../data/productsSkeyTixCards";

import Space from "../../components/atoms/Spacing/Space";
import Image from "../../components/atoms/Image/Image";
import Stack from "../../../components/arrangement/Stack/Stack";
import Link from "../../components/atoms/Link/Link";

import "./ProductsSkeyTixFeatures.scss";

const ProductsSkeyTixFeatures = () => {
  return (
    <div id="products-skey-tix-features" className="products-skey-tix-features">
      <Container fluid="xxl">
        <Container fluid="xl" className="as-center-col">
          <h2 className="header-skey-tix">What will you gain from it?</h2>
          <Space space="space-xxxl" className="desktop-xl" />
          <Space space="space-xxl" className="mobile-xl" />

          <Row className="row-skey-tix">
            {productsSkeyTixCards.map((card) => (
              <Col xs={12} md={6} lg={4} key={Math.random()}>
                <Stack justifyContent={"center"} alignItems={"center"} fullWidth>
                  <Image imgWidth={150} imgHeight={150} imgPath={card.img} alt="card" />
                  <h2 className="skey-tix">{card.header}</h2>
                  <Space space="space-sm" />
                  <p className="s2 subheader-tix">{card.subheader}</p>

                  {card.link && (
                    <>
                      <Space space="space-sm" />
                      <Link
                        url={card.link.href}
                        target={card.link.target as any}
                        variant="trietary"
                        className="card-link"
                      >
                        {card.link.label}
                      </Link>
                    </>
                  )}
                </Stack>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyTixFeatures;
