import { useFormsControll } from "../../../../contexts/FormsControllContext";
import ContactSection from "../ContactSection";
import "./ContactCooperation.scss";

const ContactCooperation = () => {
  const { openPartnershipForm } = useFormsControll();
  return (
    <ContactSection
      header={"Create your product"}
      subheader={"Contact us and transfer your business to the blockchain!"}
      buttonLabel={"Contact us"}
      action={openPartnershipForm}
    />
  );
};

export default ContactCooperation;
