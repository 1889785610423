import { useFormsControll } from "../../../contexts/FormsControllContext";
import { useDefaultProduct } from "../../../contexts/DefaultProductContext";
import ContactSection from "../ContactSection/ContactSection";

const ContactProductSkeyTix = () => {
  const { openProductForm } = useFormsControll();
  const { handleDefaultProduct } = useDefaultProduct();

  const contactAction = () => {
    openProductForm();
    handleDefaultProduct("SkeyBOX");
  };
  return (
    <ContactSection
      header={"Create your product"}
      subheader={"Contact us and transfer your business to the blockchain!"}
      buttonLabel={"Contact us"}
      action={contactAction}
    />
  );
};

export default ContactProductSkeyTix;
