import { IInteractiveElement } from "../../../../shared/interfaces/IInteractiveElement";
import "./InteractiveArrow.scss";

const InteractiveArrow = ({ variant = "default" }: IInteractiveElement) => {
  return (
    <div className="arrow-wrapper">
      <img width={19} height={12} className={`interactive-arrow ${variant}`} src="/images/down-arrow.svg" alt="arrow" />
    </div>
  );
};

export default InteractiveArrow;
