import { screenSizes } from "../constants/screenSizes";
import { ScreenSizes } from "../shared/types/ScreenSizes";
import { useWindowDimensions } from "./useWindowDimensions";

export const useIsMobile = (maxScreenSize: ScreenSizes) => {
  const { viewportWidth } = useWindowDimensions();
  const isMobile = viewportWidth <= screenSizes[maxScreenSize];

  return isMobile;
};
