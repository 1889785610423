import { IRequestMethods as RequestMethod } from "../shared/types/IRequestMethods";
import moment from "moment";
import { APP_STAGE, MAILING_API_KEY, MAILING_SERVICE_TIMEOUT, MAILING_SERVICE_URL } from "../constants";
import { SkeyProducts } from "../contexts/DefaultProductContext";

const requestExtensionByStage = () => {
  if (APP_STAGE === "dev") return "-dev";
  else if (APP_STAGE === "prod") return "";
  else return "";
};

interface EmailFormData {
  name: string;
  email: string;
  product: Omit<SkeyProducts, "">;
  message?: string;
  captchaToken: string;
}

interface ModuleFormData {
  modules: string;
  agreements: string;
}

export class EmailService {
  private static serviceUrl = MAILING_SERVICE_URL();
  private static serviceApiKey = MAILING_API_KEY;
  private static apiKeyKey = "X-API-KEY";
  private static reCaptchaKey = "recaptcha";

  static async partnershipEmail(name: string, email: string, message: string, captchaToken: string) {
    const method: RequestMethod = "POST";
    const mailerServiceName = `skeyPartners${requestExtensionByStage()}`;
    const url = `${this.serviceUrl}/${mailerServiceName}/contact`;
    const body = { name, email, message };

    return await this.sendEmail(url, method, captchaToken, body);
  }

  static async productEmail({ name, email, product, message, captchaToken }: EmailFormData) {
    const method: RequestMethod = "POST";
    const mailerServiceName = `skeyProducts${requestExtensionByStage()}`;
    const url = `${this.serviceUrl}/${mailerServiceName}/contact`;
    const body = { name, email, product, message };

    return await this.sendEmail(url, method, captchaToken, body);
  }

  static async moduleEmail({
    name,
    product,
    captchaToken,
    email,
    modules,
    message,
    agreements
  }: EmailFormData & ModuleFormData) {
    const method: RequestMethod = "POST";
    const mailerServiceName = `skeyBoxProduct${requestExtensionByStage()}`;
    const url = `${this.serviceUrl}/${mailerServiceName}/contact`;
    const body = { name, email, product, message, modules, agreements };

    return await this.sendEmail(url, method, captchaToken, body);
  }

  static async sendEmail(url: string, method: RequestMethod, captchaToken: string, body: Record<string, any>) {
    const date = moment(new Date()).format("l");

    const stringifiedBody = JSON.stringify({
      ...body,
      date
    });

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), MAILING_SERVICE_TIMEOUT);

    const req = async () => {
      const response = await fetch(url, {
        method: method,
        body: stringifiedBody,
        signal: controller.signal,
        headers: {
          [this.apiKeyKey]: this.serviceApiKey,
          [this.reCaptchaKey]: captchaToken,
          "Content-Type": "application/json"
        }
      });

      clearTimeout(timeoutId);
      return response;
    };
    return await req();
  }
}
