import { AccordionProps } from "./AccordionV2.types";
import AccordionTab from "./AccordionTab/AccordionTab";
import AccordionSummary from "./AccordionSummary/AccordionSummary";
import AccordionDetails from "./AccordionDetails/AccordionDetails";

import "./AccordionV2.scss";

const AccordionV2 = ({ children, className }: AccordionProps) => {
  const classes = `accordion ${className || ""}`;

  return <div className={classes}>{children}</div>;
};

AccordionV2.Tab = AccordionTab;
AccordionV2.Summary = AccordionSummary;
AccordionV2.Details = AccordionDetails;

export default AccordionV2;
