import { Col, Container, Row } from "react-bootstrap";
import { SECURITY_AUDIT, SKEY_ETHERSCAN } from "../../../constants";
import { exchangeStocks } from "../../../data/exchangeStocks";
import Link from "../../components/atoms/Link/Link";
import ExchangeCard from "../../components/molecules/ExchangeCard/ExchangeCard";
import Space from "../../components/atoms/Spacing/Space";
import { addSkeyToMetamask } from "../../../services/Metamask";
import ButtonLegacy from "../../components/atoms/Button/ButtonLegacy";
import CopyAddress from "./CopyAddress";
import "./OverviewExchangeStocks.scss";

const OverviewExchangeStocks = () => {
  return (
    <div id="exchange-stocks">
      <Container fluid="xxl">
        <Container fluid="xl" className="col-wrapper">
          <Row className="w-100">
            <Col className="exchanges" xs={12} xl={8}>
              {exchangeStocks.map((exchange) => (
                <Col xs={6} lg={3} xl={3} style={{ paddingRight: "10px" }} key={Math.random()}>
                  <ExchangeCard {...exchange} />
                </Col>
              ))}
            </Col>
            <Space className="mobile-lg" space="space-xl" />
            <Col className="skey-links ml-md" xs={12} lg={4}>
              <p className="s1">Skey token address</p>
              <Space space="space-sm" />
              <CopyAddress />
              <ButtonLegacy
                width={23}
                height={23}
                icon={"/images/metamask.svg"}
                onClick={addSkeyToMetamask}
                variant="trietary"
                iconHovers={false}
              >
                Add Skey token to metamask
              </ButtonLegacy>
              <Link url={SKEY_ETHERSCAN} icon="/images/go-to.svg" variant="trietary" target="_blank">
                go to etherscan.io
              </Link>
              <Space space="space-md" />
              <div className="as-row">
                <p className="s2">Audit Report Provided By</p>
                <a href={SECURITY_AUDIT} target="blank" rel="noreferref noopener" className="mb-sm ml-xs">
                  <img width={98} height={42} src={"/images/red4sec.svg"} alt="red4sec" />
                </a>
              </div>
              <div className="as-row">
                <img width={18} height={18} src="/images/green-check-icon.svg" alt="License check icon" />
                <p className="s2 ml-xs">We've got Lithuania cryptographic license</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default OverviewExchangeStocks;
