import Group from "../../../components/arrangement/Group/Group";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { IProps } from "./IProps.interface";
import Explainer from "./Explainer";
import Stack from "../../../components/arrangement/Stack/Stack";

import "./ContentWithVideo.scss";

const ContentWithVideo = ({ content, videoUrl, ownVideoTag }: IProps) => {
  const isMobile = useIsMobile("md");

  return (
    <div className="content-with-video">
      <Group
        colGap={64}
        rowGap={50}
        fullWidth
        alignItems="center"
        justifyContent="space-between"
        className={isMobile ? "wrap-reverse" : ""}
      >
        <Stack fullHeight justifyContent="center">
          {content}
        </Stack>
        <Explainer url={videoUrl} ownVideoTag={ownVideoTag} />
      </Group>
    </div>
  );
};

export default ContentWithVideo;
