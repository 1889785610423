import { Col, Container, Row } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import "./FaqHero.scss";

const FaqHero = () => {
  return (
    <div id="faq-hero">
      <Container fluid="xl">
        <Row>
          <Col xs={12} sm={8} md={6} lg={5} className="faq-content-col">
            <h1>FAQ</h1>
            <Space space="space-lg" />
            <p className="s2">
              Frequently asked questions. Here are some of the most common questions about Skey Network.
            </p>
          </Col>
        </Row>
        <img width={500} height={503} src="/images/faq-hero-shape.svg" alt="hero shape" className="hero-shape" />
      </Container>
    </div>
  );
};

export default FaqHero;
