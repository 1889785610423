import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { TransactionData } from "../../../shared/interfaces/ITransactionData";
import { FRONTEND_UTILS_URL } from "../../../utils/config";
import { STATICTICS as SKEY_STATICTICS_URL } from "../../../constants/constants";
import InteractiveText from "../../components/atoms/InteractiveText/InteractiveText";

import Space from "../../components/atoms/Spacing/Space";
import Image from "../../components/atoms/Image/Image";
import Link from "../../components/atoms/Link/Link";

import "./OverviewSkeyNetworkToday.scss";

const OverviewSkeyNetworkToday = () => {
  const [totalTransactionsCount, setTotalTransactionsCount] = useState<TransactionData>();

  const getData = async () => {
    try {
      const response = await fetch(`${FRONTEND_UTILS_URL}/transactions-stats/latest`, {
        method: "GET"
      });
      const parsedResponse = await response.json();
      setTotalTransactionsCount(parsedResponse.transactionsData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  return (
    <div id="skey-network-today" className="o-x-hidden">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row>
            <Col xs={12} lg={8} className="exchange-col">
              <h2 className="header">Skey Network today</h2>
              <Space space={"space-md"} />
              <p className="s1 subheader">
                Skey Token has a crucial role in our ecosystem. Our technology is a combination of cryptocurrencies with
                the physical world of IoT.
              </p>
              <Space space="space-lg" />
              <Link url={SKEY_STATICTICS_URL} size="size-small" variant="primary">
                Show statistics
              </Link>
              <Space space="space-xxxl" className="desktop-lg" />
              <Image
                imgWidth={637}
                imgHeight={273}
                imgPath={"/images/overview-chart-mobile.svg"}
                alt={"chart"}
                className="mobile-lg chart"
              />
              <div className="skey-stock-exchange-data">
                <div className="interactive-data-holder">
                  <InteractiveText textRole={"h2"} className="gradient-text" badgeUrl="/images/burn-badge.svg">
                    {totalTransactionsCount?.totalBurnedTokens &&
                      new Intl.NumberFormat().format(totalTransactionsCount?.totalBurnedTokens)}
                  </InteractiveText>
                  <p>Burned tokens</p>
                </div>
                <div className="interactive-data-holder">
                  <InteractiveText textRole={"h2"} className="gradient-text" badgeUrl="/images/dollar-badge.svg">
                    {totalTransactionsCount?.lastDayAvgCost &&
                      new Intl.NumberFormat().format(totalTransactionsCount?.lastDayAvgCost)}
                  </InteractiveText>
                  <p>Avg. cost per transaction</p>
                </div>
                <div className="interactive-data-holder">
                  <InteractiveText textRole={"h2"} className="gradient-text" badgeUrl="/images/repeat-badge.svg">
                    {totalTransactionsCount?.totalTransactionsCount &&
                      new Intl.NumberFormat().format(totalTransactionsCount?.totalTransactionsCount)}
                  </InteractiveText>
                  <p>Monthly transactions</p>
                </div>
              </div>
            </Col>
          </Row>
          <Image
            imgWidth={997}
            imgHeight={273}
            imgPath={"/images/overview-chart.png"}
            alt={"chart"}
            className="desktop-lg chart"
          />
        </Container>
      </Container>
    </div>
  );
};

export default OverviewSkeyNetworkToday;
