import { Container } from "react-bootstrap";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import Group from "../../../../../components/arrangement/Group/Group";
import Card, { ICard } from "../../components/Card";

import "./NewRequirements.scss";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const requirementsFor: ICard[] = [
  {
    img: "/images/passport/new-requirements/1.svg",
    text: "Electronics"
  },
  {
    img: "/images/passport/new-requirements/2.svg",
    text: "Automotive"
  },
  {
    img: "/images/passport/new-requirements/3.svg",
    text: "Textiles"
  },
  {
    img: "/images/passport/new-requirements/4.svg",
    text: "Household appliances"
  }
];

const NewRequirements = () => {
  const isMobile = useIsMobile("lg");
  return (
    <div className="new-requirements">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Stack rowGap={isMobile ? 48 : 64} fullWidth>
            <Stack rowGap={20} fullWidth>
              <h2 className="t-xs-c m-xs-a">New requirements in industries</h2>
              <p className="s2 w-xs-100 t-xs-c m-xs-a" style={{ maxWidth: "540px" }}>
                The Digital Product Passport will soon become mandatory for companies in the following industries:
              </p>
            </Stack>
            <Group colGap={40} rowGap={40} fullWidth className="wrap" justifyContent="center">
              {requirementsFor.map((r, id) => (
                <Card {...r} className="requirement" key={id} />
              ))}
            </Group>
            <p className="s2 w-xs-100 t-xs-c m-xs-a" style={{ maxWidth: "540px" }}>
              Skey Network provides the complete infrastructure to easily implement these new standards, reduce costs,
              and increase your advantage in the European market. Contact us now to find out how we can build a market
              advantage together.
            </p>
          </Stack>
        </Container>
      </Container>
    </div>
  );
};

export default NewRequirements;
