/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import { useFormsControll } from "../../../../../contexts/FormsControllContext";
import { useDefaultProduct } from "../../../../../contexts/DefaultProductContext";
import { useModules } from "../../../../../contexts/ModulesContext";
import { useAgreements } from "../../../../../contexts/FormAgreementsContext";

import useFetch from "../../../../../hooks/useFetch";
import useFormName from "../../../../../hooks/useFormName";
import useFormProduct from "../../../../../hooks/useFormProduct";
import useFormEmail from "../../../../../hooks/useFormEmail";
import useCaptcha from "../../../../../hooks/useCaptcha";
import useFormMessage from "../../../../../hooks/useFormMessage";

import { EmailService } from "../../../../../services/EmailService";

import { skeyNetworkProductsNames } from "../../../../../data/productsOfSkeyNetwork";
import { CAPTCHA_KEY } from "../../../../../constants";

import ButtonLegacy from "../../../atoms/Button/ButtonLegacy";
import CustomInput from "../../../atoms/CustomInput/CustomInput";
import ContactForm from "../ContactForm/ContactForm";
import WithDropdown from "../../WithDropdown/WithDropdown";
import Space from "../../../atoms/Spacing/Space";
import SuccessFormContent from "../SuccessFormContent/SuccessFormContent";
import FailFormContent from "../FailFormContent/FailFormContent";
import CheckboxSection from "../../../organisms/CheckBoxSection/CheckboxSection";
import AgreementsSection from "../../../organisms/AgreementsSection/AgreementsSection";

import "./ModulesForm.scss";

const ModulesForm = () => {
  const { closeModulesForm, formStage, setSendSuccess, setSendFail, resetForm } = useFormsControll();
  const { defaultProduct } = useDefaultProduct();

  const { name, NAME_FIELD, focusName, blurName, handleName } = useFormName();
  const { product, focusProducts, blurProducts, handleProduct } = useFormProduct(defaultProduct);

  const { email, EMAIL_FIELD, focusEmail, blurEmail, handleEmail } = useFormEmail();
  const { message, MESSAGE_FIELD, focusMessage, blurMessage, handleMessage } = useFormMessage();

  const { captchaToken, handleCaptcha } = useCaptcha();

  const { options: modulesOptions, resetCheckboxes } = useModules();
  const { requiredAgreementsChecked, getCheckedAgreementsList } = useAgreements();

  const toShowModulesSection = product.name === "SkeyBOX";

  const onlyCheckedModules = modulesOptions.filter((module) => module.isChecked);
  const onlyModuleNames = onlyCheckedModules.map((module) => module.label);
  const joinedNames = onlyModuleNames.join(", ");

  const { doFetch, isLoading, data, error } = useFetch(async () =>
    EmailService.moduleEmail({
      name: name.text,
      email: email.text,
      product: product.name,
      message: message.text,
      captchaToken: captchaToken || "",
      modules: joinedNames || "Empty",
      agreements: getCheckedAgreementsList() || "Empty"
    })
  );

  const sendButtonDisabled =
    !Boolean(captchaToken) ||
    isLoading ||
    Boolean(email.error) ||
    Boolean(name.error) ||
    Boolean(product.error) ||
    !requiredAgreementsChecked;

  const resetFormWithTimeout = () => {
    setSendFail();
    setTimeout(() => {
      resetForm();
    }, 3000);
  };

  useEffect(() => {
    if (!data) return;
    const status = data.ok;

    if (status) return setSendSuccess();
    if (!status) return resetFormWithTimeout();
  }, [data]);

  //handle timeout
  useEffect(() => {
    if (!error) return;
    resetFormWithTimeout();
  }, [error]);

  useEffect(() => {
    return resetCheckboxes;
  }, []);

  const formContent = (
    <>
      <p className="s1">Fill out the form to receive an offer for SkeyBOX components.</p>
      <Space space="space-md" />
      <Row>
        <Col xs={12} sm={6}>
          <CustomInput
            required={true}
            text={name.text}
            error={name.error}
            label={NAME_FIELD.label}
            type={NAME_FIELD.type}
            placeholder={NAME_FIELD.placeholder}
            focus={name.focus}
            onChange={handleName}
            onFocus={focusName}
            onBlur={blurName}
          />
        </Col>
        <Col xs={12} sm={6}>
          <CustomInput
            required={true}
            text={email.text}
            error={email.error}
            label={EMAIL_FIELD.label}
            type={EMAIL_FIELD.type}
            placeholder={EMAIL_FIELD.placeholder}
            focus={email.focus}
            autoComplete={true}
            onChange={handleEmail}
            onFocus={focusEmail}
            onBlur={blurEmail}
          />
        </Col>
        <Col xs={12} sm={6}>
          <WithDropdown options={skeyNetworkProductsNames} onSelect={handleProduct} appear={product.focus}>
            <CustomInput
              required={true}
              text={product.name}
              error={product.error}
              label={"Product"}
              type={"text"}
              placeholder={"Select product"}
              focus={product.focus}
              dropdown={true}
              onChange={() => {}}
              onFocus={focusProducts}
              onBlur={blurProducts}
            />
          </WithDropdown>
        </Col>
        <Col xs={12} sm={6}>
          <CustomInput
            required={false}
            text={message.text}
            label={MESSAGE_FIELD.label}
            type={MESSAGE_FIELD.type}
            placeholder={MESSAGE_FIELD.placeholder}
            focus={message.focus}
            autoComplete={true}
            onChange={handleMessage}
            onFocus={focusMessage}
            onBlur={blurMessage}
          />
        </Col>
      </Row>

      {toShowModulesSection && (
        <>
          <CheckboxSection />
          <hr />
        </>
      )}

      <Space space="space-lg" />
      <AgreementsSection />
      <Space space="space-lg" />
      <div style={{ height: "75px" }}>
        {formStage === "form" && <ReCAPTCHA sitekey={CAPTCHA_KEY()} onChange={(token) => handleCaptcha(token)} />}
        {formStage === "failSend" && <FailFormContent />}
      </div>
      <Space space="space-xl" />
      <div className="cta-btns">
        <ButtonLegacy
          onClick={sendButtonDisabled ? () => {} : doFetch}
          disabled={sendButtonDisabled}
          variant="primary"
          className="same-width"
        >
          {isLoading ? "Sending..." : "Send inquiry"}
        </ButtonLegacy>
        <ButtonLegacy onClick={closeModulesForm} variant="secondary" elevate="flat">
          Cancel
        </ButtonLegacy>
      </div>
    </>
  );

  const successContent = <SuccessFormContent />;

  return (
    <ContactForm className="modules-form">
      {formStage !== "successSend" && <>{formContent}</>}
      {formStage === "successSend" && <>{successContent}</>}
    </ContactForm>
  );
};

export default ModulesForm;
