import { IInteractiveElement } from "../../../../shared/interfaces/IInteractiveElement";
import { IInteractiveLogoVariants } from "../../../../shared/interfaces/IInteractiveLogoVariants";
import "./InteractiveLogo.scss";

const InteractiveLogo = ({ variant, variants }: IInteractiveElement & IInteractiveLogoVariants) => {
  return (
    <>
      <img
        width={36}
        height={22}
        className="interactive-logo"
        src={variants.default.imgPath}
        alt="interactive logo default"
        style={{ opacity: variant === "default" ? 1 : 0 }}
      />
      <img
        width={36}
        height={22}
        className="interactive-logo"
        src={variants.hover.imgPath}
        alt="interactive logo hover"
        style={{ opacity: variant === "hover" ? 1 : 0 }}
      />
      <img
        width={36}
        height={22}
        className="interactive-logo"
        src={variants.active?.imgPath}
        alt="interactive logo active"
        style={{ opacity: variant === "active" ? 1 : 0 }}
      />
    </>
  );
};

export default InteractiveLogo;
