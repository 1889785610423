import { Container } from "react-bootstrap";
import { cooperationCards } from "../../../data/cooperationCards";
import GridCards from "../../components/organisms/GridCards/GridCards";
import CooperationCard from "../../components/molecules/CooperationCard/CooperationCard";
import "./CooperationCards.scss";

const CooperationCards = () => {
  return (
    <div id="cooperation-cards">
      <Container fluid="xxl">
        <Container fluid="xl">
          <GridCards maxColumns="2" gridGap="20">
            {cooperationCards.map((card) => {
              return <CooperationCard key={Math.random()} {...card} />;
            })}
          </GridCards>
        </Container>
      </Container>
    </div>
  );
};

export default CooperationCards;
