import { EXCHANGE_BITMART, EXCHANGE_KUCOIN, EXCHANGE_PROBIT, EXCHANGE_UNISWAP } from "../constants";
import { IExchangeStockProps } from "../shared/interfaces/IExchangeStockProps";

export const exchangeStocks: IExchangeStockProps[] = [
  {
    title: "Uniswap",
    description: "Decentralized exchange. Liquidity added by the community.",
    imgPath: "/images/exchange-uniswap.svg",
    url: EXCHANGE_UNISWAP
  },
  {
    title: "Probit",
    description: "Centralized exchange. Listed on Dec 2020.",
    imgPath: "/images/exchange-probit.svg",
    url: EXCHANGE_PROBIT
  },
  {
    title: "KuCoin",
    description: "One of the top 5 exchanges",
    imgPath: "/images/exchange-kucoin.svg",
    url: EXCHANGE_KUCOIN
  },
  {
    title: "BitMart",
    description: "Listed on Nov 2021.",
    imgPath: "/images/exchange-bitmart.svg",
    url: EXCHANGE_BITMART
  }
];

export const exchangeStockOnlyImages = [
  {
    url: EXCHANGE_PROBIT,
    img: "/images/exchanges/probit.svg",
    title: "Probit"
  },
  {
    url: EXCHANGE_BITMART,
    img: "/images/exchanges/bitmart.svg",
    title: "BitMart"
  },
  {
    url: EXCHANGE_KUCOIN,
    img: "/images/exchanges/kucoin.svg",
    title: "KuCoin"
  },
  {
    url: EXCHANGE_UNISWAP,
    img: "/images/exchanges/uniswap.svg",
    title: "Uniswap"
  }
];
