import { IOptions } from "../../../../shared/interfaces/IOptions";

import "./Dropdown.scss";

const Dropdown = ({ options, onSelect }: IOptions & any) => {
  return (
    <div className="skey-dropdown">
      {options.map((option: string) => {
        return (
          <button
            onClick={(e) => {
              e.preventDefault();
              onSelect(option);
            }}
            key={option}
          >
            {option}
          </button>
        );
      })}
    </div>
  );
};

export default Dropdown;
