import { useState } from "react";

const useBoolean = (initial: boolean) => {
  const [isTrue, setIsTrue] = useState(initial);

  const setTrue = () => setIsTrue(true);
  const setFalse = () => setIsTrue(false);

  const toggle = () => setIsTrue((prev) => !prev);

  return [isTrue, setTrue, setFalse, toggle] as const;
};

export default useBoolean;
