import { DEFAULT_INPUT_AMOUNT, FiatCodes, FiatProps, fiatsProps } from "../../constants/constants";
import Group from "../../../../../components/arrangement/Group/Group";
import Input from "../Input/Input";
import DropdownV2 from "../../../../components/DropdownV2/DropdownV2";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import Chips from "../Chips/Chips";

const orderedFiatsKeys: FiatCodes[] = ["EUR", "PLN", "USD"];

interface Props {
  fiatAmount: string;
  updateFiatAmount(value: string): void;
  changeFiatCode(code: FiatCodes): void;
  activeFiatProps: FiatProps;
}

const FiatInputLine = ({ activeFiatProps, changeFiatCode, fiatAmount, updateFiatAmount }: Props) => {
  return (
    <Group colGap={8} justifyContent="center" alignItems="center" className="input-line" fullWidth>
      <Input
        value={fiatAmount}
        onChange={(e) => updateFiatAmount(e.currentTarget.value)}
        placeholder={DEFAULT_INPUT_AMOUNT}
        type="number"
        className="buy-input"
        style={{ fontSize: "38px" }}
      />
      <DropdownV2
        list={
          <Stack rowGap={8}>
            {orderedFiatsKeys.map((code) => (
              <button onClick={() => changeFiatCode(code)} key={code}>
                <Chips style={{ padding: "4px", paddingRight: "8px", minWidth: "180px" }}>
                  <Group justifyContent="space-between" alignItems="center">
                    <Group colGap={8}>
                      <img src={fiatsProps[code].currencySymbol} alt="currency-symbol" />
                      <Stack rowGap={2}>
                        <p className="s2" style={{ lineHeight: 1 }}>
                          {fiatsProps[code].shortName}
                        </p>
                        <p className="p1 op-04" style={{ lineHeight: 1 }}>
                          {fiatsProps[code].longName}
                        </p>
                      </Stack>
                    </Group>
                    <img src="/images/icons/chevron-right-black.svg" alt="chevron-right" />
                  </Group>
                </Chips>
              </button>
            ))}
          </Stack>
        }
        className="pointer"
      >
        <Chips style={{ padding: "4px", paddingRight: "8px" }}>
          <Group justifyContent="center" alignItems="center" colGap={8}>
            <img src={activeFiatProps.currencySymbol} alt="currency-symbol" />
            <p className="s2" style={{ minWidth: "32px" }}>
              {activeFiatProps.shortName}
            </p>
            <img src="/images/icons/chevron-down-black.svg" alt="chevron-down" />
          </Group>
        </Chips>
      </DropdownV2>
    </Group>
  );
};

export default FiatInputLine;
