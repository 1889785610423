import { Container } from "react-bootstrap";

import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";

import "./ProductsSkeyTixNftModule.scss";

const ProductsSkeyTixNftModule = () => {
  return (
    <div id="skey-tix-nft-module" className="skey-tix-nft-module">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="skey-tix-nft-module-header">
            <h1 className="text-center">NFT Module</h1>
            <Space space="space-md" />
            <p className="s1 text-center">Creating, sending, and viewing NFTs</p>
          </div>
          <Space space="space-xxxl" className="desktop-lg" />

          <div className="skey-tix-nft-module-main">
            <div>
              <Space space="space-xl" className="desktop-lg" />
              <Space space="space-md" className="mobile-lg" />

              <p className="s2">
                At SkeyTIX, we constantly strive to innovate to make our solutions not only effective but also
                inspiring. Our latest NFT Module is a breakthrough in event management technology. It allows you to
                create, send, and view NFTs (Non-Fungible Tokens) associated with events, opening up entirely new
                possibilities for organizers and attendees.
              </p>
              <Space space="space-lg" />
              <Link
                imgWidth={16}
                imgHeight={15}
                url="https://medium.com/p/8d18e713dbe4/edit"
                target="_blank"
                variant="trietary"
                size="size-small"
                icon="/images/go-to.svg"
              >
                read how it works
              </Link>
            </div>

            <Space space="space-xl" className="mobile-md" />

            <div>
              <img
                width={435}
                height={276}
                src="/images/skey-tix-nft-module.png"
                className="desktop-lg"
                alt="SkeyTIX nft module"
              />
              <img
                width={286}
                height={182}
                src="/images/skey-tix-nft-module.png"
                className="mobile-lg"
                alt="SkeyTIX nft module"
              />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyTixNftModule;
