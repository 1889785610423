import Container from "react-bootstrap/esm/Container";
import { go2nftBoxKeys, go2nftBoxValues, sectionsTitles } from "../../data/data";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Group from "../../../../../components/arrangement/Group/Group";
import Box from "../../components/Box/Box";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

import "./Go2NFT.scss";

const go2nftBoxesOrder: typeof go2nftBoxKeys[number][] = ["mobile-app"];

const Go2NFT = () => {
  const isMobile = useIsMobile("md");
  return (
    <div className="go-2-nft pt-xs-8 pt-md-12">
      <Container fluid="xl">
        <Stack rowGap={isMobile ? 32 : 60}>
          <SectionTitle {...sectionsTitles["go-2-nft"]} />
          <Group fullWidth colGap={40} rowGap={isMobile ? 8 : 40} className="grid" alignItems="flex-start">
            {go2nftBoxesOrder.map((boxKey) => {
              const box = go2nftBoxValues[boxKey];
              return <Box {...box} />;
            })}
          </Group>
        </Stack>
      </Container>
    </div>
  );
};

export default Go2NFT;
