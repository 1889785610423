import { useState } from "react";

const useFetch = (request: () => Promise<any>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string>("");

  const setLoading = () => setIsLoading(true);
  const unsetLoading = () => setIsLoading(false);

  const doFetch = async () => {
    try {
      setLoading();
      const reqData = await request();
      setData(reqData);
      unsetLoading();
    } catch (e: any) {
      setError(e);
      unsetLoading();
    }
  };

  return { doFetch, isLoading, data, error };
};

export default useFetch;
