import { useEffect } from "react";
import useBoolean from "../../../../../hooks/useBoolean";
import Group from "../../../../../components/arrangement/Group/Group";
import { FiatCodes, fiatsProps } from "../../constants/constants";

interface Props {
  fiatCode: FiatCodes;
  shouldShake: boolean;
}

const MinimumLabel = ({ fiatCode, shouldShake }: Props) => {
  const [isShaking, shake, stopShake] = useBoolean(false);

  useEffect(() => {
    if (shouldShake) {
      shake();
    } else {
      stopShake();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShake]);

  // useEffect(() => {
  // if (!isShaking) return;
  // const id = setTimeout(stopShake, 200);
  // return () => clearTimeout(id);
  // }, [isShaking]);

  return (
    <Group
      className={`mb-xs-1 min-label ${isShaking ? "shake" : "stop-shake"}`}
      justifyContent="center"
      alignItems="center"
      colGap={4}
    >
      <p className={`op-05 p1`}>Minimum</p>
      <p className="underline p1">{fiatsProps[fiatCode].minAmount.toFixed(2)}</p>
      <img src="/images/icons/curved-arrow-down-black.svg" alt="arrow-down" style={{ marginLeft: "-4px" }} />
      <p className="op-05 p1">for {fiatCode}</p>
    </Group>
  );
};

export default MinimumLabel;
