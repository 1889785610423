import { HelmetProvider } from "react-helmet-async";
import ReactDOM from "react-dom/client";

import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";

import "./scss/lib.scss";
import "./scss/base.scss";
import "./scss/utils.scss";
import "./scss/grid.scss";
import "./scss/responsive.scss";

const rootElement = document.getElementById("root");
const ReactRoot = ReactDOM.createRoot(rootElement!);

ReactRoot.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);
