import {
  PACKAGE_1_AMOUNT,
  PACKAGE_1_PRICE,
  PACKAGE_2_AMOUNT,
  PACKAGE_2_PRICE,
  PACKAGE_3_AMOUNT,
  PACKAGE_3_PRICE
} from "../constants/offerConsts";

export interface IOffer {
  serviceName?: string;
  serviceType?: string;
  typeFee?: string;
  title?: string;
  price?: string;
  subHeader?: string;
  className?: string;
  divider?: boolean;
}

export interface IPricingOffer {
  title?: string;

  subHeader?: string;
  rows: IOffer[];
}

export const pricingOffer: IPricingOffer[] = [
  {
    title: "Pricing",
    rows: [
      {
        serviceName: "name of service",
        className: "pricing-header-serviceName"
      },
      {
        serviceName: "type of service"
      },
      {
        serviceName: "type of fee"
      },
      {
        serviceName: "net price"
      }
    ]
  }
];

export const offerStarter: IPricingOffer[] = [
  {
    subHeader: "SkeyBOX Starter",
    rows: [
      {
        serviceType: "activation",
        typeFee: "one-off",
        price: "€1000"
      }
    ]
  },
  {
    subHeader: "",
    rows: [
      {
        serviceType: "annual",
        typeFee: "recurring",
        price: "€599"
      }
    ]
  }
];

export const offerModules: IPricingOffer[] = [
  {
    title: "Modules",
    rows: [
      {
        subHeader: "Data API",
        serviceType: "annual",
        typeFee: "recurring",
        price: "€1"
      },
      {
        subHeader: "Durable Media",
        serviceType: "annual",
        typeFee: "recurring",
        price: "€300"
      },
      {
        subHeader: "Certificates",
        serviceType: "annual",
        typeFee: "recurring",
        price: "€300"
      }
    ]
  },
  {
    title: "Packages",
    rows: [
      {
        subHeader: PACKAGE_1_AMOUNT,
        serviceType: "package of transactions",
        typeFee: "one-off",
        price: PACKAGE_1_PRICE
      },
      {
        subHeader: PACKAGE_2_AMOUNT,
        serviceType: "package of transactions",
        typeFee: "one-off",
        price: PACKAGE_2_PRICE
      },
      {
        subHeader: PACKAGE_3_AMOUNT,
        serviceType: "package of transactions",
        typeFee: "one-off",
        price: PACKAGE_3_PRICE
      }
    ]
  }
];
