import { Container } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import "./Tokenomy.scss";

const Tokenomy = () => {
  return (
    <div id="tokenomy">
      <Container fluid="xxl">
        <Container fluid="xl">
          <h2 className="header">Tokenomy</h2>
          <Space space="space-xl" />
          <img
            width={1135}
            height={354}
            src="/images/deflation-system.svg"
            alt="images"
            className="tokenomy-img desktop-xl"
          />
          <img
            width={341}
            height={979}
            src="/images/deflation-system-mobile.svg"
            alt="images"
            className="tokenomy-img mobile-xl"
          />
        </Container>
      </Container>
    </div>
  );
};

export default Tokenomy;
