import { useState, useEffect } from "react";

import { SKEY_TOKEN_ADDRESS } from "../../../constants";
import { copyInput } from "../../../services/copyInput";
import { shortAddress } from "../../../services/shortAddress";
import ButtonLegacy from "../../components/atoms/Button/ButtonLegacy";

import "./CopyAddress.scss";

const CopyBtn = () => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  return (
    <ButtonLegacy
      width={16}
      height={15}
      icon={"/images/copy.svg"}
      onClick={() => {
        copyInput(SKEY_TOKEN_ADDRESS);
        setCopied(true);
      }}
      variant="trietary"
      className={`copy ${copied ? "copied" : ""}`}
    >
      {shortAddress(SKEY_TOKEN_ADDRESS, 12)}
    </ButtonLegacy>
  );
};

export default CopyBtn;
