import { IArticleCard } from "../../../../shared/interfaces/IArticlesCard";
import ArticleCard from "../../molecules/ArticleCard/ArticleCard";
import "./ArticleImageCard.scss";

const ArticleImageCard = ({ title, url, imageUrl }: IArticleCard) => {
  return (
    <div onClick={() => (!!url ? window.open(url, "_blank") : () => {})} className={"articleImageCard"}>
      <img src={imageUrl} alt="article" className="articleImageCard-image" width="500" height="250" />
      <ArticleCard
        // date={date}
        title={title}
        url={url}
        className="articleImageCard-title"
        hoverOutside={false}
      />
    </div>
  );
};

export default ArticleImageCard;
