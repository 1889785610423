import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth, innerHeight } = window;
  return {
    viewportWidth: innerWidth,
    viewportHeight: innerHeight
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};
