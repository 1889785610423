import { Container } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import "./DevelopmentHero.scss";

const DevelopmentHero = () => {
  return (
    <div id="development-hero">
      <img
        width="535"
        height="631"
        src="/images/development-hero-bg.png"
        alt="logo"
        className="development-bg-blocks desktop-lg"
      />
      <img
        width="469"
        height="534"
        src="/images/development-hero-bg-mobile.png"
        alt="logo"
        className="development-bg-blocks mobile-lg"
      />
      <Container fluid="xxl">
        <Container fluid="xl">
          <h1 className="header">Dive into Skey Network blockchain documentation</h1>
          <Space space="space-lg" />
          <p className="s2 subheader">Combine Oracle, Blockchain, DeFi, and NFT technologies.</p>
        </Container>
      </Container>
    </div>
  );
};

export default DevelopmentHero;
