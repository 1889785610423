import { Col, Container, Row } from "react-bootstrap";
import { IArticleCard } from "../../../shared/interfaces/IArticlesCard";
import ArticleCard from "../../components/molecules/ArticleCard/ArticleCard";
import { newsArticles } from "../../../data/newsArticles";
import ArticleImageCard from "../../components/organisms/ArticleImageCard/ArticleImageCard";
import "./NewsArticles.scss";

const NewsArticles = () => {
  const twoLatestArticle: IArticleCard[] = newsArticles.slice(0, 2);
  const articles: IArticleCard[] = newsArticles.slice(2);

  return (
    <div id="news-articles">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row className="row-style">
            {twoLatestArticle.map(({ title, url, imageUrl }: IArticleCard) => {
              return (
                <Col xs={12} md={6} className="imageCard-col" key={Math.random()}>
                  <ArticleImageCard
                    // date={date}
                    title={title}
                    url={url}
                    imageUrl={imageUrl}
                  />
                </Col>
              );
            })}
          </Row>

          <Row>
            {articles.map(({ title, url, date }: IArticleCard) => {
              return (
                <Col xs={12} md={6} lg={4} className="card-col" key={Math.random()}>
                  <ArticleCard title={title} url={url} date={date} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default NewsArticles;
