import { ICooperationProps } from "../shared/interfaces/ICooperationProps";

const smartContractPreset = {
  imgPath: "/images/partner-smart-contract-hover.svg",
  name: "Smartcontract",
  notClickable: true
};
const nodePreset = {
  imgPath: "/images/partner-node.svg",
  name: "Node"
};
const nftPreset = {
  imgPath: "/images/partner-nft-hover.svg",
  name: "NFT",
  notClickable: true
};
const androidApplicationPreset = {
  imgPath: "/images/partner-application.svg",
  name: "Android App"
};

const iOSApplicationPreset = {
  imgPath: "/images/partner-application.svg",
  name: "iOS App"
};

export const productsPreset = {
  smartContractPreset,
  nodePreset,
  nftPreset,
  androidApplicationPreset,
  iOSApplicationPreset
};

export const cooperationCards: ICooperationProps[] = [
  {
    icon: "/images/partner-orange.svg",
    title: "Orange",
    description:
      "Orange Polska is one of the most popular telecoms in Poland. Blockchain development cooperation in the area of the Internet of Things and Orange Lives Object. The combination of blockchain technology and the IoT is a real revolution in the world of technology and the global economy.",
    articleUrl:
      "https://skeynetwork.medium.com/smartkey-x-orange-the-worlds-first-blockchain-of-things-sim-for-smart-cities-9e7edad42d28",
    videoUrl: "https://www.youtube.com/watch?v=Vd-TD9dfB2M",
    products: []
  },
  {
    icon: "/images/partner-go2nft.svg",
    title: "Go2NFT",
    description:
      "Uses the Skey Network blockchain to generate corporate NFTs, which provide confirmation of the authenticity and ownership of products and a record of all parameters of a given item. This solution can be used by companies and individuals - anyone who wants to secure their product through digitization.",
    articleUrl: "https://go2nft.io/",
    products: [
      { ...smartContractPreset },
      { ...nftPreset },
      { ...nodePreset, url: "https://explorer.smartkeyplatform.io/nodes" }
    ]
  },
  {
    icon: "/images/partner-mazuryAirport.svg",
    title: "Mazury Airport",
    description:
      "A domestic airport in the region of Mazury, Poland where Skey Network has created a blockchain-based test application for access control within the airport infrastructure.",
    articleUrl: "https://mazuryairport.pl/",
    products: [{ ...nodePreset, url: "https://explorer.smartkeyplatform.io/nodes" }]
  },
  {
    icon: "/images/partner-caruma.svg",
    title: "Caruma",
    description:
      "Uses the Skey Network blockchain as a virtual key that provides access to many spaces - gates, barriers, and intercoms. It improves the work of municipal services because they can more easily get where they need to. These Polish cities have joined the Skey Network ecosystem: Kołobrzeg, Grudziądz, Warsaw, and Olsztyn.",
    articleUrl: "https://caruma.io/",
    products: [
      { ...smartContractPreset },
      { ...nftPreset },
      { ...nodePreset, url: "https://explorer.smartkeyplatform.io/nodes" },
      {
        ...androidApplicationPreset,
        url: "https://play.google.com/store/apps/details?id=io.caruma.onekey"
      }
    ]
  },
  {
    icon: "/images/partner-ferrumNetwork.svg",
    title: "Ferrum Network",
    description:
      "The next generation MultiChain Messaging Engine powering a Bridgeless Future. The Skey Network ecosystem will provide transparent mechanisms for gaining access to physical assets while users will be able to use staking mechanisms in their own business models.",
    articleUrl: "https://ferrum.network/"
  },

  {
    icon: "/images/partner-teltonika.svg",
    title: "Teltonika",
    description:
      "Teltonika specialties in GPS trackers (FMB920) which are mounted on emergency vehicles. Via the integration with Skey Network, users will be able to track their device’s location and control the corresponding locks to closed districts, private property barriers and building intercom systems via GPS and remote commands sent from the mobile app.",
    articleUrl: "https://teltonika-gps.com/about-us/pioneering-blockchain-project-for-emergency-services/",
    products: [{ ...nodePreset, url: "https://explorer.smartkeyplatform.io/nodes" }]
  },
  {
    icon: "/images/partner-chainlink.svg",
    title: "Chainlink",
    description:
      "Increasing the compatibility and integration of other blockchains (e.g., EVM) with the Skey Network blockchain to increase compatibility and the possibility of using Skey Access Control.",
    articleUrl:
      "https://skeynetwork.medium.com/smartkey-and-chainlink-to-collaborate-in-government-approved-blockchain-project-to-power-smart-e205e2a2ac13",
    videoUrl: "https://www.youtube.com/watch?v=IiVzZWHAD70",
    products: []
  },
  {
    icon: "/images/partner-vsr.svg",
    title: "VSR",
    description:
      "One of the top Italian GT racing teams supported by Lamborghini Squadra Corse. Via the platform of Go2NFT which is built on Skey Network blockchain, motorsport data management is being created and provided.",
    articleUrl: "https://www.vs-racing.com/en/",
    videoUrl: "https://www.youtube.com/watch?v=gY-dO09Puw8&t=16s",
    products: [{ ...smartContractPreset }, { ...nodePreset, url: "https://explorer.smartkeyplatform.io/nodes" }]
  },
  {
    icon: "/images/partner-waste24.svg",
    title: "Waste24",
    description:
      "A comprehensive system for municipal management. The system simplifies the process of working with BDOs via the Skey Network blockchain.",
    articleUrl: "https://waste24.net",
    products: []
  },
  {
    icon: "/images/partner-copernicoin.svg",
    title: "Copernicoin",
    description:
      "A cooperation with the Warmian-Masurian Voivodeship in a project expromoting tourism using NFT tokens and Skey Network blockchain technology.",
    articleUrl: "https://copernicoin.pl/",
    products: [
      { ...smartContractPreset },
      { ...nodePreset, url: "https://explorer.smartkeyplatform.io/nodes" },
      {
        ...androidApplicationPreset,
        url: "https://play.google.com/store/apps/details?id=pl.copernicoin.app&hl=pl"
      }
    ]
  },
  {
    icon: "/images/partner-vpplant.svg",
    title: "VP Plant",
    description:
      "Cooperation with Virtual Power Plant is an example of the use of blockchain technology to increase energy efficiency. Blockchain also affects the stability and precision of obtaining energy from renewable resources. The method of storing and using energy is essential here. Skey Network blockchain helps to reduce energy costs and manage this energy much more efficiently.",
    articleUrl: "https://skeynetwork.medium.com/skey-network-ecosystem-in-the-energy-sector-93feb167d596",
    videoUrl: "https://www.youtube.com/watch?v=nKVfm_kx-E4&t=82s",
    products: [{ ...smartContractPreset }, { ...nodePreset, url: "https://explorer.smartkeyplatform.io/nodes" }]
  }
];

export const getCardObjectByTitle = (title: string) => {
  return cooperationCards.filter((card) => card.title === title)[0];
};
