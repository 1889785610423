import { IHoverIconProps } from "../../../../shared/interfaces/IHoverIconProps";
import "./HoverIcon.scss";

const HoverIcon = ({ iconPath, isHover, imgWidth, imgHeight, doHover = true, className = "" }: IHoverIconProps) => {
  const divider = ".";
  const parts = iconPath.split(divider);

  const pathBase = parts[0];
  const extension = parts[1];

  const iconUnhovered = iconPath;
  const iconHovered = `${pathBase}-hover.${extension}`;

  const withHover = (
    <>
      <img
        src={iconUnhovered}
        alt="not hovered"
        hidden={isHover}
        className={className}
        width={imgWidth}
        height={imgHeight}
      />
      <img
        width={imgWidth}
        height={imgHeight}
        src={iconHovered}
        alt="hovered"
        hidden={!isHover}
        className={className}
      />
    </>
  );

  const withoutHover = (
    <img src={iconUnhovered} width={imgWidth} height={imgHeight} alt="not hovered" className={className} />
  );

  return (
    <>
      {doHover && <>{withHover}</>}
      {!doHover && <>{withoutHover}</>}
    </>
  );
};

export default HoverIcon;
