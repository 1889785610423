import Stack from "../../../../../components/arrangement/Stack/Stack";
import { BoxProps } from "../../data/data";
import Group from "../../../../../components/arrangement/Group/Group";

import "./Box.scss";

interface LinkProps {
  url: string;
  label: string;
  icon: string;
}

const Link = ({ icon, label, url }: LinkProps) => {
  return (
    <a href={url} target="_blank" rel="noreferrer noopener nofollow" key={Math.random()} className="w-xs-100 w-sm-mc">
      <Group colGap={6} className="pt-xs-1 pb-xs-1">
        <p className="s2-other capitalize" style={{ color: "#5c44ec" }}>
          {label}
        </p>
        <img src={icon} alt="icon" />
      </Group>
    </a>
  );
};

const Box = ({ title, description, pdfsUrl, ytUrls, appUrls }: BoxProps) => {
  const pdfs = pdfsUrl.map((pdf) => ({ url: pdf, label: "presentation", icon: "/images/info/download.svg" }));
  const yt = ytUrls.map((ytUrl) => ({ url: ytUrl, label: "youtube", icon: "/images/info/go-to.svg" }));

  return (
    <div className="box">
      <Stack fullSize rowGap={8}>
        <h4 className="regular">{title}</h4>
        <p className="s2 pb-xs-1 pb-md-3 capitalize">{description}</p>
        {(appUrls?.appStore || appUrls?.googlePlay) && (
          <Group colGap={16} rowGap={8} className="pb-xs-1 pb-md-2">
            {!!appUrls?.googlePlay && (
              <a href={appUrls?.googlePlay} target="_blank" rel="noreferrer noopener nofollow">
                <img src="/images/graphics/google-play.svg" alt="google play button" style={{ height: "32px" }} />
              </a>
            )}
            {!!appUrls?.appStore && (
              <a href={appUrls?.appStore} target="_blank" rel="noreferrer noopener nofollow">
                <img src="/images/graphics/app-store.svg" alt="app store button" style={{ height: "32px" }} />
              </a>
            )}
          </Group>
        )}
        <Group className="grid" colGap={30} rowGap={0}>
          {pdfs.map((pdf, key) => (!!pdf.url ? <Link {...pdf} /> : <></>))}
          {yt.map((ytLink, key) => (!!ytLink.url ? <Link {...ytLink} /> : <></>))}
        </Group>
      </Stack>
    </div>
  );
};

export default Box;
