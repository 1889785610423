import Image from "../../atoms/Image/Image";
import Space from "../../atoms/Spacing/Space";
import { IDocsCard } from "../../../../shared/interfaces/IDocsCard";
import "./DocsCard.scss";

const DocsCard = ({ imgPath, header, subheader, className }: IDocsCard) => {
  return (
    <div className={`docs-card ${className ? className : ""}`}>
      <Image imgPath={imgPath} alt="card img" className="docs-image" imgWidth={147} imgHeight={147} />
      <h2 className="header">{header}</h2>
      <Space space="space-md" />
      <p className="s2 subheader">{subheader}</p>
    </div>
  );
};

export default DocsCard;
