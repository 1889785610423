import ProductsSkeyBoxHero from "../../sections/ProductsSkeyBoxHero/ProductsSkeyBoxHero";
import ProductsSkeyBoxFeatures from "../../sections/ProductsSkeyBoxFeatures/ProductsSkeyBoxFeatures";
import ProductsSkeyBoxPlatform from "../../sections/ProductsSkeyBoxPlatform/ProductsSkeyBoxPlatform";
import ProductsSkeyBoxModules from "../../sections/ProductsSkeyBoxModules/ProductsSkeyBoxModules";
import ProductsSkeyBoxIntegration from "../../sections/ProductsSkeyBoxIntegration/ProductsSkeyBoxIntegration";
import ProductsSkeyBoxOffer from "../../sections/ProductsSkeyBoxOffer/ProductsSkeyBoxOffer";
import ContactProductSkeyBox from "../../sections/ContactProductSkeyBox/ContactProductSkeyBox";

const ProductsSkeyBox = () => {
  return (
    <section id="products-skey-box">
      <ProductsSkeyBoxHero />
      <ProductsSkeyBoxFeatures />
      <ProductsSkeyBoxPlatform />
      <ProductsSkeyBoxModules />
      <ProductsSkeyBoxIntegration />
      <ProductsSkeyBoxOffer />
      {/*<TechnicalDocumentation links={} />*/}
      <ContactProductSkeyBox />
    </section>
  );
};

export default ProductsSkeyBox;
