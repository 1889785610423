export const productsSkeyBoxPlatform = [
  {
    img: "/images/homeBox.png",
    header: "The Configurator",
    title:
      "The Configurator allows you to register your company, including its name, description, logo, and other details, and generate a node - that is, a node of the Skey Network blockchain network."
  },
  {
    img: "/images/panelBox.png",
    header: "The Client Panel",
    title:
      "The Client Panel is used to save and read data in the blockchain network and enables the integration of your services' API."
  }
];
