import { useId } from "react";

import { ICustomInputProps } from "../../../../shared/interfaces/ICustomInputProps";

import "./CustomInput.scss";

const CustomInput = ({
  text,
  required,
  error,
  label,
  type,
  placeholder,
  focus,
  autoComplete = false,
  dropdown = false,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {}
}: ICustomInputProps) => {
  const buildClass = () => {
    let classString = "";
    if (text) {
      classString += "typed";
    }
    if (error) {
      classString += " error";
    }
    if (required) {
      classString += " required";
    }

    return classString;
  };

  const handleChange = (text: string) => {
    onChange(text);
  };

  const id = useId();

  return (
    <div className="input-wrapper">
      <label htmlFor={id} className={`p1  ${focus ? "focus" : ""} ${buildClass()}`}>
        {label}
      </label>
      <input
        required={required}
        id={id}
        className={`skey-input s2 ${buildClass()}`}
        onChange={(e) => handleChange(e.currentTarget.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
        value={text}
        placeholder={placeholder}
        autoComplete={autoComplete ? "on" : "off"}
      ></input>
      <span className={`error-text`}>{error}</span>
      {dropdown && <img src="/images/down-arrow-black.svg" alt="arrow" className="down-arrow" />}
    </div>
  );
};
export default CustomInput;
