import { Col, Container, Row } from "react-bootstrap";
import Image from "../../components/atoms/Image/Image";
import Link from "../../components/atoms/Link/Link";
import Space from "../../components/atoms/Spacing/Space";
import "./OverviewSkeyEcosystem.scss";
import { SKEY_BOX } from "../../../constants";

const OverviewSkeyEcosystem = () => {
  return (
    <div id="overview-skey-ecosystem" className="o-x-hidden">
      <Image
        imgWidth={1750}
        imgHeight={1187}
        imgPath="/images/overview-skey-ecosystem.png"
        alt="skey ecosystem"
        className="skey-ecosystem-bg desktop-lg"
      />
      <Image
        imgWidth={1091}
        imgHeight={740}
        imgPath="/images/overview-skey-ecosystem-mobile.png"
        alt="skey ecosystem"
        className="skey-ecosystem-bg mobile-lg"
      />
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row className="main-row">
            <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6, offset: 2 }} className="main-col">
              <h2 className="header">More than just a blockchain</h2>
              <Space space="space-lg" />
              <p className="s1 subheader">
                SkeyBOX - Bring your business into blockchain in just a few minutes. SkeyBOX is a set of a few tools to
                enable the implementation of blockchain technology, reducing the integration time.
              </p>
              <Space space="space-xxl" />
              <div className="links">
                <Link
                  variant="primary"
                  url={SKEY_BOX}
                  target={"_blank"}
                  className=""
                  size="size-normal"
                  mode="normal-mode"
                >
                  See more
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default OverviewSkeyEcosystem;
