import { useState } from "react";

import { IProductCard } from "../../../../shared/interfaces/IProductCard";
import { ComponentActiveState } from "../../../../shared/types/IComponentActiveState";
import InteractiveLogo from "../../atoms/InteractiveLogo/InteractiveLogo";
import InteractiveText from "../../atoms/InteractiveText/InteractiveText";
import Space from "../../atoms/Spacing/Space";

import "./ProductCard.scss";

const ProductCard = ({ title, linkLabel, text, url, linkImgPath }: IProductCard) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hover, setHover] = useState(false);
  const [variant, setVariant] = useState<ComponentActiveState>("default");

  const turnDefault = () => setVariant("default");
  const turnHover = () => setVariant("hover");
  // const turnActive = () => setVariant("active");

  const handleHover = () => {
    if (variant === "default") {
      turnHover();
      return;
    }
    if (variant === "hover") {
      return;
    }
    if (variant === "active") {
      return;
    }
  };

  const handleBlur = () => {
    if (variant === "default") {
      return;
    }
    if (variant === "hover") {
      turnDefault();
      return;
    }
    if (variant === "active") {
      return;
    }
  };

  const logoVariants = {
    default: {
      imgPath: "/images/skey-interactive-logo-default.svg"
    },
    active: {
      imgPath: "/images/skey-interactive-logo-hover.svg"
    },
    hover: {
      imgPath: "/images/skey-interactive-logo-hover.svg"
    }
  };

  return (
    <div className="product-card" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <article className={`product-card ${variant}`} onMouseOver={handleHover} onMouseLeave={handleBlur}>
        <a href={url} target="_blank" rel="noreferrer noopener">
          <div className="product-card-inside">
            <div className="card-head">
              <InteractiveText variant={variant} textRole="ps1" weight="regular">
                {title}
              </InteractiveText>
              <div className="logo-wrapper">
                <InteractiveLogo variant={variant} variants={logoVariants} />
              </div>
            </div>
            <Space space="space-sm" />
            <InteractiveText variant={"default"} textRole={"ps2"} weight={"regular"} className={"subheader"}>
              {text}
            </InteractiveText>
            <Space space="space-xl" />
            <InteractiveText
              imgWidth={16}
              imgHeight={15}
              variant={"default"}
              textRole={"ps2"}
              weight={"regular"}
              iconPath={linkImgPath}
              className="as-link"
              active={variant === "hover"}
            >
              {linkLabel}
            </InteractiveText>
          </div>
        </a>
      </article>
    </div>
  );
};

export default ProductCard;
