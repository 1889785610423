import { BaseProps } from "../../../../shared/interfaces/BaseProps";
import { SKEY_STAKING } from "../../../../constants";

const StakingButtonLink = (props: BaseProps) => {
  return (
    <a href={SKEY_STAKING} target="_blank" rel="noreferrer" className={props.className}>
      {props.children}
    </a>
  );
};

export default StakingButtonLink;
