import { Col, Container, Row } from "react-bootstrap";
import { SKEY_GITBOOK, WHITE_PAPER } from "../../../constants";
import { documentationCards } from "../../../data/documentationCards";
import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";
import "./OverviewDocumentation.scss";

import DocsCard from "../../components/molecules/DocsCard/DocsCard";

const OverviewDocumentation = () => {
  return (
    <div id="overview-documentation">
      <Container fluid="xxl">
        <Container fluid="xl" className="as-center-col">
          <h2 className="header-overview">Discover highly efficient infrastructure</h2>
          <Space space="space-lg" className="space-1" />
          <div className="buttons">
            <Link url={SKEY_GITBOOK} variant="secondary" target="_blank" size="size-normal" icon="/images/gitbook.svg">
              Show Gitbook
            </Link>
            <Link
              url={WHITE_PAPER}
              variant="secondary"
              target="_blank"
              size="size-normal"
              icon="/images/whitepaper.svg"
            >
              Download WhitePaper
            </Link>
          </div>
          <Space space="space-xxl" className="space-2" />
          <p className="s1 subheader">Skey Network enables a world of blockchain, IoT, NFTs, and new business models</p>
          <Space space="space-xxl" className="space-3 desktop-lg" />
          <Space space="space-xxxl" className="space-3 mobile-lg" />
          <Row className="doc-row">
            {documentationCards.map((card) => (
              <Col xs={12} md={6} lg={4} key={Math.random()}>
                <DocsCard imgPath={card.imgPath} header={card.header} subheader={card.subheader} className="doc-card" />
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  );
};
export default OverviewDocumentation;
