import ProductsBlockchainHero from "../../sections/ProductsBlockchainHero/ProductsBlockchainHero";
import ProductsBlockchainFeatures from "../../sections/ProductsBlockchainFeatures/ProductsBlockchainFeatures";
import "./ProductsBlockchain.scss";
import ContactProductBlockchain from "../../sections/ContactSection/ContactProductBlockchain/ContactProductBlockchain";
import TechnicalDocumentation from "../../sections/TechnicalDocumentation/TechnicalDocumentation";
import { productBlockchainDocumentationLinks } from "../../../data/productBlockchainLinks";
import Tokenomy from "../../sections/Tokenomy/Tokenomy";
import ProductUseCases from "../../sections/ProductUseCases/ProductUseCases";
import { getCardObjectByTitle } from "../../../data/cooperationCards";

const Go2NftCard = getCardObjectByTitle("Go2NFT");

const ProductsBlockchain = () => {
  return (
    <section id="products-blockchain">
      <ProductsBlockchainHero />
      <ProductsBlockchainFeatures />
      <ProductUseCases useCaseCard={{ ...Go2NftCard }} />
      <Tokenomy />
      <TechnicalDocumentation links={productBlockchainDocumentationLinks} />
      <ContactProductBlockchain />
    </section>
  );
};

export default ProductsBlockchain;
