export const skeyBoxManagement = [
  {
    img: "/images/management-skey-box.svg",
    header: "Data management",
    subHeader: [
      "Cryptographic protection of data.",
      "Transparency of data.",
      "Higher level of security for the protection of personal data.",
      "Reduced documentation management costs.",
      "Building credibility by presenting documents."
    ]
  },
  {
    img: "/images/marketing-skey-box.svg",
    header: "Marketing opportunities",
    subHeader: [
      "Emphasizing the company's innovation through the implementation of new, ground-breaking technologies.",
      "Using innovative solutions to build business advantages."
    ]
  },

  {
    img: "/images/traceability-skey-box.svg",
    header: "Product traceability and verifiability",
    subHeader: [
      "Building a brand by securing the authenticity of the product in the form of an NFT.",
      "Trust and confirmation of originality thanks to a unique digital passport/certificate, a digital twin, or a phygital asset."
    ]
  },
  {
    img: "/images/business-skey-box.svg",
    header: "New products and new business models",
    subHeader: [
      "Creating new products and services.",
      "Discovering new business opportunities.",
      "Enabling the creation of new business models and innovative solutions based on undeniable data through the use of flexible technology."
    ]
  }
];
