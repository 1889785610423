import { CSSProperties } from "react";
import { classNames } from "../../../../utils/classNames";
import { TChildren } from "../../../../shared/interfaces/IChildren";

import "./ButtonV2.scss";

interface Props {
  size: "sm" | "md" | "lg";
  hierarchy: "primary" | "secondary" | "tertiary";
  isFlat?: boolean;
  className?: string;
  style?: CSSProperties;
  isDisabled?: boolean;
  children: TChildren;
}

const ButtonV2 = ({ hierarchy, size, className, isDisabled, style, children, isFlat }: Props) => {
  return (
    <div
      className={classNames(
        "button-v2",
        size,
        hierarchy,
        isDisabled ? "disabled" : "",
        className,
        isFlat ? "flat" : ""
      )}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default ButtonV2;
