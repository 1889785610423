import { Container } from "react-bootstrap";
import { developmentProducts } from "../../../data/developmentProducts";
import { IProductCard } from "../../../shared/interfaces/IProductCard";
import GridCards from "../../components/organisms/GridCards/GridCards";
import ProductCard from "../../components/molecules/ProductCard/ProductCard";
import "./DevelopmentProducts.scss";

const DevelopmentProducts = () => {
  return (
    <div id="development-products">
      <Container fluid="xxl">
        <Container fluid="xl">
          <GridCards maxColumns="3" gridGap="20">
            {developmentProducts.map(({ linkLabel, text, title, url, linkImgPath }: IProductCard) => {
              return (
                <ProductCard
                  title={title}
                  text={text}
                  linkLabel={linkLabel}
                  url={url}
                  linkImgPath={linkImgPath}
                  key={title}
                />
              );
            })}
          </GridCards>
        </Container>
      </Container>
    </div>
  );
};

export default DevelopmentProducts;
