export const overviewBlockchain = [
  {
    img: "/images/skey-sticker.jpeg",
    header: "Blockchain Internet of Things",
    subheader: "Do you need blockchain for your IoT devices?"
  },
  {
    img: "/images/skey-sticker.jpeg",
    header: "Generating NFT",
    subheader: "Do you want to generate cheap NFTs on a large scale?"
  },
  {
    img: "/images/skey-sticker.jpeg",
    header: "Blockchain NODE",
    subheader: "Use your own commercial NODE"
  }
];
