import { useState } from "react";
import { SkeyProducts } from "../contexts/DefaultProductContext";

interface ProductFields {
  name: SkeyProducts;
  error: string;
  focus: boolean;
}

interface ProductFormValue {
  product: ProductFields;
  focusProducts: () => void;
  blurProducts: () => void;
  handleProduct: (product: SkeyProducts) => void;
}

const useFormProduct = (defaultProduct: SkeyProducts): ProductFormValue => {
  const [product, setProduct] = useState<ProductFields>({
    name: defaultProduct,
    error: "",
    focus: false
  });

  const focusProducts = () => setProduct((prev) => ({ ...prev, focus: true }));
  const blurProducts = () =>
    setTimeout(() => {
      setProduct((prev) => ({ ...prev, focus: false }));
    }, 150);

  const handleProduct = (product: SkeyProducts) => {
    setProduct((prev) => ({ ...prev, name: product }));
    blurProducts();
  };

  return { product, focusProducts, blurProducts, handleProduct };
};

export default useFormProduct;
