import { Col, Container, Row } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import DocsCard from "../../components/molecules/DocsCard/DocsCard";
import { featuresCards } from "../../../data/featuresCards";
import "./ProductsBlockchainFeatures.scss";

const ProductsBlockchainFeatures = () => {
  return (
    <div id="products-blockchain-features">
      <Container fluid="xxl">
        <Container fluid="xl" className="as-center-col">
          <h2 className="header-main">What will you gain from it?</h2>
          <Space space="space-xxxl" className="desktop-lg" />
          <Space space="space-xxl" className="mobile-lg" />
          <Row className="gaps">
            {featuresCards.map(({ imgPath, header, subheader }) => (
              <Col xs={12} md={6} lg={3} key={Math.random()}>
                <DocsCard imgPath={imgPath} header={header} subheader={subheader} className="doc-card" />
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsBlockchainFeatures;
