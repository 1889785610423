import { useState } from "react";
import { validateEmail } from "../services/validateEmail";

const useFormEmail = () => {
  const [email, setEmail] = useState({
    text: "",
    error: "",
    focus: false
  });

  const EMAIL_FIELD = {
    label: "Email",
    placeholder: "Enter your email",
    type: "email"
  };
  const focusEmail = () => setEmail((prev) => ({ ...prev, focus: true }));
  const blurEmail = () => setEmail((prev) => ({ ...prev, focus: false }));
  const handleEmail = (value: string) => {
    // @ts-ignore
    const { error } = validateEmail(value);

    if (!error) {
      setEmail((prev) => ({ ...prev, text: value, error: "" }));
      return;
    }

    setEmail((prev) => ({ ...prev, text: value, error: error }));
  };

  return { email, EMAIL_FIELD, focusEmail, blurEmail, handleEmail };
};

export default useFormEmail;
