import { productsSkeyTixLinks } from "../../../data/productsSkeyTixLinks";

import ProductsSkeyTixHero from "../../sections/ProductsSkeyTixHero/ProductsSkeyTixHero";
import ProductsSkeyTixFeatures from "../../sections/ProductsSkeyTixFeatures/ProductsSkeyTixFeatures";
import SkeyTixOperation from "../../sections/SkeyTixOperation/SkeyTixOperation";
import SkeyTixBenefits from "../../sections/SkeyTixBenefits/SkeyTixBenefits";
import SkeyTixCooperartion from "../../sections/SkeyTixCooperation/SkeyTixCooperartion";
import AdministrationPanel from "../../sections/AdministrationPanel/AdministrationPanel";
import ProductsSkeyTixNftModule from "../../sections/ProductsSkeyTixNftModule/ProductsSkeyTixNftModule";
import SkeyTixWhyNft from "../../sections/SkeyTixWhyNft/SkeyTixWhyNft";
import SkeyTixPoap from "../../sections/SkeyTixPoap/SkeyTixPoap";
import SkeyTixIntegration from "../../sections/SkeyTixIntegration/SkeyTixIntegration";
import SkeyTixFaq from "../../sections/SkeyTixFaq/SkeyTixFaq";
import SkeyTixTokenomy from "../../sections/SkeyTixTokenomy/SkeyTixTokenomy";
import TechnicalDocumentation from "../../sections/TechnicalDocumentation/TechnicalDocumentation";
import ContactProductSkeyTix from "../../sections/ContactProductSkeyTix/ContactProductSkeyTix";

import "./ProductsSkeyTix.scss";

const ProductsSkeyTix = () => {
  return (
    <section id="products-skey-tix">
      <ProductsSkeyTixHero />
      <ProductsSkeyTixFeatures />
      <SkeyTixOperation />
      <SkeyTixBenefits />
      <SkeyTixCooperartion />
      <AdministrationPanel />
      <ProductsSkeyTixNftModule />
      <SkeyTixWhyNft />
      <SkeyTixPoap />
      <SkeyTixIntegration />
      <SkeyTixFaq />
      <SkeyTixTokenomy />
      <TechnicalDocumentation links={productsSkeyTixLinks} />
      <ContactProductSkeyTix />
    </section>
  );
};

export default ProductsSkeyTix;
