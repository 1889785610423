import { BaseProps } from "../../../../shared/interfaces/BaseProps";

import "./Image.scss";

export interface IImage extends BaseProps<HTMLImageElement> {
  imgPath: string;
  asLink?: boolean;
  asButton?: boolean;
  onClick?: () => void;
  onBlur?: () => void;
  href?: string;
  className?: string;
  alt: string;
  imgHeight?: number;
  imgWidth?: number;
}

const Image = ({
  imgPath,
  asLink,
  href,
  className = "",
  alt,
  imgWidth,
  imgHeight,
  asButton = false,
  onClick = () => {},
  onBlur = () => {},
  style
}: IImage) => {
  const img = (
    <img className={`image ${className}`} alt={alt} src={imgPath} width={imgWidth} height={imgHeight} style={style} />
  );

  if (asLink) {
    return (
      <a href={href} rel="noreferrer noopener" className={`as-link ${className}`} style={style}>
        {img}
      </a>
    );
  }

  if (asButton) {
    return (
      <button onClick={onClick} onBlur={onBlur} className={className} style={style}>
        {img}
      </button>
    );
  }

  return <>{img}</>;
};

export default Image;
