import { IArticleCard } from "../shared/interfaces/IArticlesCard";

export const newsArticles: IArticleCard[] = [
  {
    title: "The Digital Product Passport (DPP)",
    url: "https://skeynetwork.medium.com/the-digital-product-passport-paving-the-way-for-a-circular-economy-in-the-eu-991135ee78a0",
    imageUrl: "articles/dpp.png"
  },
  {
    title: "Revolutionizing Real-World Assets (RWA)",
    url: "https://skeynetwork.medium.com/revolutionizing-real-world-assets-with-skeynetwork-introduction-a29e887c6705",
    imageUrl: "articles/rwa.jpg"
  },
  {
    title: "SkeyTIX - NFT Ticketing",
    url: "https://skeynetwork.medium.com/skeytix-a-digital-and-non-counterfeit-nft-ticket-that-will-reduce-registration-time-for-your-637e37cc6fa6"
  },
  {
    title: "SkeyBOX — a secure and easy-to-use tool",
    url: "https://skeynetwork.medium.com/skey-box-a-secure-and-easy-to-use-blockchain-that-will-take-your-business-to-web-3-0-4ad249253167"
  },
  {
    title: "SkeyBOX as a durable medium",
    url: "https://skeynetwork.medium.com/skeybox-durable-medium-as-a-turn-key-solution-8b13ea13fb58"
  },
  {
    title: "Skey Network introduces POAPs",
    url: "https://skeynetwork.medium.com/skeynetwork-introduces-poaps-easy-nfts-for-every-event-8d18e713dbe4"
  },
  {
    title: "SkeyBOX certificates",
    url: "https://skeynetwork.medium.com/skeybox-certificates-a-paradigm-shift-in-professional-certification-0a0f957f9965"
  },
  {
    title: "Teltonika integrates Skey Network blockchain",
    url: "https://skeynetwork.medium.com/teltonika-introduces-blockchain-to-its-iot-devices-in-collaboration-with-skeynetwork-7eb0febd3d18"
  },
  {
    title: "NFT and blockchain — a game changer",
    url: "https://skeynetwork.medium.com/nft-and-blockchain-a-game-changer-and-an-answer-to-market-challenges-ef38b059acb6"
  },
  {
    title: "Skey Network forms strategic partnership",
    url: "https://skeynetwork.medium.com/skey-network-forms-strategic-partnership-to-develop-rwa-services-58d81c81d680"
  },
  {
    title: "A new frontier for NFTs",
    url: "https://skeynetwork.medium.com/a-new-frontier-for-nfts-1782365d6ccc"
  },
  {
    date: "12 January 2023",
    title: "Skey Network — Summary of 2022 and Vision",
    url: "https://skeynetwork.medium.com/skey-network-summary-of-2022-and-vision-22aadd5a75d4",
    imageUrl: "articles/skey-vision.jpg"
  },
  {
    date: "4 January 2023",
    title: "SkeyTIX — the ticket of the future",
    url: "https://skeynetwork.medium.com/skey-tix-the-ticket-of-the-future-d9955b5160da",
    imageUrl: "articles/skey-ticket.jpg"
  },
  {
    date: "16 November 2022",
    title: "Skey Network Ecosystem in the Energy Sector",
    url: "https://skeynetwork.medium.com/skey-network-ecosystem-in-the-energy-sector-93feb167d596"
  },
  {
    date: "9 November 2022",
    title: "Blockchain in your company — from concept to implementation",
    url: "https://skeynetwork.medium.com/blockchain-in-your-company-from-concept-to-implementation-1fd202bf871a"
  },
  {
    date: "9 August 2022",
    title: "Skey Network — Growth building strategy",
    url: "https://skeynetwork.medium.com/skey-network-growth-building-strategy-534fc30c764b",
    imageUrl: "articles/fintech-time.jpeg"
  },
  {
    date: "6 July 2022",
    title: "Skey Network Ecosystem Expansion",
    url: "https://skeynetwork.medium.com/skey-network-ecosystem-expansion-e7e6b2dd5ad3",
    imageUrl: "articles/smart-nft.jpeg"
  },
  {
    date: "19 May 2022",
    title: "The first city operating on the Skey Network! Welcome, Kołobrzeg!",
    url: "https://skeynetwork.medium.com/the-first-city-operating-on-the-skey-network-welcome-ko%C5%82obrzeg-b2f3b98caa9c",
    imageUrl: "articles/city-kołobrzeg.jpeg"
  },
  {
    date: "2 March 2022",
    title: "Skey Network Ecosystem - Technology update",
    url: "https://skeynetwork.medium.com/skey-network-ecosystem-technology-update-d2cbfd5fde1e",
    imageUrl: "articles/ecosystem-technology"
  },
  {
    date: "17 January 2022",
    title: "How will the Go2NFT Project affect the economic and technical value of Skey.Network?",
    url: "https://skeynetwork.medium.com/how-will-the-go2nft-project-affect-the-economic-and-technical-value-of-skey-network-9dc8cadc3c25",
    imageUrl: "articles/project-affect.jpeg"
  },
  {
    date: "3 November 2021",
    title: "Mainnet Launch",
    url: "https://skeynetwork.medium.com/mainnet-launch-ae6231197175",
    imageUrl: "articles/mainnet-launch"
  },
  {
    date: "2 November 2021",
    title: "Skey Network Dubai Conferences Summary",
    url: "https://skeynetwork.medium.com/skey-network-dubai-conferences-summary-8143a6499226",
    imageUrl: "articles/dubai-conferences"
  },
  {
    date: "25 October 2021",
    title: "Szymon Fiedorowicz: Algorithms will one day replace CEOs",
    url: "https://www.forbes.pl/kariera/szymon-fiedorowicz-o-przywodztwie-w-biznesie-brandme-ceo-2021/flzpztm",
    imageUrl: "articles/Szymon-Fiedorowicz.jpeg"
  },
  {
    date: "21 October 2021",
    title: "Skey Network (SmartKey) Shows There Is Real Value and Utility in NFT Tokens",
    url: "https://thefintechtimes.com/gitex-global-dubai-2021-round-up-the-uae-leads-financial-services-transformation/",
    imageUrl: "articles/fintech-time.jpeg"
  },
  {
    date: "19 March 2021",
    title: "Skey Network (SmartKey) Shows There Is Real Value and Utility in NFT Tokens",
    url: "https://news.bitcoin.com/smartkey-shows-there-is-real-value-and-utility-in-nft-tokens/",
    imageUrl: "articles/nft-smartKey.jpeg"
  },
  {
    date: "30 September 2021",
    title: "SmartKey — Evolution from platform solutions service to the Own Network",
    url: "https://skeynetwork.medium.com/smartkey-evolution-from-platform-solutions-service-to-the-own-network-396d5193a758",
    imageUrl: "articles/smartkey-evolution.jpeg"
  },
  {
    date: "2 June 2021",
    title: "Skey Network X Orange: the world’s first blockchain-of-things SIM for smart cities",
    url: " https://skeynetwork.medium.com/smartkey-x-orange-the-worlds-first-blockchain-of-things-sim-for-smart-cities-9e7edad42d28",
    imageUrl: "articles/first-blockchain.jpeg"
  },
  {
    date: "02 June 2021 ",
    title: "Smart NFT Project joins major telecom to power future of smart cities",
    url: "https://cointelegraph.com/news/smart-nft-project-joins-major-telecom-to-power-future-of-smart-cities",
    imageUrl: "articles/smart-nft.jpeg"
  },
  {
    date: "25 March 2021",
    title: "Skey Network is bringing NFTs to the Internet of Things",
    url: " https://skeynetwork.medium.com/smartkey-is-bringing-nfts-to-the-internet-of-things-a6964ad426c6",
    imageUrl: "articles/bringing"
  }
];
