import "./CheckBox.scss";

interface ICheckbox {
  checked: boolean;
  onChange: () => void;
  className?: string;
}

const CheckBox = ({ checked, onChange, className }: ICheckbox) => {
  return (
    <button
      className={`checkbox${className ? ` ${className}` : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange();
      }}
    >
      {checked ? (
        <img
          src="/images/checkbox-checked.svg"
          alt="checkbox checked"
          width={24}
          height={24}
        />
      ) : (
        <img
          src="/images/checkbox-unchecked.svg"
          alt="checkbox unchecked"
          width={24}
          height={24}
        />
      )}
    </button>
  );
};

export default CheckBox;
