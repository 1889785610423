import { Col, Container, Row } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";
import { solutionsPath } from "../../../data/menuLinks";

import "./ProductsBlockchainHero.scss";

const ProductsBlockchainHero = () => {
  return (
    <div id="products-blockchain-hero">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row className="row-hero">
            <Col xs={12} md={6} className="content-col">
              <h1 className="header">Blockchain</h1>
              <Space space="space-lg" className="desktop-xl" />
              <Space space="space-md" className="mobile-xl" />
              <p className="s1 subheader">
                Skey Network blockchain enables the creation of digital services, products, and applications. It is a
                flexible, fully independent database. Skey Network Blockchain is a data bus for emerging digital
                services and products. We provide full support, products, and tools.
              </p>

              <Space space="space-xxl" />
              <Link variant="primary" className="buttonHero" url={solutionsPath} target="_self">
                Show all products
              </Link>
            </Col>
          </Row>
        </Container>
        <img
          width={770}
          height={556}
          src="/images/product-blockchain-bg-desktop.jpg"
          alt="logo"
          className="overview-bg-blocks desktop-xl"
        />
        <img
          width={375}
          height={340}
          src="/images/product-blockchain-bg-mobile.jpg"
          alt="logo"
          className="overview-bg-blocks mobile-xl"
        />
      </Container>
    </div>
  );
};

export default ProductsBlockchainHero;
