import { useState } from "react";

export type FormStages = "form" | "successSend" | "failSend";

interface UseFormStageValue {
  formStage: FormStages;
  setSendSuccess: () => void;
  setSendFail: () => void;
  resetForm: () => void;
}

const useFormStage = (): UseFormStageValue => {
  const [formStage, setFormStage] = useState<FormStages>("form");

  const setSendSuccess = () => setFormStage("successSend");
  const setSendFail = () => setFormStage("failSend");
  const resetForm = () => setFormStage("form");

  return { formStage, setSendSuccess, setSendFail, resetForm };
};

export default useFormStage;
