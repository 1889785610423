import { Col, Container, Row } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import { useIsMobile } from "../../../hooks/useIsMobile";

import ButtonV2 from "../../components/atoms/Button/ButtonV2";
import { solutionsPath } from "../../../data/menuLinks";

import "./OverviewHero.scss";
import Group from "../../../components/arrangement/Group/Group";

const OverviewHero = () => {
  const isMobile = useIsMobile("xl");
  return (
    <div id="overview-hero">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Row className={"row-hero"}>
            <Col xs={12} md={6} className={"content-col"}>
              <h1 className={"ecosystemHero"}>Blockchain that works</h1>
              <Space space={"space-lg"} className={"desktop-xl"} />
              <Space space={"space-md"} className={"mobile-xl"} />
              <p className="s1 subheader hero-adaptable">
                Skey Network delivers real-world utility and value-driven solutions in its blockchain ecosystem, with
                strong partnerships.
              </p>
              <Space space={"space-xxl"} />
              <a href={solutionsPath} className="w-xs-100 w-md-mc d-xs-b">
                <ButtonV2 size={"md"} hierarchy={"primary"}>
                  <Group fullWidth justifyContent="center">
                    <p className="b1">Solutions</p>
                  </Group>
                </ButtonV2>
              </a>
            </Col>
          </Row>
        </Container>
        {isMobile && (
          <img
            src={"/images/overview/hero-desktop.png"}
            width="566"
            height="613"
            alt={"logo"}
            className={"overview-bg-blocks"}
          />
        )}
        {!isMobile && (
          <img
            src={"/images/overview/hero-desktop.png"}
            width="868"
            height="838"
            alt={"logo"}
            className={"overview-bg-blocks"}
          />
        )}
      </Container>
    </div>
  );
};

export default OverviewHero;
