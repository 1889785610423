import { useDefaultProduct } from "../../../../contexts/DefaultProductContext";
import { useFormsControll } from "../../../../contexts/FormsControllContext";
import ContactSection from "../ContactSection";
import "./ContactProductDefault.scss";

const ContactProductDefault = () => {
  const { openProductForm } = useFormsControll();
  const { resetDefaultProduct } = useDefaultProduct();

  const contactAction = () => {
    openProductForm();
    resetDefaultProduct();
  };

  return (
    <ContactSection
      header={"Create your product"}
      subheader={"Contact us and transfer your business to the blockchain!"}
      buttonLabel={"Contact us"}
      action={contactAction}
    />
  );
};

export default ContactProductDefault;
