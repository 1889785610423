import { useAgreements } from "../../../../contexts/FormAgreementsContext";

import Stack from "../../../../components/arrangement/Stack/Stack";
import Group from "../../../../components/arrangement/Group/Group";
import CheckBox from "../../atoms/Checkbox/CheckBox";
import Link from "../../atoms/Link/Link";

import "./AgreementsSection.scss";

const AgreementsSection = () => {
  const { agreements, allAgreementsChecked, toggleCheckAgreement, toggleCheckAllAgreements } = useAgreements();

  return (
    <div className="agreements-section">
      <Stack className="agreements-section-first-col">
        <Group colGap={16} alignItems="flex-start">
          <CheckBox checked={allAgreementsChecked} onChange={toggleCheckAllAgreements} />
          <p>Select all</p>
        </Group>
      </Stack>

      <Stack className="agreements-section-second-col">
        {agreements.map(({ checked, label, link, required }, index) => {
          return (
            <Group key={index} colGap={16} alignItems="flex-start">
              <CheckBox checked={checked} onChange={() => toggleCheckAgreement(index)} />
              <p>
                {label}{" "}
                {link && (
                  <Link url={link.url} variant="trietary" mode="dark-mode" size="size-extra-small">
                    {link.label}
                  </Link>
                )}
                {required && <span className="label-required">*</span>}
              </p>
            </Group>
          );
        })}
      </Stack>
    </div>
  );
};

export default AgreementsSection;
