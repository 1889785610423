import { Container } from "react-bootstrap";
import { faqQuestions } from "../../../data/faqQuestions";
import Accordion from "../../components/organisms/Accordion/Accordion";
import "./FaqAccordion.scss";

const FaqAccordion = () => {
  return (
    <div id="faq-accordion">
      <Container fluid="xl">
        <Accordion accordionItems={faqQuestions} />
      </Container>
    </div>
  );
};

export default FaqAccordion;
