import { createContext, useContext, useState } from "react";

import { AccordionTabProps, AccordionActiveState, AccordionTabContextValue } from "../AccordionV2.types";
import "./AccordionTab.scss";

const AccordionTabContext = createContext(null as any);

const AccordionTab = ({ children, inititalVariant = "default" }: AccordionTabProps) => {
  const [variant, setVariant] = useState<AccordionActiveState>(inititalVariant);

  const turnDefault = () => setVariant("default");
  const turnHover = () => setVariant("hover");
  const turnActive = () => setVariant("active");

  const handleHover = () => {
    if (variant === "default") {
      return turnHover();
    }
    return;
  };

  const handleClick = () => {
    if (variant === "active") {
      return turnHover();
    }
    return turnActive();
  };

  const handleBlur = () => {
    if (variant === "hover") {
      return turnDefault();
    }
    return;
  };

  const contextValue: AccordionTabContextValue = {
    variant,
    handleHover,
    handleClick,
    handleBlur
  };

  const dividerClasses = `accordion-tab-divider accordion-tab-divider-${variant}`;

  return (
    <AccordionTabContext.Provider value={contextValue}>
      <div className="accordion-tab">
        {children}
        <hr className={dividerClasses} />
      </div>
    </AccordionTabContext.Provider>
  );
};

export const useAccordionTab = (): AccordionTabContextValue => useContext(AccordionTabContext);

export default AccordionTab;
