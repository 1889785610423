import { Col, Container, Row } from "react-bootstrap";
import { accessControlCards } from "../../../data/accessControlCards";
import Image from "../../components/atoms/Image/Image";
import Space from "../../components/atoms/Spacing/Space";
import "./BlockchainAccessControl.scss";

const BlockchainAccessControl = () => {
  return (
    <div id="blockchain-access-control">
      <Container fluid="xxl">
        <Container fluid="xl" className="as-center-col">
          <h2 className="header-blockchain">What will you gain from it?</h2>
          <Space space="space-lg" />
          <Row className="row-access-control">
            {accessControlCards.map((card) => (
              <Col xs={12} md={6} lg={4} key={Math.random()}>
                <div className="access">
                  <Image imgWidth={16} imgHeight={15} imgPath={card.img} alt="card" className="access-control-img" />
                  <h2 className="access-header">{card.header}</h2>
                  <Space space="space-sm" />
                  <p className="s2 subheader-access">{card.subheader}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default BlockchainAccessControl;
