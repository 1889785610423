/* eslint-disable react-hooks/exhaustive-deps */
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect } from "react";

import { useFormsControll } from "../../../../../contexts/FormsControllContext";
import ButtonLegacy from "../../../atoms/Button/ButtonLegacy";
import CustomInput from "../../../atoms/CustomInput/CustomInput";
import ContactForm from "../ContactForm/ContactForm";
import WithDropdown from "../../WithDropdown/WithDropdown";
import { useDefaultProduct } from "../../../../../contexts/DefaultProductContext";
import useFetch from "../../../../../hooks/useFetch";
import { EmailService } from "../../../../../services/EmailService";
import Space from "../../../atoms/Spacing/Space";
import { skeyNetworkProductsNames } from "../../../../../data/productsOfSkeyNetwork";
import { CAPTCHA_KEY } from "../../../../../constants";
import SuccessFormContent from "../SuccessFormContent/SuccessFormContent";
import FailFormContent from "../FailFormContent/FailFormContent";
import useFormName from "../../../../../hooks/useFormName";
import useFormProduct from "../../../../../hooks/useFormProduct";
import useFormEmail from "../../../../../hooks/useFormEmail";
import useCaptcha from "../../../../../hooks/useCaptcha";
import useFormMessage from "../../../../../hooks/useFormMessage";

import "./ProductForm.scss";

const ProductForm = () => {
  const { closeProductForm, formStage, setSendSuccess, setSendFail, resetForm } = useFormsControll();
  const { defaultProduct } = useDefaultProduct();

  const { name, NAME_FIELD, focusName, blurName, handleName } = useFormName();
  const { product, focusProducts, blurProducts, handleProduct } = useFormProduct(defaultProduct);
  const { email, EMAIL_FIELD, focusEmail, blurEmail, handleEmail } = useFormEmail();
  const { message, MESSAGE_FIELD, focusMessage, blurMessage, handleMessage } = useFormMessage();

  const { captchaToken, handleCaptcha } = useCaptcha();

  const { doFetch, isLoading, data, error } = useFetch(async () =>
    EmailService.productEmail({
      name: name.text,
      email: email.text,
      product: product.name,
      message: message.text,
      captchaToken: captchaToken || ""
    })
  );

  const sendButtonDisabled =
    !Boolean(captchaToken) || isLoading || Boolean(email.error) || Boolean(name.error) || Boolean(product.error);

  const resetFormWithTimeout = () => {
    setSendFail();
    setTimeout(() => {
      resetForm();
    }, 3000);
  };

  //handle incoming data
  useEffect(() => {
    if (!data) return;
    const status = data.ok;

    if (status) {
      setSendSuccess();
      return;
    }
    if (!status) {
      resetFormWithTimeout();
      return;
    }
  }, [data]);

  //handle timeout
  useEffect(() => {
    if (!error) return;
    resetFormWithTimeout();
  }, [error]);

  const formContent = (
    <>
      <p className="s1">Please fill out the form below with your contact information.</p>
      <Space space="space-md" />
      <CustomInput
        required={true}
        text={name.text}
        error={name.error}
        label={NAME_FIELD.label}
        type={NAME_FIELD.type}
        placeholder={NAME_FIELD.placeholder}
        focus={name.focus}
        onChange={handleName}
        onFocus={focusName}
        onBlur={blurName}
      />
      <Space space={"space-xs"} />
      <WithDropdown options={skeyNetworkProductsNames} onSelect={handleProduct} appear={product.focus}>
        <CustomInput
          required={true}
          text={product.name}
          error={product.error}
          label={"Product"}
          type={"text"}
          placeholder={"Select product"}
          focus={product.focus}
          dropdown={true}
          onChange={() => {}}
          onFocus={focusProducts}
          onBlur={blurProducts}
        />
      </WithDropdown>
      <Space space={"space-xs"} />
      <CustomInput
        required={true}
        text={email.text}
        error={email.error}
        label={EMAIL_FIELD.label}
        type={EMAIL_FIELD.type}
        placeholder={EMAIL_FIELD.placeholder}
        focus={email.focus}
        autoComplete={true}
        onChange={handleEmail}
        onFocus={focusEmail}
        onBlur={blurEmail}
      />
      <CustomInput
        required={false}
        text={message.text}
        label={MESSAGE_FIELD.label}
        type={MESSAGE_FIELD.type}
        placeholder={MESSAGE_FIELD.placeholder}
        focus={message.focus}
        autoComplete={true}
        onChange={handleMessage}
        onFocus={focusMessage}
        onBlur={blurMessage}
      />
      <Space space="space-xl" />
      <div style={{ height: "75px" }}>
        {formStage === "form" && <ReCAPTCHA sitekey={CAPTCHA_KEY()} onChange={(token) => handleCaptcha(token)} />}
        {formStage === "failSend" && <FailFormContent />}
      </div>
      <Space space="space-xl" />
      <div className="cta-btns">
        <ButtonLegacy
          onClick={sendButtonDisabled ? () => {} : doFetch}
          disabled={sendButtonDisabled}
          variant="primary"
          className="same-width"
        >
          {isLoading ? "Sending..." : "Send message"}
        </ButtonLegacy>
        <ButtonLegacy onClick={closeProductForm} variant="secondary" elevate="flat">
          Cancel
        </ButtonLegacy>
      </div>
    </>
  );

  const successContent = <SuccessFormContent />;

  return (
    <ContactForm>
      {formStage !== "successSend" && <>{formContent}</>}
      {formStage === "successSend" && <>{successContent}</>}
    </ContactForm>
  );
};

export default ProductForm;
