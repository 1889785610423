import ContactProductDefault from "../../sections/ContactSection/ContactProductDefault/ContactProductDefault";
import MoreInfo from "./sections/MoreInfo/MoreInfo";
import ContactUs from "./sections/ContactUs/ContactUs";
import HowItWorks from "./sections/HowItWorks/HowItWorks";
import Benefits from "./sections/Benefits/Benefits";
import RWAPotential from "./sections/RWAPotential/RWAPotential";
import Hero from "./sections/Hero/Hero";

import "./RWA.scss";

const RWA = () => {
  return (
    <section id="rwa">
      <Hero />
      <RWAPotential />
      <Benefits />
      <HowItWorks />
      <ContactUs />
      <MoreInfo />
      <ContactProductDefault />
    </section>
  );
};

export default RWA;
