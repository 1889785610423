import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

import { offerModules, offerStarter, pricingOffer } from "../../../data/productsPricingOfferDesktop";
import Space from "../../components/atoms/Spacing/Space";
import ButtonLegacy from "../../components/atoms/Button/ButtonLegacy";

import { useFormsControll } from "../../../contexts/FormsControllContext";
import { useDefaultProduct } from "../../../contexts/DefaultProductContext";

import "./PricingOfferDesktop.scss";

const PricingOfferDesktop = () => {
  const { openModulesForm } = useFormsControll();
  const { handleDefaultProduct } = useDefaultProduct();

  const orderAction = () => {
    openModulesForm();
    handleDefaultProduct("SkeyBOX");
  };

  return (
    <div className="product-pricing">
      {pricingOffer.map((price, index) => (
        <Fragment key={index}>
          <Col lg={12}>
            <h4>{price.title}</h4>
          </Col>
          <Space space="space-md" />
          <div className="pricing-header">
            {price.rows?.map((offer, key) => (
              <Col lg={offer.serviceName === "type of service" ? 4 : 3} key={key}>
                <div className={`pricing-header-col ${offer.className || ""}`}>
                  <p className="s2">{offer.serviceName}</p>
                </div>
              </Col>
            ))}
          </div>
        </Fragment>
      ))}
      <Space space="space-lg" />
      <hr />
      <Space space="space-xl" />
      {offerStarter.map((price, index) => (
        <Fragment key={index}>
          <Row>
            <Col lg={3}>
              <p className="s2 subHeader-starter">{price.subHeader}</p>
            </Col>
            {price.rows?.map((offer, key) => (
              <Fragment key={key}>
                <Col lg={4}>
                  <p className="s2">{offer.serviceType}</p>
                </Col>
                <Col lg={2}>
                  <p className="s2">{offer.typeFee}</p>
                </Col>
                <Col lg={{ span: 2, offset: 1 }}>
                  <p className="s2 price">{offer.price}</p>
                </Col>
              </Fragment>
            ))}
          </Row>
        </Fragment>
      ))}
      {offerModules.map((modules, index) => (
        <Fragment key={index}>
          <Space space="space-lg" />
          <Col lg={12} className="modules-title">
            <p className="s2 title">{modules.title}</p>
          </Col>
          <Space space="space-xl" />
          <div className="starter-box">
            {modules.rows.map((offer, key) => (
              <Row key={key}>
                <Col lg={3}>
                  <p className="s2 subHeader-starter">{offer.subHeader}</p>
                </Col>
                <Col lg={4}>
                  <p className="s2">{offer.serviceType}</p>
                </Col>
                <Col lg={{ span: 2 }}>
                  <p className="s2">{offer.typeFee}</p>
                </Col>
                <Col lg={{ span: 2, offset: 1 }}>
                  <p className="s2 price">{offer.price}</p>
                </Col>
              </Row>
            ))}
          </div>
        </Fragment>
      ))}
      <Space space="space-xxl" />
      <ButtonLegacy variant="primary" size="size-normal" onClick={orderAction} className="wide">
        Order now
      </ButtonLegacy>
    </div>
  );
};

export default PricingOfferDesktop;
