import { IProductSkeyCard } from "../shared/interfaces/IProductSkeyCard";
import { blockchainPath, dppPath, rwaPath, skeyAccessControlPath, skeyBoxPath, skeyTixPath } from "./menuLinks";

const blockchain: IProductSkeyCard = {
  title: "Blockchain",
  text: "Skey Network Blockchain is a data bus for emerging digital services and products. We provide full support, products, and tools.",
  imgPath: "/images/solutions/blockchain.jpg",
  // mobileImgPath: "/images/products-skey-card1-mobile.jpeg",
  url: blockchainPath,
  linkLabel: "see more",
  linkImgPath: "/images/go-to.svg",
  disabled: false
};

const skeyAccessControl = {
  title: "Skey Access Control",
  text: "Skey Network provides a technology that combines Oracle, DeFi, NFT, and IoT devices with blockchain, creating Blockchain of Things",
  imgPath: "/images/solutions/access-control.jpg",
  // mobileImgPath: "/images/products-skey-card2-mobile.jpeg",
  url: skeyAccessControlPath,
  linkLabel: "see more",
  linkImgPath: "/images/go-to.svg",
  disabled: false
};

const skeyBox = {
  title: "SkeyBOX",
  text: "SkeyBOX - a stored blockchain copy. It is your own gateway to the blockchain ecosystem",
  imgPath: "/images/solutions/box.jpg",
  // mobileImgPath: "/images/products-skey-card3-mobile.jpeg",
  url: skeyBoxPath,
  linkLabel: "see more",
  linkImgPath: "/images/go-to.svg",
  disabled: false
};

const skeyTix = {
  title: "SkeyTIX and SkeyPoAP",
  text: "The tool integrated with your system - it transfers products to the blockchain using NFT and allows you to create a unique event. Thanks to SkeyPoAP you can create digital mementos.",
  imgPath: "/images/solutions/tix.jpg",
  // mobileImgPath: "/images/products-skey-card4-mobile.jpeg",
  url: skeyTixPath,
  linkLabel: "see more",
  linkImgPath: "/images/go-to.svg",
  disabled: false
};

const skeyAI = {
  title: "Skey AI",
  text: "A revolutionized set of AI tools, optimizing the process of creating carbon certificates and other essential documents. Compliant with European Commission standards, our solutions actively contribute to sustainable development and environmental protection.",
  imgPath: "/images/solutions/ai.jpg",
  mobileImgPath: "/images/products-skey-card5-mobile.png",
  // url: SKEY_TIX_PATH,
  linkLabel: "more info soon",
  linkImgPath: "/images/go-to.svg",
  disabled: true
};

const skeyPayment = {
  title: "Skey Payment Solution",
  text: "A system designed for banks, financial institutions, payment card operators, and other verified licensed users issuing Prepaid cards.",
  imgPath: "/images/solutions/payment-solution.jpg",
  mobileImgPath: "/images/products-skey-card6-mobile.png",
  // url: SKEY_TIX_PATH,
  linkLabel: "more info soon",
  linkImgPath: "/images/go-to.svg",
  disabled: true
};

const skeyRwa = {
  title: "Skey Real World Assets (RWA)",
  text: "It's a tool designed to simplify the tokenization of a variety of assets, such as parking spaces, advertising media, or farmland. It offers a comprehensive infrastructure, making the tokenization process simple and accessible to everyone.",
  imgPath: "/images/solutions/rwa.jpg",
  mobileImgPath: "/images/card-rwa-mobile.jpg",
  // url: SKEY_TIX_PATH,
  url: rwaPath,
  linkLabel: "see more",
  linkImgPath: "/images/go-to.svg",
  disabled: false
};

const skeyDpp: IProductSkeyCard = {
  title: "Skey Digital Passport (DPP)",
  text: "It is a solution for creating the digital product passport required by the European Union. It will allow you to sell on the EU market and open up new opportunities to grow your business.",
  imgPath: "/images/solutions/dpp.jpg",
  url: dppPath,
  mobileImgPath: "",
  linkLabel: "see more",
  linkImgPath: "/images/go-to.svg",
  disabled: false
};

export const solutions: IProductSkeyCard[] = [
  skeyDpp,
  skeyRwa,
  skeyBox,
  skeyTix,
  skeyAccessControl,
  blockchain,
  skeyAI,
  skeyPayment
];

export const skeyNetworkProductsNames = solutions.map((product) => product.title);
