import Group from "../../../components/arrangement/Group/Group";
import Stack from "../../../components/arrangement/Stack/Stack";
import { IListItem } from "./IListItem";

import "./ListItem.scss";

const ListItem = ({ description, imgUrl, title }: IListItem) => {
  return (
    <Group className="list-item" fullWidth colGap={10} alignItems="flex-start">
      <img src={imgUrl} alt={title} />
      <Stack rowGap={4} fullWidth>
        <p className="s2 title">{title}</p>
        <p className="s1 description">{description}</p>
      </Stack>
    </Group>
  );
};

export default ListItem;
