export const documentationCards = [
  {
    imgPath: "/images/doc-card1-img.svg",
    header: "Economical products & tools",
    subheader: "Join the blockchain ecosystem using ready-made products and tools"
  },
  {
    imgPath: "/images/doc-card2-img.svg",
    header: "Gateway to Decentralized Finance",
    subheader: "Our goal is to combine decentralized finance projects with physical assets."
  },
  {
    imgPath: "/images/doc-card3-img.svg",
    header: "Easy blockchain innovation",
    subheader:
      "Connect your business, product, data, or devices to the Skey Network blockchain and get interoperability and security from day one."
  }
];
