import Overview from "../ui/pages/Overview/Overview";
import Solutions from "../ui/pages/Solutions/Solutions";
import ProductsBlockchain from "../ui/pages/ProductsBlockchain/ProductsBlockchain";
import ProductsSkeyAccessControl from "../ui/pages/ProductsSkeyAccessControl/ProductsSkeyAccessControl";
import News from "../ui/pages/News/News";
import Development from "../ui/pages/Development/Development";
import FAQ from "../ui/pages/FAQ/FAQ";
import NotFound from "../ui/pages/NotFound/NotFound";
import WithNavbarAndFooter from "../ui/layouts/WithNavbarAndFooter";
import Cooperation from "../ui/pages/Cooperation/Cooperation";
import NoLayout from "../ui/layouts/NoLayout";
import ProductsSkeyTix from "../ui/pages/ProductsSkeyTix/ProductsSkeyTix";
import ProductsSkeyBox from "../ui/pages/ProductsSkeyBox/ProductsSkeyBox";
import Info from "../ui/pages/Info/Info";

import { IRoute } from "../shared/interfaces/IRoute";
import WithInfoNavbarAndFooter from "../ui/layouts/WithInfoNavbarAndFooter";
import {
  blockchainPath,
  dppPath,
  menuLinks,
  rwaPath,
  skeyAccessControlPath,
  skeyBoxPath,
  skeyTixPath
} from "../data/menuLinks";
import Passport from "../ui/pages/Passport/Passport";
import RWA from "../ui/pages/RWA/RWA";

const MAIN_PATH_OF_ARRAY = 0;

export const routes: IRoute[] = [
  {
    Component: Overview,
    path: menuLinks.overview.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: Solutions,
    path: menuLinks.solutions.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: ProductsBlockchain,
    path: blockchainPath,
    Layout: WithNavbarAndFooter
  },
  {
    Component: ProductsSkeyAccessControl,
    path: skeyAccessControlPath,
    Layout: WithNavbarAndFooter
  },
  {
    Component: ProductsSkeyTix,
    path: skeyTixPath,
    Layout: WithNavbarAndFooter
  },
  {
    Component: ProductsSkeyBox,
    path: skeyBoxPath,
    Layout: WithNavbarAndFooter
  },
  {
    Component: News,
    path: menuLinks.news.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: Cooperation,
    path: menuLinks.cooperation.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: Development,
    path: menuLinks.development.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: Passport,
    path: dppPath,
    Layout: WithNavbarAndFooter
  },
  {
    Component: RWA,
    path: rwaPath,
    Layout: WithNavbarAndFooter
  },
  {
    Component: Info,
    path: menuLinks.info.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithInfoNavbarAndFooter
  },
  {
    Component: FAQ,
    path: menuLinks.faq.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: NotFound,
    path: menuLinks.notFound.paths[MAIN_PATH_OF_ARRAY],
    Layout: NoLayout
  }
];

export default routes;
