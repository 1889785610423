import { ReactNode, createContext, useState, useContext } from "react";
import useFormStage, { FormStages } from "../hooks/useFormStage";

interface IContext {
  children: ReactNode;
}

interface FormControllsValue {
  productFormOpen: boolean;
  openProductForm: () => void;
  closeProductForm: () => void;
  modulesFormOpen: boolean;
  openModulesForm: () => void;
  closeModulesForm: () => void;
  partnershipFormOpen: boolean;
  openPartnershipForm: () => void;
  closePartnershipForm: () => void;
  closeAll: () => void;
  formStage: FormStages;
  setSendSuccess: () => void;
  setSendFail: () => void;
  resetForm: () => void;
}

const FormsControllContext = createContext(null as any);

export const FormsControllProvider = ({ children }: IContext) => {
  const [productFormOpen, setProductFormOpen] = useState(false);
  const [partnershipFormOpen, setPartnershipFormOpen] = useState(false);
  const [modulesFormOpen, setModulesFormOpen] = useState(false);
  const { formStage, setSendSuccess, setSendFail, resetForm } = useFormStage();

  const openProductForm = () => setProductFormOpen(true);
  const closeProductForm = () => setProductFormOpen(false);
  const openModulesForm = () => setModulesFormOpen(true);
  const closeModulesForm = () => setModulesFormOpen(false);

  const openPartnershipForm = () => setPartnershipFormOpen(true);
  const closePartnershipForm = () => setPartnershipFormOpen(false);

  const closeAll = () => {
    closeProductForm();
    closePartnershipForm();
    closeModulesForm();
    resetForm();
  };

  return (
    <FormsControllContext.Provider
      value={{
        productFormOpen,
        openProductForm,
        closeProductForm,
        modulesFormOpen,
        openModulesForm,
        closeModulesForm,
        partnershipFormOpen,
        openPartnershipForm,
        closePartnershipForm,
        closeAll,
        formStage,
        setSendSuccess,
        setSendFail,
        resetForm
      }}
    >
      {children}
    </FormsControllContext.Provider>
  );
};

export const useFormsControll = (): FormControllsValue => useContext(FormsControllContext);
