import Group from "../../../../../components/arrangement/Group/Group";
import Input from "../Input/Input";
import Chips from "../Chips/Chips";
import { CRYPTO_CODE_RENDER, DEFAULT_INPUT_AMOUNT, FiatCodes } from "../../constants/constants";

interface Props {
  calculatedCrypto: number | null;
  fiatCode: FiatCodes;
  oneFiatUnitPrice: number | null;
}

const CryptoInputLine = ({ calculatedCrypto, fiatCode, oneFiatUnitPrice }: Props) => {
  return (
    <div>
      <p className="mb-xs-1 op-05 p1 capitalize t-xs-c">you will get:</p>
      <Group colGap={8} justifyContent="center" alignItems="center" className="input-line" fullWidth>
        <Input
          value={!calculatedCrypto ? "" : (calculatedCrypto || 0).toFixed(4)}
          disabled
          placeholder={DEFAULT_INPUT_AMOUNT}
          type="number"
          style={{ fontSize: "28px" }}
          className="buy-input"
        />
        <Group justifyContent="flex-start" className="chips-wrapper">
          <Chips style={{ padding: "4px", paddingRight: "16px" }} className="none-events">
            <Group justifyContent="center" alignItems="center" colGap={8}>
              <img src="/images/crypto-currencies/skey.svg" alt="currency-symbol" />
              <p className="s2">{CRYPTO_CODE_RENDER}</p>
            </Group>
          </Chips>
        </Group>
      </Group>
      <p className="op-05 t-xs-c">
        Exchange rate:{" "}
        {`1 ${fiatCode} = ${oneFiatUnitPrice ? oneFiatUnitPrice.toFixed(4) : "---"} ${CRYPTO_CODE_RENDER}`}
      </p>
    </div>
  );
};

export default CryptoInputLine;
