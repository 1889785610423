import { ButtonHTMLAttributes, useState } from "react";
import HoverIcon from "../HoverIcon/HoverIcon";

import "./ButtonLegacy.scss";

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "trietary";
  elevate?: "flat" | "";
  size?: "extra-small" | "size-small" | "size-normal";
  icon?: string;
  width?: number;
  height?: number;
  iconHovers?: boolean;
  disabled?: boolean;
}

const ButtonLegacy = ({
  children,
  onClick = () => {},
  onBlur = () => {},
  variant = "primary",
  size = "size-normal",
  elevate = "",
  icon,
  width,
  height,
  iconHovers = true,
  disabled = false,
  className = ""
}: IButtonProps) => {
  const [hoverState, setHoverState] = useState<"default" | "hover">("default");

  const isHovered = hoverState === "hover";

  const hasIcon = !!icon && !!height && !!width;

  const setDefault = () => setHoverState("default");
  const setHover = () => setHoverState("hover");

  const builtClass = `skey-button ${variant} ${size} ${className} ${elevate}`;

  return (
    <button
      className={builtClass}
      onMouseOver={setHover}
      onMouseLeave={setDefault}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      onBlur={onBlur}
      disabled={disabled}
    >
      {children}
      {hasIcon && width && height && (
        <HoverIcon iconPath={icon} imgWidth={width} imgHeight={height} isHover={isHovered} doHover={iconHovers} />
      )}
    </button>
  );
};

export default ButtonLegacy;
