import { Container } from "react-bootstrap";
import ContentWithVideo from "../../../../sections/ContentWithVideo/ContentWithVideo";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import Group from "../../../../../components/arrangement/Group/Group";
import { blockchainPath, dppPath, rwaPath, skeyBoxPath } from "../../../../../data/menuLinks";

import "./Solutions.scss";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const items = [
  {
    label: "RWA",
    url: rwaPath
  },
  {
    label: "Digital Product Passport",
    url: dppPath
  },
  {
    label: "NFT",
    url: skeyBoxPath
  },
  {
    label: "Internet of Things",
    url: blockchainPath
  }
];

const Content = () => {
  const isMobile = useIsMobile("lg");
  return (
    <Stack rowGap={32} className="pl-xs-0 pl-md-2" fullWidth style={{ maxWidth: isMobile ? "unset" : "290px" }}>
      <p className="s1 dark t-xs-c t-lg-l" style={{ fontSize: "18px" }}>
        Skey Network brings real value to the blockchain world. Check out our successful solutions!
      </p>
      <Stack fullWidth rowGap={5}>
        {items.map((it, id) => (
          <a href={it.url} target="_self" className="pt-xs-1 pb-xs-1 w-xs-100 w-lg-mc" key={id}>
            <Group fullSize colGap={8} justifyContent="center" alignItems="center">
              <p className="primary s2">{it.label}</p>
              <img src="/images/go-to-30.svg" alt={it.label} width={"24px"} height={"24px"} />
            </Group>
          </a>
        ))}
      </Stack>
    </Stack>
  );
};

const Video = () => {
  return (
    <video playsInline className="video" controls>
      <source src="/videos/overview/RWA-and-DPP.mp4" type="video/mp4" />
    </video>
  );
};

const Solutions = () => {
  return (
    <div className="solutions">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <ContentWithVideo content={<Content />} ownVideoTag={<Video />} />
        </Container>
      </Container>
    </div>
  );
};

export default Solutions;
