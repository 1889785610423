export const productsSkeyBoxModules = [
  {
    img: "/images/api-modules.svg",
    header: "Data API",
    title:
      "a module that allows the company to manage data (read/write) in the network along with an easy-to-implement API. It ensures the transparency and integrity of your data using the currently most secure database, which is the blockchain."
  },
  {
    img: "/images/security-modules.svg",
    header: "Files Security",
    title:
      "(durable medium) a module that allows the company to secure and make publicly available vital documents in digital form - contracts, regulations, and others by securing them with a key on the blockchain. This ensures the irrefutability, non-repudiation of the files."
  },
  {
    img: "/images/certificates-modules.svg",
    header: "Certificates",
    title:
      "a module that allows the company to base the certification system related to its products or services on the NFT token, which ensures indisputability, authenticity, and uniqueness."
  }
];
