import { useEffect } from "react";

interface Props {
  condition: boolean;
  callback: Function;
  timeoutMs: number;
}

const useTimeout = ({ callback, condition, timeoutMs }: Props) => {
  useEffect(() => {
    if (!condition) return;

    setTimeout(callback, timeoutMs);
  }, [condition, timeoutMs, callback]);
};

export default useTimeout;
