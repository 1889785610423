import { Container } from "react-bootstrap";
import { skeyPartnershipsImages } from "../../../data/skeyPartnershipsImages";
import Space from "../../components/atoms/Spacing/Space";
import CustomSlider from "../../components/molecules/PartnershipsSlider/PartnershipsSlider";

import "./OverviewPartnerships.scss";

const OverviewPartnerships = () => {
  return (
    <div id="overview-partnerships">
      <Container fluid="xxl">
        <Container fluid="xl" className="as-col">
          <p className="s1 title">Skey Network Partnerships</p>
          <Space space="space-lg" className="desktop-lg" />
          <Space space="space-lg" className="mobile-lg" />
          <div className="partnerships top">
            <div className="wrapper">
              <CustomSlider images={skeyPartnershipsImages} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default OverviewPartnerships;
