import { Link } from "react-router-dom";
import { IRouterLink } from "./RouterLink.interface";
import "./RouterLink.scss";

const RouterLink = ({ label, link, isActive, className }: IRouterLink) => {
  return (
    <Link className={`router-link ${isActive ? "active" : ""} ${className ? className : ""}`} to={link}>
      {label}
    </Link>
  );
};

export default RouterLink;
