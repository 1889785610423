import { useState } from "react";

import { ComponentActiveState } from "../../../../shared/types/IComponentActiveState";
import AccordionTabContent from "../../atoms/AccordionTabContent/AccordionTabContent";
import InteractiveDivider from "../../atoms/InteractiveLine/InteractiveDivider";
import InteractivePlus from "../../atoms/InteractivePlus/InteractivePlus";
import InteractiveText from "../../atoms/InteractiveText/InteractiveText";
import Space from "../../atoms/Spacing/Space";
import { IAccordionTab } from "./AccordionTab.interface";

import "./AccordionTab.scss";

const AccordionTab = ({ question, inititalVariant, answers, additionalLink, additionalLinkLabel }: IAccordionTab) => {
  const [variant, setVariant] = useState<ComponentActiveState>(inititalVariant);

  const turnDefault = () => setVariant("default");
  const turnHover = () => setVariant("hover");
  const turnActive = () => setVariant("active");

  const handleHover = () => {
    if (variant === "default") {
      turnHover();
      return;
    }
    if (variant === "hover") {
      return;
    }
    if (variant === "active") {
      return;
    }
  };

  const handleClick = () => {
    if (variant === "default") {
      turnActive();
      return;
    }
    if (variant === "hover") {
      turnActive();
      return;
    }
    if (variant === "active") {
      turnDefault();
      return;
    }
  };

  const handleBlur = () => {
    if (variant === "default") {
      return;
    }
    if (variant === "hover") {
      turnDefault();
      return;
    }
    if (variant === "active") {
      return;
    }
  };

  return (
    <div
      className="accordion-tab"
      onClick={handleClick}
      onMouseOver={handleHover}
      onMouseLeave={handleBlur}
      role="button"
    >
      <div className="accordion-tab-top">
        <InteractiveText
          imgWidth={16}
          imgHeight={15}
          variant={variant}
          textRole={"ps1"}
          weight={"bold"}
          className={"w-90"}
        >
          {question}
        </InteractiveText>
        <InteractivePlus variant={variant} />
      </div>
      <Space space="space-md" />
      <AccordionTabContent
        variant={variant}
        answers={answers}
        additionalLink={additionalLink}
        additionalLinkLabel={additionalLinkLabel}
      />
      <InteractiveDivider variant={variant} />
    </div>
  );
};

export default AccordionTab;
