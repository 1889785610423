export const validateName = (input: string) => {
  let error = "";

  if (input.trim().length <= 0) {
    error = "Name cannot be empty.";
  } else if (input.trim().length >= 100) {
    error = "Name too long.";
  }

  return { error };
};
