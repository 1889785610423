import React, { ReactNode } from "react";
import "./Explainer.scss";

interface Props {
  url?: string;
  ownVideoTag?: ReactNode;
}

const Explainer = ({ url, ownVideoTag }: Props) => {
  return (
    <div className="explainer">
      {!!ownVideoTag ? (
        <>{ownVideoTag}</>
      ) : (
        <iframe
          width="100%"
          height="100%"
          src={url}
          title="video"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default Explainer;
