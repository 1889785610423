/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import { IAccordionTabContent } from "./AccordionTabContent.interface";
import useTagHeight from "../../../../hooks/useTagHeight";
import { ComponentActiveState } from "../../../../shared/types/IComponentActiveState";
import Space from "../Spacing/Space";
import Link from "../Link/Link";
import "./AccordionTabContent.scss";

const AccordionTabContent = ({
  variant,
  answers,
  imgWidth,
  imgHeigth,
  additionalLink,
  additionalLinkLabel = ""
}: IAccordionTabContent) => {
  const { elementHeight, tagRef } = useTagHeight();

  const getHeight = (variant: ComponentActiveState) => {
    if (variant === "default") {
      return 0;
    }
    if (variant === "hover") {
      return 0;
    }
    if (variant === "active") {
      return elementHeight;
    }
    throw new Error("Wrong variant");
  };

  const initialHeight = getHeight(variant);
  const [contentHeight, setContentHeight] = useState<number>(initialHeight);

  useEffect(() => {
    setContentHeight(getHeight(variant));
  }, [variant, elementHeight]);

  return (
    <div className={`accordion-tab-content variant ${variant}`} style={{ height: contentHeight }}>
      <Row ref={tagRef} className="content-row">
        {answers.map((answer: string) => {
          return (
            <Col xs={12} md={6} xl={4} key={Math.random()}>
              <p className="s2">{answer}</p>
            </Col>
          );
        })}
        {additionalLink && additionalLinkLabel && (
          <>
            <Space space="space-md" />
            <Link
              imgWidth={16}
              imgHeight={15}
              url={additionalLink}
              target="_blank"
              variant="trietary"
              size="size-small"
              icon="/images/go-to.svg"
            >
              {additionalLinkLabel}
            </Link>
          </>
        )}
        <Space space="space-xxl" />
      </Row>
    </div>
  );
};

export default AccordionTabContent;
