export const productsSkeyTixBenefits = [
  {
    benefit: "Innovative and secure ticketing system."
  },
  {
    benefit: "A transparent system allows for faster and better ticket verification."
  },
  {
    benefit: "Current ticket status."
  },
  {
    benefit: "Enables the creation of statistics."
  },
  {
    benefit: "Optimizes traffic on gates."
  },
  {
    benefit: "Easy and convenient to use."
  },
  {
    benefit: "Enables your own graphical version of the NFT ticket."
  },
  {
    benefit: "Ensures anonymity and security of sensitive data."
  },
  {
    benefit: "Hides personal information; they are not revealed in use and verification."
  }
];
