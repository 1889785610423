import { Container } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import Stack from "../../../../../components/arrangement/Stack/Stack";
import Section from "./Sections/Section";
import SectionStack from "./SectionStack/SectionStack";
import { IListItem } from "../../../../components/ListItem/IListItem";

import "./HowItWorks.scss";

const parkingLots: IListItem[] = [
  {
    imgUrl: "/images/rwa/how-it-works/rwa.svg",
    title: "RWA",
    description: "Parking lot"
  },
  {
    imgUrl: "/images/rwa/how-it-works/revenue.svg",
    title: "Revenue",
    description: "Passive Income"
  },
  {
    imgUrl: "/images/rwa/how-it-works/ownership.svg",
    title: "Ownership",
    description: "Shares stored using NFT"
  }
];

const advertisingSpaces: IListItem[] = [
  {
    imgUrl: "/images/rwa/how-it-works/rwa.svg",
    title: "RWA",
    description: "Advertising space"
  },
  {
    imgUrl: "/images/rwa/how-it-works/revenue.svg",
    title: "Revenue",
    description: "Passive Income"
  },
  {
    imgUrl: "/images/rwa/how-it-works/ownership.svg",
    title: "Ownership",
    description: "Shares stored using NFT"
  }
];

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Stack fullWidth rowGap={isMobile ? 60 : 80}>
            <Stack fullWidth rowGap={20} style={{ zIndex: 1 }}>
              <h2 className="m-xs-a t-xs-c">How does it work?</h2>
              <p className="s2 t-xs-c m-xs-a" style={{ maxWidth: "720px" }}>
                Our SmartContract automates the entire process - from presenting the offer to distributing profits -
                with guaranteed interest protection.
              </p>
            </Stack>
            <Section directionOnMobile={"reversed"} isMobile={isMobile}>
              <SectionStack
                itemsList={parkingLots}
                title={"Parking lots"}
                description={
                  "Tokenizing a parking lot with RWA allows you to easily trade shares and generate regular income, increasing liquidity and availability of investments. This solution will also work well in other real estate sectors."
                }
                button={{
                  isDisabled: false,
                  url: "https://www.youtube.com/watch?v=BxyEU7wVCjI"
                }}
              />
              <img src="/images/rwa/how-it-works/parking-lots.png" alt="parking-lots" className="section-img" />
            </Section>
            <Section directionOnMobile={"classic"} isMobile={isMobile}>
              <img src="/images/rwa/how-it-works/advertising-spaces.png" alt="parking-lots" className="section-img" />
              <SectionStack
                itemsList={advertisingSpaces}
                title={"Advertising spaces"}
                description={
                  "The tokenization of advertising space allows investors to easily acquire stakes in profitable locations, increasing the liquidity of the advertising market."
                }
                button={{
                  isDisabled: false,
                  url: "https://www.youtube.com/watch?v=Vb9djZDYRgI"
                }}
              />
            </Section>
            <Section directionOnMobile={"reversed"} isMobile={isMobile}>
              <SectionStack
                title={"Photovoltaic farms"}
                description={
                  "The tokenization of photovoltaic farms allows investors to acquire stakes in energy projects, which increases liquidity and opens up new investment opportunities. As a result, owners can raise capital faster, and investors gain access to steady income from renewable energy production"
                }
                button={{
                  isDisabled: true,
                  url: ""
                }}
              />
              <img src="/images/rwa/how-it-works/photovoltaic-farms.png" alt="parking-lots" className="section-img" />
            </Section>
          </Stack>
        </Container>
      </Container>
    </div>
  );
};

export default HowItWorks;
