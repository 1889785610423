import { useNavigationContext } from "../../../../contexts/NavigationContext";

import Image from "../../atoms/Image/Image";
import RouterLink from "../../atoms/RouterLink/RouterLink";

import DropdownV2 from "../../DropdownV2/DropdownV2";
import Stack from "../../../../components/arrangement/Stack/Stack";

import StakingButtonLink from "../../domain/StakingButton/StakingButtonLink";
import StakingButtonShell from "../../domain/StakingButton/StakingButtonShell";

import "./MenuBar.scss";

const MenuBar = () => {
  const { links } = useNavigationContext();

  const menuContent = links
    .filter((link) => !link.isHidden)
    .map((link) => {
      return (
        <RouterLink
          className="menu-link"
          label={link.label}
          link={link.path}
          isActive={window.location.pathname === link.path}
          key={Math.random()}
        />
      );
    });

  const mobileMenuContent = (
    <>
      {menuContent}
      <StakingButtonLink className="w-xs-100 w-md-mc flex justify-center">
        <StakingButtonShell />
      </StakingButtonLink>
    </>
  );

  return (
    <>
      <nav className="menu-bar d-xs-n d-md-f">{menuContent}</nav>
      <DropdownV2
        list={
          <Stack rowGap={8} className="nav-tooltip" alignItems="center" justifyContent="flex-start">
            {mobileMenuContent}
          </Stack>
        }
        className="pointer tooltip-wrapper d-xs-b d-md-n"
      >
        <Image
          imgWidth={22}
          imgHeight={16}
          imgPath={"/images/hamburger.svg"}
          alt={"hamburger"}
          style={{ paddingRight: "0px" }}
          className={"hamburger mobile-md p-xs-1 p-md-0"}
        />
      </DropdownV2>
    </>
  );
};

export default MenuBar;
