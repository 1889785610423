import { Container } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import { useIsMobile } from "../../../hooks/useIsMobile";

import "./CooperationHero.scss";

const CooperationHero = () => {
  const isMobile = useIsMobile("lg");
  return (
    <div id="cooperation-hero">
      {isMobile && (
        <img
          width="944"
          height="339"
          src="/images/cooperation-hero-bg-mobile.png"
          alt="logo"
          className="cooperation-bg-blocks"
        />
      )}
      {!isMobile && (
        <img
          width="1509"
          height="589"
          src="/images/cooperation-hero-bg.png"
          alt="logo"
          className="cooperation-bg-blocks"
        />
      )}
      <Container fluid="xxl">
        <Container fluid="xl flex-center">
          <h1 className="header">Cooperation</h1>
          <Space space={"space-lg"} />
          <p className="s1 subheader">The Skey Network ecosystem is growing up! Check out who uses our products!</p>
        </Container>
      </Container>
    </div>
  );
};

export default CooperationHero;
