// ;
import { IChildren } from "../../shared/interfaces/IChildren";
// import Backdrop from "../components/atoms/Backdrop/Backdrop";
import Footer from "../sections/Footer/Footer";
// import Forms from "../sections/Forms/Forms";
import NavbarInfo from "../components/organisms/Navbar/NavbarInfo";

const WithNavbarAndFooter = ({ children }: IChildren) => {
  return (
    <>
      <NavbarInfo />
      {children}
      <Footer />
      {/* <Forms /> */}
    </>
  );
};

export default WithNavbarAndFooter;
