import { useFormsControll } from "../../../../contexts/FormsControllContext";
import useClickOutside from "../../../../hooks/useClickOutside";
import { IChildren } from "../../../../shared/interfaces/IChildren";
import "./Backdrop.scss";

const Backdrop = ({ children }: IChildren) => {
  const { closeAll } = useFormsControll();

  const ref = useClickOutside(closeAll);

  return (
    <div className="skey-backdrop">
      <div ref={ref as any}>{children}</div>
    </div>
  );
};

export default Backdrop;
