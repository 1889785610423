import "./Anchor.scss";

const Anchor = ({ children, imgClassName = "" }: any) => {
  return (
    <div className="anchor">
      <img width={60} height={60} src="/images/anchor.svg" alt="anchor" className={`anchor-img ${imgClassName}`} />
      {children}
    </div>
  );
};

export default Anchor;
