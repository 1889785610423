import { useEffect } from "react";

import { useModules } from "../../../../contexts/ModulesContext";
import CheckboxWithText from "../../molecules/CheckboxWithText/CheckboxWithText";

import "./CheckboxSection.scss";

const CheckboxSection = () => {
  const { options, toggleModuleById, resetCheckboxes } = useModules();

  useEffect(() => {
    return resetCheckboxes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="checkbox-section mb-xs-3 mt-xs-1">
      <label className="p1 mb-xs-1">Select SkeyBOX modules*</label>
      <div className="checkboxes-container rg-xs-1 rg-md-2">
        {options.map((option, index) => (
          <CheckboxWithText
            isChecked={option.isChecked}
            onToggle={() => toggleModuleById(option.id)}
            label={option.label}
            className="d-flex"
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxSection;
