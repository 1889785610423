import { CSSProperties, ReactNode } from "react";

import { BaseProps } from "../../../shared/interfaces/BaseProps";
import { classNames } from "../../../utils/classNames";

import "./Stack.scss";

export interface IStack extends BaseProps {
  colGap?: number;
  rowGap?: number;
  fullSize?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  children: ReactNode;
}

const Stack = ({
  colGap = 0,
  rowGap = 0,
  fullSize = false,
  fullWidth = false,
  fullHeight = false,
  children,
  className,
  justifyContent = "flex-start",
  alignItems = "flex-start",
  style
}: IStack) => {
  const classes = classNames(
    "stack",
    className,
    fullSize ? "full-size" : "",
    fullWidth ? "full-width" : "",
    fullHeight ? "full-height" : ""
  );

  const isGap = !!rowGap || !!colGap;

  return (
    <div
      style={{
        gap: isGap ? `${rowGap}px ${colGap}px` : undefined,
        justifyContent,
        alignItems,
        ...style
      }}
      className={classes}
    >
      {children}
    </div>
  );
};

export default Stack;
