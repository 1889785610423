import { BaseProps } from "../../../shared/interfaces/BaseProps";
import { classNames } from "../../../utils/classNames";

import "./ShadowBox.scss";

interface Props extends BaseProps {}

const ShadowBox = (props: Props) => {
  return (
    <div className={classNames("shadow-box", props.className)} style={props.style}>
      {props.children}
    </div>
  );
};

export default ShadowBox;
