import { IStoryCardProps } from "../../../../shared/interfaces/IStoryCardProps";
import Link from "../../atoms/Link/Link";
import Space from "../../atoms/Spacing/Space";
import "./StoryCard.scss";

const StoryCard = ({ anchorSide, date, title, description, url = "" }: IStoryCardProps) => {
  return (
    <div className={`story-card ${anchorSide}`}>
      <p>{date}</p>
      <Space space="space-xs" />
      <p className="s1">{title}</p>
      <Space space="space-sm" />
      <p className="s2">{description}</p>
      {url && (
        <>
          <Space space="space-sm" />
          <Link url={url} className="d-unset" variant="trietary" size="size-small" icon="/images/go-to.svg">
            more...
          </Link>
        </>
      )}
    </div>
  );
};

export default StoryCard;
