import { FC } from "react";

const VerticalLine: FC<{ className?: string }> = ({ className = "" }) => {
  return (
    <svg width="2" height="18" viewBox="0 0 2 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M1 1V17" stroke="#120E2F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default VerticalLine;
