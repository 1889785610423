import { IPanelCard } from "../shared/interfaces/IPanelCard";

export const administrationPanelCard: IPanelCard[] = [
  {
    image: "/images/create-events.png",
    title: "Create events"
  },
  {
    image: "/images/generate-tickets.png",
    title: "Generate NFT tickets"
  },
  {
    image: "/images/manage-controllers.png",
    title: "Manage controllers"
  },
  {
    image: "/images/tickets-design.png",
    title: "Give your tickets their own design"
  },

  {
    image: "/images/validation-process.png",
    title: "Observe the ticket validation process"
  },
  {
    image: "/images/website-event.png",
    title: "Create a website for your event"
  },

  {
    image: "/images/sign-up.png",
    title: "Add the ability to sign up"
  },
  {
    image: "/images/support-consents.png",
    title: "Optional support for consents such as GDPR"
  }
];
