import ContactProductSkeyAccess from "../../sections/ContactSection/ContactProductSkeyAccess/ContactProductSkeyAccess";
import ProductsSkeyAccessControlHero from "../../sections/ProductsSkeyAccessControlHero/ProductsSkeyAccessControlHero";
import BlockchainAccessControl from "../../sections/BlockchainAccessControl/BlockchainAccessControl";
import Tokenomy from "../../sections/Tokenomy/Tokenomy";
import "./ProductsSkeyAccessControl.scss";
import ProductUseCases from "../../sections/ProductUseCases/ProductUseCases";
import { getCardObjectByTitle } from "../../../data/cooperationCards";
import TechnicalDocumentation from "../../sections/TechnicalDocumentation/TechnicalDocumentation";
import { productSkeyAccessLinks } from "../../../data/productSkeyAccessLinks";

const carumaObj = getCardObjectByTitle("Caruma");

const ProductsSkeyAccessControl = () => {
  return (
    <section id="products-skey-access-control">
      <ProductsSkeyAccessControlHero />
      <BlockchainAccessControl />
      <ProductUseCases useCaseCard={{ ...carumaObj }} />
      <Tokenomy />
      <TechnicalDocumentation links={[...productSkeyAccessLinks]} />
      <ContactProductSkeyAccess />
    </section>
  );
};

export default ProductsSkeyAccessControl;
