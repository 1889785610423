import "./ProductsSkeyBoxPlatform.scss";
import { Container } from "react-bootstrap";
import PlatformsSkeyBox from "../PlatformsSkeyBox/PlatformsSkeyBox";

const ProductsSkeyBoxPlatform = () => {
  return (
    <div id="products-skey-box-platform">
      <Container fluid="xxl">
        <Container fluid="xl flex-center">
          <h3 className="header-panel-box">The Platform consists of</h3>
          <PlatformsSkeyBox />
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyBoxPlatform;
