import { ReactNode } from "react";

import useFormHeight from "../../../../../hooks/useFormHeight";
import { useFormsControll } from "../../../../../contexts/FormsControllContext";

import "../Form.scss";

interface FormProps {
  children: ReactNode;
  className?: string;
}

const ContactForm = ({ children, className }: FormProps) => {
  const { formHeight, formRef } = useFormHeight();
  const { formStage } = useFormsControll();

  const finalFormHeight = formStage === "form" ? "max-content" : `${formHeight}px`;

  const buildClass = `form${className ? ` ${className}` : ""}`;

  return (
    <form className={buildClass} style={{ height: finalFormHeight }}>
      <div className="overlay" ref={formRef}>
        {children}
      </div>
    </form>
  );
};

export default ContactForm;
