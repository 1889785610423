import { ReactNode, createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface IContext {
  children: ReactNode;
}

const ScrollPageContext = createContext(null as any);

export const ScrollPageProvider = ({ children }: IContext) => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    //else scroll to section
    else {
      setTimeout(() => {
        const sectionId = hash.substring(1); // cut first character (#)
        const section = document.getElementById(sectionId);

        if (section) {
          window.scrollTo({
            behavior: section ? "smooth" : "auto",
            top: section ? section.offsetTop : 0
          });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return <ScrollPageContext.Provider value={""}>{children}</ScrollPageContext.Provider>;
};

export const useScrollPageContext = () => useContext(ScrollPageContext);
