import { useState, useMemo } from "react";

import useTagHeight from "../../../../hooks/useTagHeight";
import { IStoryCardProps } from "../../../../shared/interfaces/IStoryCardProps";
import Anchor from "../../atoms/Anchor/Anchor";
import StoryCard from "../StoryCard/StoryCard";
import StoryOverlay from "../StoryOverlay/StoryOverlay";
import TabInteractiveDate from "../TabInteractiveDate/TabInteractiveDate";
import Space from "../../atoms/Spacing/Space";

import "./StoryTab.scss";

type TVariant = "default" | "active";

interface IStoryTab {
  initialVariant: TVariant;
  date: string;
  cards: IStoryCardProps[];
}

const StoryTab = ({ initialVariant, date, cards }: IStoryTab) => {
  const [open, setOpen] = useState<TVariant>(initialVariant);

  const handleOpen = () => setOpen("active");
  const handleClose = () => setOpen("default");

  const toggleOpen = () => {
    if (open === "active") {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const { elementHeight, tagRef } = useTagHeight();

  const takeHeight = () => {
    return open === "active" ? elementHeight : 0;
  };

  const content = useMemo(() => {
    return (
      <div ref={tagRef} className="content-holder">
        {cards.map((card) => {
          return (
            <Anchor key={Math.random()} imgClassName="desktop-xl">
              <StoryCard
                date={card.date}
                title={card.title}
                description={card.description}
                anchorSide={card.anchorSide}
                url={card.url}
              />
            </Anchor>
          );
        })}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button className="story-tab" onClick={toggleOpen}>
        <TabInteractiveDate variant={open} date={date} />
      </button>
      <Space space="space-sm" />
      <StoryOverlay variant={open} height={takeHeight()}>
        {content}
      </StoryOverlay>
    </>
  );
};

export default StoryTab;
