import { Container } from "react-bootstrap";
import Stack from "../../../../../components/arrangement/Stack/Stack";

import "./FewSteps.scss";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const FewSteps = () => {
  const isMobile = useIsMobile("lg");
  return (
    <div className="few-steps">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Stack rowGap={isMobile ? 32 : 64} fullWidth>
            <Stack rowGap={20} fullWidth>
              <h2 className="t-xs-c m-xs-a" style={{ whiteSpace: "pre-wrap" }}>
                {"Digital Passport in\nfew steps"}
              </h2>
              <p className="s2 w-xs-100 t-xs-c m-xs-a" style={{ maxWidth: "580px" }}>
                The Digital Product Passport is the future of trade in the European Union, allowing companies to meet
                the latest regulatory and environmental requirements.
              </p>
            </Stack>
            <img
              src="/images/passport/few-steps/desktop.png"
              alt="few steps"
              className="d-xs-n d-lg-b"
              style={{ maxWidth: "100%", marginBottom: "-50px" }}
            />
            <img
              src="/images/passport/few-steps/mobile.png"
              alt="few steps"
              className="d-xs-b d-lg-n"
              style={{ position: "relative", left: "50%", transform: "translateX(-50%)", marginBottom: "-120px" }}
            />
          </Stack>
        </Container>
      </Container>
    </div>
  );
};

export default FewSteps;
