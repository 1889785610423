import { IGridCards } from "../../../../shared/interfaces/IGridCards";

import "./GridCards.scss";

const GridCards = ({ gridGap = "20", maxColumns = "3", children, className = "" }: IGridCards) => {
  return (
    <div className={`grid-cards grid-cards-col-${maxColumns} grid-cards-gap-${gridGap} ${className}`}>{children}</div>
  );
};

export default GridCards;
