import { Container } from "react-bootstrap";
import StoryAccordion from "../../components/organisms/StoryAccordion/StoryAccordion";
import { newsStoriesOfTheProject } from "../../../data/newsStoriesOfTheProject";
import Space from "../../components/atoms/Spacing/Space";
import "./NewsStory.scss";

const NewsStory = () => {
  return (
    <div id="news-story">
      <Container fluid="xxl">
        <Container fluid="xl">
          <h2 className="header">The story of the project</h2>
          <Space space="space-xxl" />
          <StoryAccordion storyAccordionItems={newsStoriesOfTheProject} />
        </Container>
      </Container>
    </div>
  );
};

export default NewsStory;
