import { IChildren } from "../../../../shared/interfaces/IChildren";
import { IOptions } from "../../../../shared/interfaces/IOptions";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import "./WithDropdown.scss";

const WithDropdown = ({ children, options, onSelect, appear }: IChildren & IOptions & any) => {
  return (
    <div className="with-dropdown">
      {children}
      {appear && <Dropdown options={options} onSelect={onSelect} />}
    </div>
  );
};

export default WithDropdown;
