import { DIGITAL_PASSPORT } from "../../../../constants/constants";
import { DomainComponent } from "../../../../shared/interfaces/DomainComponent";
import { classNames } from "../../../../utils/classNames";

const CaseStudyButton = ({ children, className }: DomainComponent) => {
  return (
    <a href={DIGITAL_PASSPORT} target="_blank" rel="noreferrer" className={classNames(className)}>
      {children}
    </a>
  );
};

export default CaseStudyButton;
