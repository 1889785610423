import { IStoryOverlay } from "../../../../shared/interfaces/IStoryOverlay";
import StoryLine from "../../atoms/StoryLine/StoryLine";
import "./StoryOverlay.scss";

const StoryOverlay = ({ variant, children, height }: IStoryOverlay) => {
  return (
    <div className={`story-overlay ${variant}`} style={{ height: height }}>
      {children}
      <StoryLine className="desktop-xl" />
    </div>
  );
};

export default StoryOverlay;
