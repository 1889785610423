import StakingButtonLink from "./StakingButtonLink";
import StakingButtonShell from "./StakingButtonShell";

const StakingButton = () => {
  return (
    <StakingButtonLink>
      <StakingButtonShell />
    </StakingButtonLink>
  );
};

export default StakingButton;
