import { useAccordionTab } from "../AccordionTab/AccordionTab";
import Group from "../../../../components/arrangement/Group/Group";

import AccordionPlusIcon from "../AccordionPlusIcon/AccordionPlusIcon";
import { AccordionSummaryProps } from "../AccordionV2.types";
import "./AccordionSummary.scss";

const AccordionSummary = ({ children }: AccordionSummaryProps) => {
  const { variant, handleClick, handleHover, handleBlur } = useAccordionTab();

  return (
    <div
      role="button"
      onClick={handleClick}
      onMouseOver={handleHover}
      onMouseLeave={handleBlur}
      className="accordion-summary"
    >
      <Group justifyContent="space-between" className="accordion-summary-row">
        <p className="s2 bold">{children}</p>
        <AccordionPlusIcon variant={variant} />
      </Group>
    </div>
  );
};

export default AccordionSummary;
