import Container from "react-bootstrap/esm/Container";
import { sectionsTitles, skeyNetworkBoxKeys, skeyNetworkBoxValues } from "../../data/data";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import Group from "../../../../../components/arrangement/Group/Group";
import Box from "../../components/Box/Box";

import "./SkeyNetwork.scss";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const skeyNetworkBoxesOrder: typeof skeyNetworkBoxKeys[number][] = ["skey-box", "skey-tix", "skey-access", "skey-rwa"];

const SkeyNetwork = () => {
  const isMobile = useIsMobile("md");
  return (
    <div className="skey-network pt-xs-10 pt-md-15">
      <Container fluid="xl">
        <Stack rowGap={isMobile ? 32 : 60}>
          <SectionTitle {...sectionsTitles["skey-network"]} />
          <Group colGap={40} rowGap={isMobile ? 8 : 40} className="grid" alignItems="flex-start">
            {skeyNetworkBoxesOrder.map((boxKey) => {
              const box = skeyNetworkBoxValues[boxKey];
              return <Box {...box} />;
            })}
          </Group>
        </Stack>
      </Container>
    </div>
  );
};

export default SkeyNetwork;
