import { Fragment } from "react";

import GridCards from "../../components/organisms/GridCards/GridCards";
import { productsSkeyBoxPlatform } from "../../../data/productsSkeyBoxPlatform";
import Image from "../../components/atoms/Image/Image";
import Space from "../../components/atoms/Spacing/Space";
import "./PlatformsSkeyBox.scss";

const PlatformsSkeyBox = () => {
  return (
    <div>
      <GridCards maxColumns="2" className="col-box-panel">
        {productsSkeyBoxPlatform.map((card, index) => (
          <Fragment key={index}>
            <Image className="image-box" imgWidth={80} imgHeight={65} imgPath={card.img} alt="products" />
            <div className="box-platform">
              <p className="s1 header">{card.header}</p>
              <Space space="space-sm" />
              <p className="s2 title">{card.title}</p>
            </div>
          </Fragment>
        ))}
      </GridCards>
    </div>
  );
};

export default PlatformsSkeyBox;
