import { LinkItem } from "../shared/interfaces/LinkItem";
import { PRODUCT_SKEY_TIX_GITBOOK, PRODUCT_SKEY_TIX_PANEL_MANUAL, PRODUCT_SKEY_TIX_VIDEO } from "../constants";

export const productsSkeyTixLinks: LinkItem[] = [
  {
    label: "Show Gitbook",
    link: PRODUCT_SKEY_TIX_GITBOOK,
    icon: "/images/gitbook.svg"
  },
  {
    label: "Watch Video",
    link: PRODUCT_SKEY_TIX_VIDEO,
    icon: "/images/video.svg"
  },
  {
    label: "Read how it works",
    link: PRODUCT_SKEY_TIX_PANEL_MANUAL,
    icon: "/images/whitepaper.svg"
  }
];
