import { Col, Container, Row } from "react-bootstrap";

import Space from "../../components/atoms/Spacing/Space";

import { exchangeStockOnlyImages } from "../../../data/exchangeStocks";

import "./OverviewDex.scss";

const OverviewDex = () => {
  return (
    <div className="overview-dex">
      <Container fluid="xxl">
        <Container fluid="xl" className="as-col">
          <p className="s1 title text-center">Exchanges</p>
          <Space space="space-lg" className="desktop-lg" />
          <Space space="space-lg" className="mobile-lg" />
          <Row className="wrapper">
            {exchangeStockOnlyImages.map((exchange, index) => (
              <Col xs={{ span: 6 }} md={{ span: 2 }} className="exchange-col" key={index}>
                <a href={exchange.url} target="_blank" rel="noreferrer noopener nofollow" className="link">
                  <img src={exchange.img} alt="Exchange" width={70} height={70} />
                  <p className="s2 mt-xs-1">{exchange.title}</p>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default OverviewDex;
