import { useEffect, useState } from "react";

import { useAccordionTab } from "../AccordionTab/AccordionTab";
import useTagHeight from "../../../../hooks/useTagHeight";

import { AccordionActiveState, AccordionDetailsProps } from "../AccordionV2.types";

import "./AccordionDetails.scss";

const AccordionDetails = ({ children }: AccordionDetailsProps) => {
  const { elementHeight, tagRef } = useTagHeight();
  const { variant } = useAccordionTab();

  const getHeight = (variant: AccordionActiveState) => {
    if (variant === "default") {
      return 0;
    }
    if (variant === "hover") {
      return 0;
    }
    if (variant === "active") {
      return elementHeight;
    }
    throw new Error("Wrong variant");
  };

  const initialHeight = getHeight(variant);
  const [contentHeight, setContentHeight] = useState<number>(initialHeight);

  useEffect(() => {
    setContentHeight(getHeight(variant));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, elementHeight]);

  const classes = `accordion-tab-content accordion-tab-content-${variant}`;

  return (
    <div className={classes} style={{ height: contentHeight }}>
      <div ref={tagRef} className="pb-4">
        {children}
      </div>
    </div>
  );
};

export default AccordionDetails;
