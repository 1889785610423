import { Container } from "react-bootstrap";

import { menuLinks } from "../../../../data/menuLinks";

import Group from "../../../../components/arrangement/Group/Group";
import MenuBar from "../../molecules/MenuBar/MenuBar";
import Image from "../../atoms/Image/Image";

import StakingButton from "../../domain/StakingButton/StakingButton";

import "./Navbar.scss";

const Navbar = () => {
  return (
    <>
      <div className="navbar-background"></div>
      <section id="nav-bar">
        <Container fluid="xl">
          <Group fullSize justifyContent="space-between">
            <Image
              imgWidth={104}
              imgHeight={36}
              imgPath={"/images/skey-logo-basic.svg"}
              alt={"skey logo"}
              asLink={true}
              href={menuLinks.overview.paths[0]}
            />
            <Group fullHeight colGap={10} className="w-auto relative">
              <MenuBar />
              <Group className="d-xs-n d-md-f">
                <StakingButton />
              </Group>
            </Group>
          </Group>
        </Container>
      </section>
    </>
  );
};

export default Navbar;
