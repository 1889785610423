import { Container } from "react-bootstrap";
import ContentWithVideo from "../../../../sections/ContentWithVideo/ContentWithVideo";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import { IListItem } from "../../../../components/ListItem/IListItem";
import ListItem from "../ListItem/ListItem";

import "./DigitalProductPotential.scss";

const listItems: IListItem[] = [
  {
    imgUrl: "/images/passport/potential/potential.svg",
    title: "Potential",
    description: "$10 billion per year"
  },
  {
    imgUrl: "/images/passport/potential/market.svg",
    title: "Market",
    description: "All companies\ntrading in the EU"
  },
  {
    imgUrl: "/images/passport/potential/trend.svg",
    title: "Trend",
    description: "Tokenization of goods"
  },
  {
    imgUrl: "/images/passport/potential/industries.svg",
    title: "Industries",
    description: "Fashion, Beauty, Batteries, etc."
  }
];

const Content = () => {
  return (
    <Stack rowGap={24} className="pl-xs-0 pl-md-2">
      {listItems.map((li, id) => (
        <ListItem {...li} key={id} />
      ))}
    </Stack>
  );
};

const DigitalProductPotential = () => {
  return (
    <div className="digital-product-potential">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <ContentWithVideo
            content={<Content />}
            videoUrl={"https://www.youtube.com/embed/agGUOVbvJ5g?si=kWA1a9oitRYT0XiT"}
          />
        </Container>
      </Container>
    </div>
  );
};

export default DigitalProductPotential;
