import { Link } from "react-router-dom";
import { menuLinks } from "../../../data/menuLinks";

import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="404" style={{ width: "100%", paddingTop: "80px" }}>
      <h1 style={{ margin: "auto", width: "max-content" }}>404 - Not found</h1>
      <Link
        to={menuLinks.overview.paths[0]}
        className="underline"
        style={{ margin: "auto", width: "max-content", display: "block" }}
      >
        Go to the main page
      </Link>
    </div>
  );
};

export default NotFound;
