import { Fragment } from "react";
import Link from "../../atoms/Link/Link";
import Space from "../../atoms/Spacing/Space";
import { IScrollToProps } from "../../../../shared/interfaces/IScrollToProps";

import { Link as RouterLink } from "react-router-dom";

import "./ScrollToBox.scss";

const ScrollToBox = ({ boxTitle, links, className = "" }: IScrollToProps) => {
  return (
    <div className={`scroll-to-box ${className}`}>
      <p className={`s2 title`}>{boxTitle}</p>
      <Space space={"space-xs"} />
      {links.map(({ url, children, target, download }) => {
        return (
          <Fragment key={Math.random()}>
            {target ? (
              <Link
                url={url}
                target={target}
                download={download}
                variant="trietary"
                mode="dark-mode"
                size="size-extra-small"
              >
                {children}
              </Link>
            ) : (
              <RouterLink to={url} className="footer-link">
                {children}
              </RouterLink>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default ScrollToBox;
