import { useState, ReactNode, FC } from "react";

import { BaseProps } from "../../../shared/interfaces/BaseProps";
import { classNames } from "../../../utils/classNames";

import "./Dropdown.scss";
import useClickOutside from "../../../hooks/useClickOutside";

interface DropdownProps extends BaseProps {
  children: ReactNode;
  list: ReactNode;
}

type DropdownStatus = "opened" | "closed" | "init";

const DropdownV2: FC<DropdownProps> = ({ children, list, ...rest }) => {
  const [status, setStatus] = useState<DropdownStatus>("init");

  const handleToggle = () => {
    setStatus((prev) => {
      switch (prev) {
        case "init":
          return "opened";
        case "closed":
          return "opened";
        case "opened":
          return "closed";
      }
    });
  };

  const ref = useClickOutside(() =>
    setStatus((prev) => {
      return prev === "init" ? "init" : "closed";
    })
  );

  return (
    <div className={classNames("dropdown", rest.className)} style={rest.style} ref={ref as any} onClick={handleToggle}>
      <div>{children}</div>
      <div className={`dropdown-list ${status}`}>{list}</div>
    </div>
  );
};

export default DropdownV2;
