import { BaseProps } from "../../../../../shared/interfaces/BaseProps";
import { classNames } from "../../../../../utils/classNames";

import "./Chips.scss";

interface Props extends BaseProps {}

const Chips = (props: Props) => {
  return (
    <div className={classNames("chips", props.className)} style={props.style}>
      {props.children}
    </div>
  );
};

export default Chips;
