import { MutableRefObject, ReactNode, createContext, useContext } from "react";
import useTagHeight from "../hooks/useTagHeight";

interface IHeightProvider {
  elementHeight: number;
  tagRef: MutableRefObject<any>;
}

export const HeightProviderCtx = createContext(null as any);

export const HeightProvider = ({ children }: { children: ReactNode }) => {
  const tag = useTagHeight();
  return <HeightProviderCtx.Provider value={tag}>{children}</HeightProviderCtx.Provider>;
};

export const useCtxHeightProvider = (): IHeightProvider => useContext(HeightProviderCtx);
