import Container from "react-bootstrap/esm/Container";
import Stack from "../../../../../components/arrangement/Stack/Stack";

import "./Partners.scss";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const Partners = () => {
  const isMobile = useIsMobile("md");
  return (
    <div className="partners pt-xs-10 pt-md-19">
      <Container fluid="xl">
        <Stack fullWidth alignItems="center" rowGap={isMobile ? 8 : 16}>
          <h1 className="h1-info">Partners</h1>
          <p className="s1 t-xs-c" style={{ maxWidth: "530px" }}>
            Build an entire ecosystem of services together with us and our partners
          </p>
        </Stack>
      </Container>
    </div>
  );
};

export default Partners;
