import { useDefaultProduct } from "../../../../contexts/DefaultProductContext";
import { useFormsControll } from "../../../../contexts/FormsControllContext";
import ContactSection from "../ContactSection";
import "./ContactProductSkeyAccess.scss";

const ContactProductSkeyAccess = () => {
  const { openProductForm } = useFormsControll();
  const { handleDefaultProduct } = useDefaultProduct();

  const contactAction = () => {
    openProductForm();
    handleDefaultProduct("Skey Access Control");
  };
  return (
    <ContactSection
      header={"Create your product"}
      subheader={"Contact us and transfer your business to the blockchain!"}
      buttonLabel={"Contact us"}
      action={contactAction}
    />
  );
};

export default ContactProductSkeyAccess;
