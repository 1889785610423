import { useState } from "react";

const useFormMessage = () => {
  const [message, setMessage] = useState({
    text: "",
    focus: false
  });
  const MESSAGE_FIELD = {
    label: "Message",
    placeholder: "Enter your message",
    type: "text"
  };
  const focusMessage = () => setMessage((prev) => ({ ...prev, focus: true }));
  const blurMessage = () => setMessage((prev) => ({ ...prev, focus: false }));
  const handleMessage = (value: string) => {
    setMessage((prev) => ({ ...prev, text: value }));
  };

  return { message, MESSAGE_FIELD, focusMessage, blurMessage, handleMessage };
};

export default useFormMessage;
