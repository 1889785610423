import { IExchangeStockProps } from "../../../../shared/interfaces/IExchangeStockProps";
import Image from "../../atoms/Image/Image";
import Space from "../../atoms/Spacing/Space";
import "./ExchangeCard.scss";

const ExchangeCard = ({ description, imgPath, title, url }: IExchangeStockProps) => {
  return (
    <a className={`exchange-card exchange-link skey-link`} href={url} target="blank" rel="norefferer noopener">
      <Image imgWidth={70} imgHeight={70} imgPath={imgPath} alt={"exchange"} />
      <Space space="space-sm" />
      <p className="s2">{title}</p>
      <Space space="space-xs" />
      <p>{description}</p>
    </a>
  );
};

export default ExchangeCard;
