export const productsSkeyTixCards = [
  {
    img: "/images/profit-card5.svg",
    header: "Non-counterfeiting",
    subheader: "Each ticket is unique. No one will forge your NFT ticket on the blockchain network."
  },
  {
    img: "/images/profit-card8.svg",
    header: "NFT Module",
    subheader: "Creating, sending, and viewing NFTs related to events.",
    link: {
      href: "#skey-tix-nft-module",
      label: "see more",
      target: "_self"
    }
  },
  {
    img: "/images/profit-card2.svg",
    header: "Non-copiability",
    subheader: "The ticket cannot be copied or counterfeited. It is secure by cryptographic encryption."
  },
  {
    img: "/images/profit-card6.svg",
    header: "Functionality",
    subheader: "You have a clear, intuitive panel where you can see all the tickets you have."
  },
  {
    img: "/images/profit-card3.svg",
    header: "Integration",
    subheader: "Easy and quick access via API."
  },
  {
    img: "/images/profit-card7.svg",
    header: "Comfort",
    subheader:
      "An easy-to-use application with each of your NFT tickets. You do not need additional readers or other devices."
  }
];
