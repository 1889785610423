import { Container } from "react-bootstrap";
import { IProductSkeyCard } from "../../../shared/interfaces/IProductSkeyCard";
import SolutionSkeyCard from "../../components/molecules/SolutionSkeyCard/SolutionSkeyCard";
import { solutions } from "../../../data/productsOfSkeyNetwork";
import Group from "../../../components/arrangement/Group/Group";
import { useIsMobile } from "../../../hooks/useIsMobile";

import "./SolutionsSkeyCards.scss";

const SolutionsSkeyCards = () => {
  const isMobile = useIsMobile("lg");
  return (
    <div id="solutions-skey-cards">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Group
            fullWidth
            colGap={20}
            rowGap={isMobile ? 16 : 80}
            className="wrap"
            alignItems="stretch"
            justifyContent={isMobile ? "center" : "flex-start"}
          >
            {solutions.map((product: IProductSkeyCard, id) => {
              return <SolutionSkeyCard {...product} isBigOne={id === 0} key={id} />;
            })}
          </Group>
        </Container>
      </Container>
    </div>
  );
};

export default SolutionsSkeyCards;
