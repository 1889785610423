import { useState } from "react";

import { IWithTooltipProps } from "../../../../shared/interfaces/IWithTooltipProps";
import Tooltip from "../../atoms/Tooltip/Tooltip";

import "./WithTooltip.scss";

const WithTooltip = ({
  alwaysShown,
  tooltipInside,
  externalClassForTooltip,
  className = "",
  handleToggle,
  handleClose,
  isOpen = false
}: IWithTooltipProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleInnerOpen = () => setMenuOpen(true);
  const handleInnerClose = () => {
    setTimeout(() => {
      setMenuOpen(false);
    }, 150);
  };

  const handleInnerToggle = () => {
    if (menuOpen) {
      handleInnerClose();
    } else {
      handleInnerOpen();
    }
  };

  return (
    <button
      className={`with-tooltip ${className}`}
      onClick={handleToggle ? handleToggle : handleInnerToggle}
      onBlur={handleClose ? handleClose : handleInnerClose}
    >
      {alwaysShown}
      {(isOpen || menuOpen) && <Tooltip className={externalClassForTooltip} children={tooltipInside} />}
    </button>
  );
};

export default WithTooltip;
