import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DefaultProductProvider } from "./contexts/DefaultProductContext";
import { FormsControllProvider } from "./contexts/FormsControllContext";
import { NavigationProvider } from "./contexts/NavigationContext";
// import { ScrollControllProvider } from "./contexts/ScrollControllContext";
import { ScrollPageProvider } from "./contexts/ScrollPageContext";
import routes from "./routing/routes";
import { ModulesProvider } from "./contexts/ModulesContext";
import { Helmet } from "react-helmet-async";

const App = () => {
  return (
    <Router>
      <Helmet htmlAttributes={{ lang: "en" }} />
      <NavigationProvider>
        <ScrollPageProvider>
          <FormsControllProvider>
            <DefaultProductProvider>
              <ModulesProvider>
                {/* <ScrollControllProvider> */}
                <Routes>
                  {routes.map((route, id) => {
                    const { path, Component, Layout } = route;
                    return (
                      <Route
                        key={id}
                        path={path}
                        element={
                          <Layout>
                            <Component />
                          </Layout>
                        }
                      ></Route>
                    );
                  })}
                </Routes>
                {/* </ScrollControllProvider> */}
              </ModulesProvider>
            </DefaultProductProvider>
          </FormsControllProvider>
        </ScrollPageProvider>
      </NavigationProvider>
    </Router>
  );
};

export default App;
