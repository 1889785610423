import Space from "../../components/atoms/Spacing/Space";
import GridCards from "../../components/organisms/GridCards/GridCards";
import { skeyBoxManagement } from "../../../data/productsSkeyBox";
import "./SkeyBoxProducts.scss";

const SkeyBoxProducts = () => {
  return (
    <div id="skey-box-products">
      <GridCards maxColumns="2">
        {skeyBoxManagement.map((card, index) => (
          <div className="products-skey-box" key={index}>
            <img src={card.img} className="imageBoxProducts" width={80} height={65} alt="products" />
            <Space space="space-lg" />
            <div className="skey-features">
              <h3 className="skey-box-header">{card.header}</h3>
              <Space space="space-md" />
              {card.subHeader.map((subHeader, index) => (
                <ul key={index}>
                  <li className="subheader">{subHeader}</li>
                </ul>
              ))}
            </div>
          </div>
        ))}
      </GridCards>
    </div>
  );
};

export default SkeyBoxProducts;
