import { IAccordionTab } from "../ui/components/molecules/AccordionTab/AccordionTab.interface";

export const faqQuestions: IAccordionTab[] = [
  {
    question: "What is Skey Network?",
    answers: [
      "Skey Network provides a fast, scalable and affordable blockchain platform. It is an independent blockchain that allows you to create flexible products, services, and user-friendly applications that guarantee cryptographic security. Skey Network shares a universal communication blockchain system.",
      "It is an access control tool for cities, businesses, public security, and individual audiences. In practice, these are virtual keys for cars, doors, rooms, electric vehicles, scooters, sensors, devices, and many other things. We also provide the opportunity to share this access to assets with family, friends, suppliers, taxi drivers, and municipal services. Our system is safe, transparent, stable, and open.",
      "We function in the Blockchain and Smart City areas using NFT-NFC technology, and we create effective Smart Contracts solutions."
    ],
    inititalVariant: "default",
    additionalLink: "",
    additionalLinkLabel: ""
  },
  {
    question: "What is the function of the Skey token?",
    answers: [
      "Skey Token is currently available on the exchanges listed below. The following exchanges are supported and personally recommended by Skey Network. Add your Skey Token address to MetaMask or another wallet to make sure your Skey tokens are visible in the list of assets. Early investors in SKEY stand to benefit from the network growth, because Skey Network requires a utility token - SkeyUSD, in order to transact on the network. In practice - more users mean more benefits."
    ],
    inititalVariant: "default",
    additionalLink: "",
    additionalLinkLabel: ""
  },
  {
    question: "Can you explain the token economics of the project?",
    answers: [
      "Skey is a utility token that cities and corporations will use to provide services on our blockchain ecosystem. Each company will have to purchase a Skey technical token to offer its services on our Blockchain. Those technical tokens can only be bought with Skey ERC20 units. A significant advantage for the holders is that the ERC20 tokens used to purchase the technical unit will be frozen and never return to the market. This way, every new implementation and usage of our Blockchain will help to increase the price of the Skey ERC20 token. This system can be compared to our Investors buying the fuel source companies will need to power up Smart Cities."
    ],
    inititalVariant: "default",
    additionalLink: "https://www.youtube.com/watch?v=gDhpBG2raPw",
    additionalLinkLabel: "Watch the explainer video"
  },
  {
    question: "What is a NODE?",
    answers: [
      "A node is a host connected to the Skey Network blockchain using the Skey Network ecosystem. Node functions are block storage, transaction validation, and sending transactions. It is a very energy-efficient and ecological solution, and it is LPoS."
    ],
    inititalVariant: "default",
    additionalLink: "",
    additionalLinkLabel: ""
  },
  {
    question: "Can I have my own NODE?",
    answers: [
      "Only partners and associates can have their own Skey NODE. If you want to cooperate with us, please contact us."
    ],
    inititalVariant: "default",
    additionalLink: "",
    additionalLinkLabel: ""
  },
  {
    question: "What is the purpose of Skey Network?",
    answers: [
      "Skey Network is constantly evolving; it has developed from services to a fully functioning and independent blockchain. Until the end of this year, we are planning many engaging activities - we are changing the website and services and implementing a system of commercial NODEs servers. We model our development on other projects, building long-term, functional solutions based on our own blockchain."
    ],
    inititalVariant: "default",
    additionalLink: "",
    additionalLinkLabel: ""
  },
  {
    question: "What does it mean that Skey Network develops Smart City?",
    answers: [
      "We prepare products and tools for cities, companies, and individual (end) users under separate partnership agreements that integrate customers' special requirements with Skey Network. An example would be a company (e.g., from the energy industry) that wants to use the Skey Network solution. Caruma collects all customer requirements, prepares a solution, and creates a dApp, Smart Contract that meets the customer's needs. It can also act as an intermediary in purchasing Skey Network tokens and generating technical tokens - necessary to operate on the Skey Network blockchain."
    ],
    inititalVariant: "default",
    additionalLink: "",
    additionalLinkLabel: ""
  },
  {
    question: "What is SkeyBOX?",
    answers: [
      "SkeyBOX is a set of a few tools to enable the implementation of blockchain technology, reducing the integration time. Bring your business into blockchain in just a few minutes. After creating an account, you run NODE, which is your gateway to the blockchain. You will be able to use all modules: Communication node - your blockchain server.",
      "Files Security - a durable medium that guarantees the credibility of your documentation.\n" +
        "Certificates - certification system for your products based on the NTF token -\n" +
        "Supply chain - building a transparent supply chain for your products\n" +
        "NFT Generator - secure your products by making them digital and unforgeable NFT\n"
    ],
    inititalVariant: "default",
    additionalLink: "https://www.youtube.com/watch?v=7vDA0bXsiRQ&t",
    additionalLinkLabel: "Watch more"
  },
  {
    question: "What is SkeyTIX?",
    answers: [
      "SkeyTIX is a tool for creating a unique event using NFT tickets. Create your event or integrate your ticketing system with NFT. SkeyTIX significantly secures the ticketing system and accelerates registrations for the event."
    ],
    inititalVariant: "default",
    additionalLink: "https://skeynetwork.medium.com/skey-tix-the-ticket-of-the-future-d9955b5160da",
    additionalLinkLabel: "Read more"
  },
  {
    question: "What is the strategy of the Skey Network?",
    answers: [
      "We are creating plug & play products. Mass implementation and partner acquisition. Snowball effect and acceleration of mass adoption through the usability of the Skey token."
    ],
    inititalVariant: "default",
    additionalLink: "",
    additionalLinkLabel: ""
  }
];
