import FaqHero from "../../sections/FaqHero/FaqHero";
import FaqAccordion from "../../sections/FaqAccordion/FaqAccordion";
import "./FAQ.scss";
import OverviewExchangeStocks from "../../sections/OverviewExchangeStocks/OverviewExchangeStocks";

const FAQ = () => {
  return (
    <section id="faq">
      <FaqHero />
      <FaqAccordion />
      <OverviewExchangeStocks />
    </section>
  );
};

export default FAQ;
