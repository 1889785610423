import { APP_STAGE } from "./constants";

export const ARI_WIDGET_ID_PROD = "75967059-97c7-4562-8e06-11ecd958aacb";
export const ARI_WIDGET_ID_DEV = "e736284e-5035-4669-a01e-7ebce5ac441c"; //our test key
// export const ARI_WIDGET_ID = "13ebdb82-1871-4d21-a58d-f500d9766f3b"; //they test key

const ARI_API_BASE_URL_DEV = "https://gateway-dev.ari10.com";
const ARI_API_BASE_URL_PROD = "https://gateway.ari10.com";

export const ARI_API_BASE_URL = APP_STAGE === "dev" ? ARI_API_BASE_URL_DEV : ARI_API_BASE_URL_PROD;
export const ARI_WIDGET_ID = APP_STAGE === "dev" ? ARI_WIDGET_ID_DEV : ARI_WIDGET_ID_PROD;
