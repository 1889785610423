import { Container } from "react-bootstrap";

import Space from "../../components/atoms/Spacing/Space";

import "./SkeyTixCooperation.scss";

const SkeyTixCooperartion = () => {
  return (
    <div id="skey-tix-cooperation" className="skey-tix-cooperation">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="cooperation-box">
            <div className="cooperation-content">
              <h2 className="header">Cooperation</h2>
              <Space space="space-lg" />

              <p className="s1 subheader">
                Do you have your own ticketing system and want to extend it with the functions of NFT-based tickets?
              </p>
              <Space space="space-lg" />
              <p className="s2 subheader">
                Use our solution. Our tool develops your ticketing system by integrating the ticket with the NFT token.
                You can easily and quickly add this extension to your system with a plugin. You can create your own
                solutions without developer support.
              </p>
            </div>
            <Space space="space-xl" className="mobile-md" />

            <div className="cooperation-image-box">
              <img
                width={342}
                height={272}
                src="/images/skey-tix-cooperation.svg"
                className="desktop-lg"
                alt="SkeyTIX cooperation"
              />
              <img
                width={286}
                height={227}
                src="/images/skey-tix-cooperation.svg"
                className="mobile-lg"
                alt="SkeyTIX cooperation"
              />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default SkeyTixCooperartion;
