import { useState } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { IProductSkeyCard } from "../../../../shared/interfaces/IProductSkeyCard";
import Space from "../../atoms/Spacing/Space";
import InteractiveText from "../../atoms/InteractiveText/InteractiveText";
import { classNames } from "../../../../utils/classNames";

import "./SolutionSkeyCard.scss";
import Stack from "../../../../components/arrangement/Stack/Stack";

interface Props {
  mobileImgPath: string;
  imgPath: string;
  title: string;
  text: string;
  disabled: boolean;
  linkImgPath: string;
  url?: string;
  isHover: boolean;
  linkLabel: string;
}

const BaseContent = ({
  mobileImgPath,
  imgPath,
  text,
  title,
  disabled,
  isHover,
  linkImgPath,
  linkLabel,
  url
}: Props) => {
  return (
    <>
      <div style={{ position: "relative", height: "250px", width: "100%" }}>
        {!mobileImgPath || (!isMobile && !!mobileImgPath) ? (
          <img
            width="552"
            height="250"
            src={imgPath}
            alt="Product of Skey Network Blockchain"
            className="header-image"
          />
        ) : isMobile ? (
          <img
            width={340}
            height={236}
            src={mobileImgPath}
            alt="Product of Skey Network Blockchain"
            className="header-image"
          />
        ) : (
          <></>
        )}
        <div className="header-imageOverlay" />
      </div>
      <Stack className="content" justifyContent="space-between" fullSize>
        <div>
          <h2 className="title-card">{title}</h2>
          <Space space="space-md" className="desktop-lg" />
          <Space space="space-sm" className="mobile-lg" />
          <p className="s2">{text}</p>
          <Space space="space-md" />
        </div>
        <InteractiveText
          imgWidth={16}
          imgHeight={15}
          variant="default"
          textRole="ps2"
          weight="regular"
          iconPath={disabled ? "" : linkImgPath}
          className={`link ${!url || disabled ? "link-disabled" : "interactive-text default"}`}
          active={isHover}
        >
          {linkLabel}
        </InteractiveText>
      </Stack>
    </>
  );
};

const SolutionSkeyCard = ({
  title,
  text,
  imgPath,
  url,
  linkLabel,
  disabled,
  linkImgPath,
  mobileImgPath,
  isBigOne
}: IProductSkeyCard & { isBigOne?: boolean }) => {
  const [hover, setHover] = useState(false);

  const className = classNames("solution-skey-card-container", disabled ? "disabled" : "", "h-xs-100");

  const props = {
    title,
    text,
    imgPath,
    linkLabel,
    linkImgPath,
    mobileImgPath,
    disabled,
    url,
    isHover: hover
  };
  const getLink = () => {
    return url?.startsWith("http") ? (
      <a href={url || ""} className={className} rel="noreferrer noopener" target="_blank">
        <BaseContent {...(props as any)} />
      </a>
    ) : (
      <Link to={url || ""} className={className} rel="noreferrer noopener">
        <BaseContent {...(props as any)} />
      </Link>
    );
  };

  const onMouseEnter = () => {
    if (disabled) return;

    setHover(true);
  };

  const onMouseLeave = () => {
    if (disabled) return;

    setHover(false);
  };

  return (
    <div
      className={classNames("solution-skey-card", disabled ? "disabled" : "", isBigOne ? "big" : "")}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {getLink()}
    </div>
  );
};

export default SolutionSkeyCard;
