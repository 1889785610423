import { TELEGRAM_1, TELEGRAM_2, TELEGRAM_3, TELEGRAM_4 } from "../constants";

export const telegramChannels = [
  {
    label: "Skey Network News >",
    url: TELEGRAM_1
  },
  {
    label: "Skey Network Chat Official >",
    url: TELEGRAM_2
  },
  {
    label: "Skey Network Chat Korea >",
    url: TELEGRAM_3
  },
  {
    label: "Skey Network Chat PL >",
    url: TELEGRAM_4
  }
];
