export const paths = [
  "overview",
  "solutions",
  "news",
  "cooperation",
  "development",
  "info",
  "faq",
  "notFound"
] as const;
export type Paths = typeof paths[number];

export interface LinkValue {
  label: string;
  paths: string[];
  isHidden?: boolean;
}

type MenuLinks = Record<Paths, LinkValue>;

export const solutionsPath = "/solutions";
export const blockchainPath = `${solutionsPath}/blockchain`;
export const skeyAccessControlPath = `${solutionsPath}/skey-access-control`;
export const skeyBoxPath = `${solutionsPath}/skey-box`;
export const skeyTixPath = `${solutionsPath}/skey-tix`;
export const dppPath = `${solutionsPath}/digital-product-passport`;
export const rwaPath = `${solutionsPath}/real-world-assets`;

export const menuLinks: MenuLinks = {
  overview: {
    label: "Overview",
    paths: ["/"],
    isHidden: true
  },
  solutions: {
    label: "Solutions",
    paths: [solutionsPath, blockchainPath, skeyAccessControlPath, skeyBoxPath, skeyTixPath, dppPath, rwaPath],
    isHidden: false
  },
  news: {
    label: "News",
    paths: ["/news"],
    isHidden: false
  },
  cooperation: {
    label: "Cooperation",
    paths: ["/cooperation"],
    isHidden: false
  },
  development: {
    label: "Development",
    paths: ["/development"],
    isHidden: false
  },
  info: {
    label: "Info",
    paths: ["/info"],
    isHidden: true
  },
  faq: {
    label: "FAQ",
    paths: ["/faq"],
    isHidden: false
  },
  notFound: {
    label: "Not Found",
    paths: ["/*"],
    isHidden: true
  }
};
