import DevelopmentHero from "../../sections/DevelopmentHero/DevelopmentHero";
import DevelopmentProducts from "../../sections/DevelopmentProducts/DevelopmentProducts";
import "./Development.scss";

const Development = () => {
  return (
    <section id="development">
      <DevelopmentHero />
      <DevelopmentProducts />
    </section>
  );
};

export default Development;
