import Group from "../../../../components/arrangement/Group/Group";

import "./StakingButton.scss";

const StakingButtonShell = () => {
  return (
    <div className="staking-button">
      <Group fullSize justifyContent="center" alignItems="center">
        <p className="s2 mazzard white-0" style={{ marginTop: "4px", letterSpacing: "0.02em" }}>
          Staking
        </p>
      </Group>
    </div>
  );
};

export default StakingButtonShell;
