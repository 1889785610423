export const featuresCards = [
  {
    imgPath: "/images/feature-card1-img.svg",
    header: "Access",
    subheader: "to a fast, scalable, and cheap blockchain"
  },
  {
    imgPath: "/images/feature-card2-img.svg",
    header: "Digital data",
    subheader: "security and decentralization"
  },
  {
    imgPath: "/images/feature-card3-img.svg",
    header: "Ready-made",
    subheader: "blockchain applications and tools"
  },
  {
    imgPath: "/images/feature-card4-img.svg",
    header: "User-friendly",
    subheader: "and easy to implement"
  }
];
