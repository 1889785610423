import { Container } from "react-bootstrap";

import Stack from "../../../../../components/arrangement/Stack/Stack";
import ContentWithVideo from "../../../../sections/ContentWithVideo/ContentWithVideo";
import ListItem from "../../../../components/ListItem/ListItem";
import { IListItem } from "../../../../components/ListItem/IListItem";

import "./RWAPotential.scss";
import { RWA_VIDEO_URL } from "../../constants";

const listItems: IListItem[] = [
  {
    imgUrl: "/images/passport/potential/potential.svg",
    title: "Potential",
    description: "Over $50 billion"
  },
  {
    imgUrl: "/images/passport/potential/market.svg",
    title: "Market",
    description: "Global reach"
  },
  {
    imgUrl: "/images/passport/potential/trend.svg",
    title: "Trend",
    description: "Real World Assets (RWA)"
  },
  {
    imgUrl: "/images/passport/potential/industries.svg",
    title: "Industries",
    description: "Real estate, Commercial, Green energy"
  }
];

const Content = () => {
  return (
    <Stack rowGap={24} className="pl-xs-0 pl-md-2">
      {listItems.map((li, id) => (
        <ListItem {...li} key={id} />
      ))}
    </Stack>
  );
};

const RWAPotential = () => {
  return (
    <div className="rwa-potential">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <ContentWithVideo content={<Content />} videoUrl={RWA_VIDEO_URL} />
        </Container>
      </Container>
    </div>
  );
};

export default RWAPotential;
