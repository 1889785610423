import { Container } from "react-bootstrap";
import Image from "../../components/atoms/Image/Image";
import Space from "../../components/atoms/Spacing/Space";

import "./SolutionsDeflationSystem.scss";

const SolutionsDeflationSystem = () => {
  return (
    <div id="deflation-system">
      <Container fluid="xxl">
        <Container fluid="xl">
          <h3 className="header">Skey Token Deflation System</h3>
          <Space space={"space-xxl"} />
          <Image
            imgWidth={1100}
            imgHeight={343}
            imgPath={"/images/deflation-system.svg"}
            alt={"deflation system"}
            className="desktop-lg deflation-img"
          />
          <Image
            imgWidth={341}
            imgHeight={979}
            imgPath={"/images/deflation-system-mobile.svg"}
            alt={"deflation system"}
            className="mobile-lg deflation-img"
          />
        </Container>
      </Container>
    </div>
  );
};

export default SolutionsDeflationSystem;
