import { Col, Container } from "react-bootstrap";

import Space from "../../components/atoms/Spacing/Space";
import Image from "../../components/atoms/Image/Image";
import { productsSkeyBoxIntegration } from "../../../data/productsSkeyBoxIntegration";

import "./ProductsSkeyBoxIntegration.scss";

const ProductsSkeyBoxIntegration = () => {
  return (
    <div id="products-skey-box-integration">
      <Container fluid="xxl">
        <Container fluid="xl flex-center">
          <h2 className="header-panel">Simple integration</h2>
          <Space space="space-md" />
          <p className="s2 subheader products-integration">
            Below, we present the whole process of including your company in the Skey Network blockchain. See how simple
            it is
          </p>
          <Space space="space-xxl" />
          <Col xs={12} lg={12} className="simple-integration">
            {productsSkeyBoxIntegration.map((card, index) => (
              <div key={index}>
                <Image className="box-integration" imgWidth={74} imgHeight={74} imgPath={card.img} alt="integration" />
                <Space space="space-sm" />
                <p className="s1 header-card subheader">{card.header}</p>
                <Space space="space-sm" />
                <p className="s2 integration ">{card.subheader}</p>
              </div>
            ))}
          </Col>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyBoxIntegration;
