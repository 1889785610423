import { Container, Row } from "react-bootstrap";

import Space from "../../components/atoms/Spacing/Space";
import AdministrationPanelCard from "../AdministrationPanelCard/AdministrationPanelCard";

import "./AdministrationPanel.scss";

const AdministrationPanel = () => {
  return (
    <div id="skey-tix-administration-panel" className="skey-tix-administration-panel">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="header-section">
            <h2 className="title">Administration panel</h2>
            <Space space="space-md" />
            <p className="s1 description">Check how many advantages of SkeyTIX you can use for your event.</p>
            <Space space="space-xxxl" />
          </div>

          <Row>
            <AdministrationPanelCard />
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default AdministrationPanel;
