import Group from "../../../../components/arrangement/Group/Group";
import CheckBox from "../../atoms/Checkbox/CheckBox";

interface ChecboxWithTextProps {
  isChecked: boolean;
  onToggle: () => void;
  label: string;
  className?: string;
}

const CheckboxWithText = ({ isChecked, label, onToggle, className }: ChecboxWithTextProps) => {
  return (
    <Group colGap={16} className={className}>
      <CheckBox checked={isChecked} onChange={onToggle} />
      <p className="s2">{label}</p>
    </Group>
  );
};

export default CheckboxWithText;
