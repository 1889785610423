import { IInteractiveElement } from "../../../../shared/interfaces/IInteractiveElement";
import { ComponentActiveState } from "../../../../shared/types/IComponentActiveState";
import "./InteractivePlus.scss";

const InteractivePlus = ({ variant = "default" }: IInteractiveElement) => {
  const defaultLines = (
    <>
      <img
        width={24}
        height={24}
        className={`vertical-line default`}
        src="/images/vertical-line-default.svg"
        alt="vertical line"
      />
      <img
        width={24}
        height={24}
        className={`horizontal-line default`}
        src="/images/horizontal-line-default.svg"
        alt="horizontal line"
      />
    </>
  );

  const hoverLines = (
    <>
      <img
        width={24}
        height={24}
        className={`vertical-line`}
        src="/images/vertical-line-hover.svg"
        alt="vertical line"
      />
      <img
        width={24}
        height={24}
        className={`horizontal-line`}
        src="/images/horizontal-line-hover.svg"
        alt="horizontal line"
      />
    </>
  );

  const activeLines = (
    <>
      <img
        width={24}
        height={24}
        className={`vertical-line active`}
        src="/images/vertical-line-default.svg"
        alt="vertical line"
      />
      <img
        width={24}
        height={24}
        className={`horizontal-line active`}
        src="/images/horizontal-line-default.svg"
        alt="horizontal line"
      />
    </>
  );

  const lines = (state: ComponentActiveState) => {
    switch (state) {
      case "default":
        return defaultLines;
      case "hover":
        return hoverLines;
      case "active":
        return activeLines;
      default:
        return defaultLines;
    }
  };

  return (
    <div className="interactive-plus" role="button">
      {lines(variant)}
    </div>
  );
};

export default InteractivePlus;
