import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { IChildren } from "../shared/interfaces/IChildren";
import { formAgreements } from "../data/formAgreements";

export interface CheckboxOption {
  name: string;
  label: string;
  link?: { label: string; url: string };
  checked: boolean;
  required: boolean;
  id: number;
}

interface ContextValue {
  agreements: CheckboxOption[];
  allAgreementsChecked: boolean;
  requiredAgreementsChecked: boolean;
  toggleCheckAgreement: (id: number) => void;
  toggleCheckAllAgreements: () => void;
  getCheckedAgreementsList: () => string;
  resetCheckboxes: () => void;
}

const inititalAgreements: CheckboxOption[] = formAgreements.map(({ name, label, required, link }, id) => ({
  name,
  label,
  checked: false,
  link,
  required,
  id
}));

const FormAgreementsContext = createContext(null as any);

export const FormAgreementsProvider = ({ children }: IChildren) => {
  const [agreements, setAgreements] = useState<CheckboxOption[]>(inititalAgreements);
  const [allAgreementsChecked, setAllAgreementsChecked] = useState(false);

  const toggleCheckAgreement = (id: number) => {
    agreements.forEach((agreement, index) => {
      if (agreement.id === id) {
        const checkedAgreement = { ...agreement, checked: !agreement.checked };

        const updatedAgreements = [...agreements.slice(0, index), checkedAgreement, ...agreements.slice(index + 1)];
        return setAgreements(updatedAgreements);
      }
    });
  };

  const toggleCheckAllAgreements = () => {
    if (!allAgreementsChecked) {
      setAllAgreementsChecked(true);
      setAgreements((prevState) => prevState.map((agreement) => ({ ...agreement, checked: true })));
    } else {
      setAllAgreementsChecked(false);
      agreements.map((agreement) => ({ ...agreement, checked: false }));
      setAgreements((prevState) => prevState.map((agreement) => ({ ...agreement, checked: false })));
    }
  };

  const getCheckedAgreementsList = () => {
    const checkedAgreements = agreements.filter((agreement) => agreement.checked);
    const list = checkedAgreements.map((agreement) => agreement.name).join(", ");

    return list;
  };

  const resetCheckboxes = () => setAgreements(inititalAgreements);

  useEffect(() => {
    const allAgreementsChecked = agreements.every((agreement) => agreement.checked);

    if (allAgreementsChecked) setAllAgreementsChecked(true);
    else setAllAgreementsChecked(false);
  }, [agreements]);

  const requiredAgreementsChecked = useMemo(() => {
    const requiredAgreements = agreements.filter((agreement) => agreement.required);
    return requiredAgreements.every((agreement) => agreement.checked);
  }, [agreements]);

  const contextValue: ContextValue = {
    agreements,
    allAgreementsChecked,
    requiredAgreementsChecked,
    toggleCheckAgreement,
    toggleCheckAllAgreements,
    getCheckedAgreementsList,
    resetCheckboxes
  };

  return <FormAgreementsContext.Provider value={contextValue}>{children}</FormAgreementsContext.Provider>;
};

export const useAgreements = (): ContextValue => useContext(FormAgreementsContext);
