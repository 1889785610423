import { SKEY_TIX_APP_ANDROID, SKEY_TIX_APP_IOS } from "../constants";

export const skeyTixApp = [
  {
    logoPath: "/images/skey-tix-ios-app.svg",
    url: SKEY_TIX_APP_IOS
  },
  {
    logoPath: "/images/skey-tix-android-app.svg",
    url: SKEY_TIX_APP_ANDROID
  }
];
