import { ComponentProps } from "react";

import { classNames } from "../../../../../utils/classNames";
import "./Input.scss";

interface Props extends ComponentProps<"input"> {
  isError?: boolean;
}

const Input = (props: Props) => {
  const { isError, className, style, ...rest } = props;
  return <input className={classNames("input", className, isError ? "error" : "")} style={style} {...rest} />;
};

export default Input;
