import { Container } from "react-bootstrap";

import Stack from "../../../../../components/arrangement/Stack/Stack";
import Group from "../../../../../components/arrangement/Group/Group";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

import ButtonV2 from "../../../../components/atoms/Button/ButtonV2";

import "./Hero.scss";
import { scrollToView } from "../../../../../utils/scrollToView";
import { id } from "../../constants/id";

const Hero = () => {
  const isMobile = useIsMobile("lg");

  return (
    <div className="hero">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Group
            rowGap={20}
            alignItems="center"
            justifyContent={isMobile ? "center" : "flex-start"}
            className={isMobile ? "wrap-reverse" : ""}
          >
            <Stack rowGap={isMobile ? 24 : 32} style={{ width: "590px" }}>
              <h1 className="t-xs-c t-lg-l m-xs-a m-lg-0">Skey Digital Passport</h1>
              <div className="w-xs-100 w-lg-mc">
                <p className="s1 t-xs-c t-lg-l m-xs-a" style={{ maxWidth: "430px" }}>
                  A digital tool that collects all product information, helping companies meet new standards and care
                  for the environment.
                </p>
              </div>
              <button className="w-xs-100 w-lg-mc" onClick={() => scrollToView(id.caseStudy)}>
                <ButtonV2 size={"md"} hierarchy={"secondary"} className="m-xs-a m-lg-0 w-xs-100">
                  <Group fullSize justifyContent="center">
                    <p className="s1 primary">Case study</p>
                  </Group>
                </ButtonV2>
              </button>
            </Stack>
            <video width={496} height={496} playsInline autoPlay muted loop className="video">
              <source src="/videos/passport/dpp.mp4" type="video/mp4" />
            </video>
            {/* <img src="/images/passport/hero.png" alt="hero" className="hero-img" /> */}
          </Group>
        </Container>
      </Container>
    </div>
  );
};

export default Hero;
