export const validateEmail = (email: string) => {
  let error = "";

  const emailMatches = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  if (email.trim().length <= 0) {
    error = "Email cannot be empty.";
  } else if (!emailMatches) {
    error = "Email is not valid";
  }

  return { error };
};
