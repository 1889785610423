import { IChildren } from "../../shared/interfaces/IChildren";
// import Backdrop from "../components/atoms/Backdrop/Backdrop";
import Navbar from "../components/organisms/Navbar/Navbar";
import Footer from "../sections/Footer/Footer";
import Forms from "../sections/Forms/Forms";

const WithNavbarAndFooter = ({ children }: IChildren) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
      <Forms />
    </>
  );
};

export default WithNavbarAndFooter;
