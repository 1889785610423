import Group from "../../../../../../components/arrangement/Group/Group";
import { TChildren } from "../../../../../../shared/interfaces/IChildren";
import { classNames } from "../../../../../../utils/classNames";

import "./Section.scss";

interface Props {
  directionOnMobile: "classic" | "reversed";
  children: TChildren;
  isMobile: boolean;
}

const direction = {
  classic: "wrap",
  reversed: "wrap-reverse"
};

const Section = ({ children, directionOnMobile, isMobile }: Props) => {
  return (
    <Group
      className={classNames("hdiw-section", isMobile ? direction[directionOnMobile] : "")}
      fullWidth
      justifyContent={isMobile ? "center" : "space-between"}
      alignItems="center"
    >
      {children}
    </Group>
  );
};

export default Section;
