import { createContext, useContext } from "react";

import { IChildren } from "../shared/interfaces/IChildren";
import { LinkValue, menuLinks } from "../data/menuLinks";

const NavigationContext = createContext(null as any);

interface WithActiveLink extends LinkValue {
  path: string;
  isActive: boolean;
}

interface NavigationCtxReturn {
  links: WithActiveLink[];
}

export const NavigationProvider = ({ children }: IChildren) => {
  const MAIN_PATH_OF_ARRAY = 0;

  const withActiveLink = (links: Record<string, any>) => {
    const linkValues = Object.values(links);
    const markAsActive = linkValues.map((link) => {
      return {
        ...link,
        path: link.paths[MAIN_PATH_OF_ARRAY]
      };
    });
    return markAsActive;
  };

  const menuWithActive: WithActiveLink[] = withActiveLink(menuLinks);

  return <NavigationContext.Provider value={{ links: menuWithActive }}>{children}</NavigationContext.Provider>;
};

export const useNavigationContext = (): NavigationCtxReturn => useContext(NavigationContext);
