import { Fragment } from "react";
import { accordionItemsWithSides } from "../../../../services/accordionWithSides";
import { IOneStory } from "../../../../shared/interfaces/IOneStory";
import Space from "../../atoms/Spacing/Space";
import StoryTab from "../../molecules/StoryTab/StoryTab";

import "./StoryAccordion.scss";

export interface IStoriesOfProject {
  storyAccordionItems: IOneStory[];
}

const StoryAccordion = (storyAccordionItems: IStoriesOfProject) => {
  const withSides = accordionItemsWithSides(storyAccordionItems);

  return (
    <div className="story-accordion">
      {withSides.map((item) => {
        return (
          <Fragment key={Math.random()}>
            <StoryTab initialVariant={item.initialVariant} cards={item.cards} date={item.year} />
            <Space space="space-md" />
          </Fragment>
        );
      })}
    </div>
  );
};

export default StoryAccordion;
