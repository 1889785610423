import "./FailFormContent.scss";

const FailFormContent = () => {
  return (
    <div className="as-row fail-form-content">
      <img src="/images/error-x.svg" alt="error" />
      <p className="s2 ml-xs info">Sending failed. Please try again.</p>
    </div>
  );
};

export default FailFormContent;
