import Container from "react-bootstrap/esm/Container";
import { carumaBoxKeys, carumaBoxValues, sectionsTitles } from "../../data/data";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Group from "../../../../../components/arrangement/Group/Group";
import Box from "../../components/Box/Box";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

import "./Caruma.scss";

const carumaBoxesOrder: typeof carumaBoxKeys[number][] = ["one-key", "passport"];

const Caruma = () => {
  const isMobile = useIsMobile("md");
  return (
    <div className="caruma pt-xs-8 pt-md-12">
      <Container fluid="xl">
        <Stack rowGap={isMobile ? 32 : 60}>
          <SectionTitle {...sectionsTitles["caruma"]} />
          <Group fullWidth colGap={40} rowGap={isMobile ? 8 : 40} className="grid" alignItems="flex-start">
            {carumaBoxesOrder.map((boxKey) => {
              const box = carumaBoxValues[boxKey];
              return <Box {...box} />;
            })}
          </Group>
        </Stack>
      </Container>
    </div>
  );
};

export default Caruma;
