import { Container } from "react-bootstrap";
import { LinkItem } from "../../../shared/interfaces/LinkItem";
import Link from "../../components/atoms/Link/Link";
import Space from "../../components/atoms/Spacing/Space";
import "./TechnicalDocumentation.scss";

interface TechDoc {
  links: LinkItem[];
}

const TechnicalDocumentation = ({ links }: TechDoc) => {
  return (
    <div className="technical-documentation">
      <Container fluid="xxl">
        <Container fluid="xl">
          <h2 className="header">Technical documentation</h2>
          <Space space="space-lg" className="desktop-lg" />
          <Space space="space-md" className="mobile-lg" />
          <div className="tech-buttons">
            {links.map(({ label, link, icon: iconPath, self = false }) => {
              return (
                <Link url={link} icon={iconPath} variant="secondary" target={self ? "_self" : "_blank"} key={link}>
                  {label}
                </Link>
              );
            })}
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default TechnicalDocumentation;
