import { Container } from "react-bootstrap";

import { productsSkeyTixWhyNft } from "../../../data/productsSkeyTixWhyNft";

import Space from "../../components/atoms/Spacing/Space";

import "./SkeyTixWhyNft.scss";

const SkeyTixWhyNft = () => {
  return (
    <div id="skey-tix-why-nft" className="skey-tix-why-nft">
      <Container fluid="xxl">
        <Container fluid="xl" className="as-center-col">
          <h2 className="header-main">Why NFT?</h2>
          <Space space="space-xxxl" className="desktop-lg" />
          <Space space="space-xxl" className="mobile-lg" />

          <div className="skey-tix-why-nft-cards">
            {productsSkeyTixWhyNft.map(({ imgPath, title, description }) => (
              <div key={Math.random()} className="why-nft-card">
                <img src={imgPath} alt={title} width={120} height={80} />
                <h2 className="title">{title}</h2>
                <Space space="space-md" />
                <p className="s2 description">{description}</p>
              </div>
            ))}
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default SkeyTixWhyNft;
