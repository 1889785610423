export type ModulesName = "Node" | "Data API" | "Files Security" | "Certificates";

export interface Module {
  label: ModulesName;
  description: string;
}

export const modules: Module[] = [
  { label: "Node", description: "" },
  { label: "Data API", description: "" },
  { label: "Certificates", description: "" },
  { label: "Files Security", description: "" }
];
