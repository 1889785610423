import { CSSProperties, ReactNode, forwardRef } from "react";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { BaseProps } from "../../../shared/interfaces/BaseProps";
import { ScreenSizes } from "../../../shared/types/ScreenSizes";

import "./Flex.scss";

type Role = "stack" | "group";

interface Props extends BaseProps {
  baseRole: Role;
  mobileBreakpoint: ScreenSizes;
  children: ReactNode;

  fullSize?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;

  colGap?: number;
  rowGap?: number;
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  testId?: string;
}

const Flex = forwardRef(
  (
    {
      className,
      baseRole,
      mobileBreakpoint,
      fullSize,
      fullHeight,
      fullWidth,
      children,
      justifyContent,
      alignItems,
      rowGap,
      colGap,
      ...rest
    }: Props,
    ref: any
  ) => {
    const isMobile = useIsMobile(mobileBreakpoint);
    const oppositeRole = baseRole === "group" ? "stack" : "group";
    const currentRole = !isMobile ? baseRole : oppositeRole;

    const classes = `flex ${className} ${fullSize ? " full-size" : ""}${fullWidth ? " full-width" : ""}${
      fullHeight ? " full-height" : ""
    }`;

    const styles: CSSProperties = {
      ...rest.style,
      flexDirection: currentRole === "group" ? "row" : "column",
      justifyContent: currentRole === "group" ? justifyContent : alignItems,
      alignItems: currentRole === "group" ? alignItems : justifyContent,
      rowGap,
      columnGap: colGap
    };

    return (
      <div {...rest} ref={ref} test-id={rest.testId} className={classes} style={styles}>
        {children}
      </div>
    );
  }
);

export default Flex;
