import { useState } from "react";
import { validateName } from "../services/validateName";

const useFormName = () => {
  const [name, setName] = useState({
    text: "",
    error: "",
    focus: false
  });
  const NAME_FIELD = {
    label: "Company name",
    placeholder: "Enter your company's name",
    type: "text"
  };
  const focusName = () => setName((prev) => ({ ...prev, focus: true }));
  const blurName = () => setName((prev) => ({ ...prev, focus: false }));
  const handleName = (value: string) => {
    const { error } = validateName(value);

    if (!error) {
      setName((prev) => ({ ...prev, text: value, error: "" }));
      return;
    }

    setName((prev) => ({ ...prev, text: value, error }));
  };

  return { name, NAME_FIELD, focusName, blurName, handleName };
};

export default useFormName;
