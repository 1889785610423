import { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Space from "../../components/atoms/Spacing/Space";
import { productsClientPanel } from "../../../data/productsClientPanel";
import Image from "../../components/atoms/Image/Image";
import PricingOfferDesktop from "../PricingOfferDesktop/PricingOfferDesktop";
import PricingOfferMobile from "../PricingOfferMobile/PricingOfferMobile";

import "./ProductsSkeyBoxOffer.scss";

const ProductsSkeyBoxOffer = () => {
  return (
    <div id="products-skey-box-offer">
      <Container fluid="xxl">
        <Container fluid="xl flex-center">
          <h2 className="header">The offer</h2>
          <Space space="space-xxxl" className="desktop-xl" />
          <Space space="space-xxl" className="mobile-lg" />
          <Row>
            <Col xs={12} lg={3}>
              <p className="s1 ">SkeyBOX starter pack</p>
              <Space space="space-sm" />
              <p className="s2">
                Your company's address in the blockchain, also known as your node, opens up possibilities for developing
                further services based on the blockchain network. The cost is €1000 as a one-off activation fee and €599
                as a recurring fee for annual maintenance of the node in our infrastructure.
              </p>
              <Space space="space-xxxl" className="desktop-xl" />
              <Space space="space-xxl" className="mobile-lg" />
              {productsClientPanel.map((panel, index) => (
                <Fragment key={index}>
                  <Image imgWidth={80} imgHeight={80} imgPath={panel.img} alt="client-panel" />
                  <Space space="space-sm" />
                  <p className="s1 subheader">{panel.header}</p>
                  <Space space="space-sm" />
                  <p className="s2 title-offer">{panel.title}</p>
                  <Space space="space-sm" />
                  <p className="s2">{panel.subtitle}</p>
                </Fragment>
              ))}
            </Col>
            <Col lg={{ span: 8, offset: 1 }}>
              <PricingOfferDesktop />
            </Col>
            <Col xs={12} className="offer-mobile">
              <PricingOfferMobile />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyBoxOffer;
