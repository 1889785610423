import { ICooperationProps } from "../../../../shared/interfaces/ICooperationProps";
import Space from "../../atoms/Spacing/Space";
import Image from "../../atoms/Image/Image";
import Link from "../../atoms/Link/Link";
import "./CooperationCard.scss";
import Group from "../../../../components/arrangement/Group/Group";

const CooperationCard = ({
  icon,
  title,
  description: descriptions,
  articleUrl,
  videoUrl,
  products
}: ICooperationProps) => {
  return (
    <div className="cooperation-card">
      <div className="card-head">
        <Image imgPath={icon} alt="company-logo" className="h-45" imgWidth={160} imgHeight={52} />
        <Group colGap={16}>
          {videoUrl && (
            <Link variant="trietary" icon="/images/go-to.svg" size="size-small" className={`link`} url={videoUrl}>
              watch
            </Link>
          )}

          {articleUrl && (
            <Link variant="trietary" icon="/images/go-to.svg" size="size-small" className={`link`} url={articleUrl}>
              read
            </Link>
          )}
        </Group>
      </div>
      <Space space="space-xl" />
      <p className="descriptions">{descriptions}</p>
      <Space space={"space-md"} />
      <div className="links-holder">
        {products && (
          <>
            {products.map(({ imgPath, name, url = "", notClickable }) => {
              return (
                <div key={Math.random()} className="link-holder">
                  <Link
                    url={url}
                    icon={imgPath}
                    className="img-max-width"
                    variant="trietary"
                    size="size-small"
                    iconOnLeft={true}
                    notClickable={notClickable}
                  >
                    {name}
                  </Link>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default CooperationCard;
