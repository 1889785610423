import {
  SKEY_TOKEN_ADDRESS,
  SKEY_TOKEN_DECIMALS,
  SKEY_TOKEN_SYMBOL
} from "../constants";

const token = {
  address: SKEY_TOKEN_ADDRESS ?? "error",
  symbol: SKEY_TOKEN_SYMBOL ?? "err",
  decimals: SKEY_TOKEN_DECIMALS ?? 8,
  image: `${window.location.origin}/assets/Skey-sign-256.png`
};

export const addSkeyToMetamask = async () => {
  const ethereum = (window as any).ethereum;
  if (!ethereum) {
    alert("Metamask is not installed");
    return;
  }

  try {
    await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: { ...token }
      }
    });
  } catch (error) {
    console.error(error);
  }
};
