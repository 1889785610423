import { IPricingOffer } from "./productsPricingOfferDesktop";
import "../ui/sections/PricingOfferMobile/PricingOfferMobile.scss";
import {
  PACKAGE_1_AMOUNT,
  PACKAGE_1_PRICE,
  PACKAGE_2_AMOUNT,
  PACKAGE_2_PRICE,
  PACKAGE_3_AMOUNT,
  PACKAGE_3_PRICE
} from "../constants/offerConsts";

export const pricingOfferMobile: IPricingOffer[] = [
  {
    title: "Pricing",
    rows: [
      {
        serviceName: "name of service"
      },
      {
        serviceName: "net price",
        divider: true
      }
    ]
  }
];

export const starterMobile: IPricingOffer[] = [
  {
    subHeader: "SkeyBOX Starter",
    rows: [
      {
        serviceType: "activation / one-off",
        price: "€1000"
      },
      {
        serviceType: "annual / recurring",
        price: "€599"
      }
    ]
  }
];

export const offerModulesMobile: IPricingOffer[] = [
  {
    title: "Modules",
    rows: [
      {
        subHeader: "Data API",
        serviceType: "annual / recurring",
        price: "€1",
        divider: true
      },
      {
        subHeader: "Durable Media",
        serviceType: "annual / recurring",
        price: "€300",
        divider: true
      },
      {
        subHeader: "Certificates",
        serviceType: "annual / recurring",
        price: "€300"
      }
    ]
  },
  {
    title: "Packages",
    rows: [
      {
        subHeader: PACKAGE_1_AMOUNT,
        serviceType: "package of trans. / one-off",
        price: PACKAGE_1_PRICE,
        divider: true
      },
      {
        subHeader: PACKAGE_2_AMOUNT,
        serviceType: "package of trans. / one-off",
        price: PACKAGE_2_PRICE,
        divider: true
      },
      {
        subHeader: PACKAGE_3_AMOUNT,
        serviceType: "package of trans. / one-off",
        price: PACKAGE_3_PRICE
      }
    ]
  }
];

export const modulesPackages: IPricingOffer[] = [
  {
    title: "Packages",
    rows: [
      {
        subHeader: PACKAGE_1_AMOUNT,
        serviceType: "package of trans. / one-off",
        price: "€5"
      },
      {
        subHeader: PACKAGE_2_AMOUNT,
        serviceType: "package of trans. / one-off",
        price: "€50"
      },
      {
        subHeader: PACKAGE_3_AMOUNT,
        serviceType: "package of trans. / one-off",
        price: "€500"
      }
    ]
  }
];
