import ContactProductDefault from "../../sections/ContactSection/ContactProductDefault/ContactProductDefault";
import ContactUs from "./sections/ContactUs/ContactUs";
import FewSteps from "./sections/FewSteps/FewSteps";
import DigitalProductPotential from "./sections/DigitalProductPotential/DigitalProductPotential";
import Gains from "./sections/Gain/Gains";
import Hero from "./sections/Hero/Hero";
import MainBenefits from "./sections/MainBenefits/MainBenefits";
import MoreInfo from "./sections/MoreInfo/MoreInfo";
import NewRequirements from "./sections/NewRequirements/NewRequirements";
import UseCase from "./sections/UseCase/UseCase";

import "./Passport.scss";

const Passport = () => {
  return (
    <section id="passport">
      <Hero />
      <DigitalProductPotential />
      <MainBenefits />
      <UseCase />
      <Gains />
      <FewSteps />
      <NewRequirements />
      <ContactUs />
      <MoreInfo />
      <ContactProductDefault />
    </section>
  );
};

export default Passport;
