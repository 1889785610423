import { Container } from "react-bootstrap";

import Space from "../../components/atoms/Spacing/Space";

import "./SolutionsHero.scss";

const SolutionsHero = () => {
  return (
    <div id="products-hero">
      <img
        width="617"
        height="678"
        src="/images/products-hero-bg-blocks.png"
        alt="hero"
        className="products-bg-blocks"
      />
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="content-center ">
            <h1 className={"header-products"}>Solutions of Skey Network Blockchain</h1>
            <Space space="space-xxl" />
            <p className={" s1 subheader"}>
              Skey Network blockchain enables the creation of digital services, products, and applications. It is a
              flexible, fully independent database
            </p>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default SolutionsHero;
