import { Container } from "react-bootstrap";
import "./ProductsSkeyBoxFeatures.scss";
import Space from "../../components/atoms/Spacing/Space";
import SkeyBoxProducts from "../SkeyBoxProducts/SkeyBoxProducts";

const ProductsSkeyBoxFeatures = () => {
  return (
    <div id="products-skey-box-features">
      <Container fluid="xxl">
        <Container fluid="xl flex-center">
          <h2 className="header-skey-box">What will you gain from it?</h2>
          <Space space="space-md" className="desktop-xl" />
          <Space space="space-md" className="mobile-lg" />
          <p className="s1 subheader products-box">
            Thanks to this service, the client can integrate their products or services with an open public register of
            events and actions, and create new products or services based on blockchain technology.
          </p>
          <SkeyBoxProducts />
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyBoxFeatures;
