import { useState } from "react";

import Space from "../../atoms/Spacing/Space";
import { IArticleCard } from "../../../../shared/interfaces/IArticlesCard";
import InteractiveText from "../../atoms/InteractiveText/InteractiveText";

import "./ArticleCard.scss";

const ArticleCard = ({ date, title, url, className = "", hoverOutside = false }: IArticleCard) => {
  const [hoverInner, setHoverInner] = useState(false);

  return (
    <a
      className={`articleCard ${className} ${hoverInner || hoverOutside ? "active" : ""}`}
      onMouseEnter={() => setHoverInner(true)}
      onMouseLeave={() => setHoverInner(false)}
      href={url || ""}
      target="_blank"
      rel="noreferrer noopener"
    >
      {/* <p className="date">{date}</p> */}
      <Space space="space-xs" />
      <p className="s1 title">{title}</p>
      <Space space="space-sm" />
      <InteractiveText
        imgWidth={16}
        imgHeight={15}
        variant="default"
        textRole="ps2"
        weight="regular"
        iconPath={"/images/go-to.svg"}
        className={`link ${!url ? "link-disabled" : "interactive-text default"}`}
        active={hoverInner || hoverOutside}
      >
        read article
      </InteractiveText>
    </a>
  );
};

export default ArticleCard;
