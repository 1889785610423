import { ReactNode, createContext, useState, useContext } from "react";
import { modules } from "../data/modulesNames";

export interface CheckboxOption {
  label: string;
  description: string;
  isChecked: boolean;
  id: number;
}

interface ModulesContextProps {
  children: ReactNode;
}

interface ModulesReturnValue {
  options: CheckboxOption[];
  toggleModuleById: (id: number) => void;
  resetCheckboxes: () => void;
}

const ModulesContext = createContext(null as any);

const inititalModules: CheckboxOption[] = modules.map(({ label, description }, id) => ({
  label,
  description,
  isChecked: false,
  id
}));

export const ModulesProvider = ({ children }: ModulesContextProps) => {
  const [options, setOptions] = useState<CheckboxOption[]>(inititalModules);

  const toggleModuleById = (id: number) => {
    setOptions((prev) =>
      prev.map((checkbox) => ({
        ...checkbox,
        isChecked: checkbox.id === id ? !checkbox.isChecked : checkbox.isChecked
      }))
    );
  };

  const resetCheckboxes = () => setOptions(inititalModules);

  return (
    <ModulesContext.Provider value={{ options, toggleModuleById, resetCheckboxes }}>{children}</ModulesContext.Provider>
  );
};

export const useModules = (): ModulesReturnValue => useContext(ModulesContext);
