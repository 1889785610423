// import Slider from "react-slick";
import { ISliderProps } from "../../../../shared/interfaces/IPartnershipsSlider";
import Image from "../../atoms/Image/Image";
import "./PartnershipsSlider.scss";
import Slider from "react-slick";

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  variableWidth: true,
  easing: "linear",
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
};

const CustomSlider = ({ images }: ISliderProps) => {
  return (
    <Slider {...sliderSettings} className="partnershipsSlider">
      {images.map((image, id) => (
        <a href={image.url} target="_blank" rel="noreferrer nofollow noopener" key={id}>
          <Image
            imgWidth={96}
            imgHeight={35}
            key={Math.random()}
            imgPath={image.img}
            alt="partnership logo"
            className="partnership mb-xs"
          />
        </a>
      ))}
    </Slider>
  );
};

export default CustomSlider;
