import { Fragment } from "react";

import Space from "../../atoms/Spacing/Space";
import AccordionTab from "../../molecules/AccordionTab/AccordionTab";
import { IAccordionTab } from "../../molecules/AccordionTab/AccordionTab.interface";

import "./Accordion.scss";

interface IAccordionItems {
  accordionItems: IAccordionTab[];
}

const Accordion = ({ accordionItems }: IAccordionItems) => {
  return (
    <div className="skey-accordion">
      {accordionItems.map((accordionItem: IAccordionTab) => {
        return (
          <Fragment key={Math.random()}>
            <AccordionTab {...accordionItem} key={Math.random()} />
            <Space space="space-md" />
          </Fragment>
        );
      })}
    </div>
  );
};

export default Accordion;
